import React, { ComponentProps, FC, ReactElement, useEffect, useState } from 'react';
import { Link, useRoute } from 'react-router5';
import { take } from 'rxjs/operators';
import { CurrentUser } from '@proliance-ai/typings';
import { defaultRoute, isExternalRoute, redirectToLobby } from '@router';
import { userService } from '@services';

interface IOwnProps {
  children: ReactElement;
  authorized?: ReactElement;
  unauthorized?: ReactElement;
}
type Props = IOwnProps & ComponentProps<any>;

const DefaultRoute: FC<Props> = ({ children, authorized, unauthorized, ...params }): ReactElement => {
  const { router } = useRoute();
  const [ user, setUser ] = useState<null | CurrentUser>(null);
  useEffect(() => {
    const subscription = userService.user$
      .pipe(take(1))
      .subscribe((data: null | CurrentUser) => setUser(data));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const selectedElement = user === null ? unauthorized : authorized;
  const element = typeof selectedElement !== 'undefined' ? selectedElement : children;
  const routeName = defaultRoute();
  const currentRouteName = router.getState().name;
  return routeName === currentRouteName
    ? <div {...params}>{element}</div>
    : isExternalRoute(routeName)
      ? (
        <a
          className="link"
          {...params}
          onClick={() => redirectToLobby({ route: routeName })}
        >
          {element}
        </a>
      )
      : (
        <Link
          data-link
          className="link"
          routeName={routeName}
          {...params}
        >
          {element}
        </Link>
      );
};

export default DefaultRoute;
