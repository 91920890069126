import React, { ComponentType, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router5';
import { LoadingIndicator, LoadingSuspend, Modal as OldModal } from '@proliance-ai/design-system';
import { NotificationContainer, ScrollProvider, Modal } from '@proliance-ai/react-ui';
import { setRouter, Outlet } from '@router';
import { routes } from '@router/configuration';
import { i18n, newRelicService } from '@services';

import 'core-js/stable';
import './polyfill/append';
import './polyfill/removeElement';

import '@proliance-ai/react-ui/style.css';
import '@styles/index.styl';

newRelicService.initialize();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const router = setRouter(routes);

const render = (Component: ComponentType) => {
  root.render(
    <>
      <LoadingIndicator/>
      <Suspense fallback={ <LoadingSuspend/> }>
        <RouterProvider router={ router }>
          <I18nextProvider i18n={ i18n }>
            <ScrollProvider>
              <Component/>
              <Modal/>
              <OldModal/>
              <NotificationContainer/>
            </ScrollProvider>
          </I18nextProvider>
        </RouterProvider>
      </Suspense>
    </>
  );
};
// TODO Remove OldModal when modal will be fully migrated to react-ui
router.start(() => {
  render(Outlet);
});
