import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { Software, SystemsType } from '@services';
import { Header } from '@proliance-ai/design-system';

const getSoftwareColumnList = (
  t: TFunction,
  systemsType: SystemsType
): Array<Column<Software>> => [
  {
    accessor: 'name',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'manufacturer',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  }
];

export default getSoftwareColumnList;
