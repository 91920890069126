import { Observable } from 'rxjs';
import {
  AssignAcademyUserData,
  Collection,
  DpoUser,
  InviteAcademyUserData,
  InviteTeamMemberData,
  TeamArea,
  TeamData,
  TeamUser,
  TranslationInfo,
  User
} from '@proliance-ai/typings';
import { AvailableUserListParameters } from '@services';
import { Api } from '@services/api';
import { PayloadRequestOptions } from '@proliance-ai/design-system';

const apiUrl = '/api/survey/team';

class TeamApiService extends Api {
  public getAvailableUserList(parameters: Partial<AvailableUserListParameters>): Observable<Collection<User>> {
    const { context = 'all', ...query } = parameters;
    return this.get<Collection<User>>({
      url: `${ context }/available-users`,
      parameters: { ...query }
    });
  }

  public getTeam(): Observable<TeamData> {
    return this.get<TeamData>();
  }

  public getDpo(): Observable<DpoUser> {
    return this.get<DpoUser>({ url: 'dpo' });
  }

  public inviteTeamMember(data: InviteTeamMemberData): Observable<User> {
    const { teamArea, ...body } = data;
    return this.post<User>({
      url: teamArea,
      body,
      suppressDefaultErrorHandler: [ 403 ]
    });
  }

  public assignTeamMember(functionalArea: string, userId: number): Observable<User> {
    return this.put<User>({ url: `${ functionalArea }/${ userId }` });
  }

  public inviteAcademyUser(data: InviteAcademyUserData): Observable<User> {
    const { companyCourseId, ...body } = data;
    return this.post<User>({
      url: `academy/${ companyCourseId }`,
      body,
      suppressDefaultErrorHandler: true
    });
  }

  public inviteAcademyUserGroup(companyCourseId: number, body: string[]): Observable<number> {
    return this.post<number>({
      url: `academy/batch-invitation/${ companyCourseId }`,
      body,
      returnType: 'status'
    });
  }

  public assignAcademyUser(data: AssignAcademyUserData): Observable<null> {
    const { userId, companyCourseId } = data;
    return this.put<null>({
      url: `academy/${ companyCourseId }/${ userId }`
    });
  }

  public unAssignAcademyUser(id: number): Observable<void> {
    return this.delete({ url: `academy/${ id }` });
  }

  public checkUserInvitationToken(token: string): Observable<TranslationInfo> {
    return this.get<TranslationInfo>({
      url: 'academy/invitation',
      parameters: { token }
    });
  }

  public getTeamByFunctionalArea(teamArea: TeamArea): Observable<null | TeamUser> {
    return this.get<null | TeamUser>({
      url: teamArea.toLowerCase()
    });
  }

  public inviteDeputy(data: InviteTeamMemberData, requestOptions: Partial<PayloadRequestOptions> = {}): Observable<User> {
    const { teamArea, ...body } = data;
    return this.post<User>({
      suppressDefaultErrorHandler: [ 403 ],
      ...requestOptions,
      url: 'dpo/deputy/',
      body
    });
  }
  public assignDeputy(userId: number, requestOptions: Partial<PayloadRequestOptions> = {}): Observable<User> {
    return this.put<User>({
      ...requestOptions,
      url: `dpo/deputy/${ userId }`
    });
  }
  public unAssignDeputy(userId: number): Observable<User> {
    return this.delete<User>({ url: `dpo/deputy/${ userId }` });
  }

  public inviteAssistant(data: InviteTeamMemberData): Observable<User> {
    const { teamArea, ...body } = data;
    return this.post<User>({
      url: `assistant/${ teamArea }`,
      body,
      suppressDefaultErrorHandler: [ 403 ]
    });
  }
  public assignAssistant(teamArea: TeamArea, userId: number): Observable<User> {
    return this.put<User>({ url: `assistant/${ teamArea }/${ userId }` });
  }
  public unAssignAssistant(teamArea: TeamArea, userId: number): Observable<User> {
    return this.delete<User>({ url: `assistant/${ teamArea }/${ userId }` });
  }

  public retireUser(userId: number, companyId?: number): Observable<User> {
    return this.put({
      url: `retire/${ userId }`,
      parameters: { companyId }
    });
  }
}

export const teamApiService = new TeamApiService(apiUrl);
