import { State } from 'router5';
import { ProductType } from '@proliance-ai/typings';
import { RouteListType } from '@router/configuration';
import { isProductValueType, productService } from '@services';

export const productParameterName = 'product';

export const productMiddleware = () => (toState: State): boolean => {
  const product = toState.params[productParameterName];
  if (isProductValueType(product)) {
    productService.setCurrentProduct(product);
  } else {
    const currentProduct: null | ProductType = productService.getCurrentProduct();
    if (currentProduct) {
      if (!productService.isRouteInProduct(toState.name as RouteListType, currentProduct)) {
        productService.setCurrentProductByRouteName(toState.name as RouteListType);
      }
      return true;
    }
    productService.setCurrentProductByRouteName(toState.name as RouteListType);
  }
  return true;
};
