// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circle-ComplianceScoreWidget_tkScL{display:block;margin:20px auto 0;font-family:'Roboto',sans-serif}.status-ComplianceScoreWidget_a52Hd{fill:#1b143f;font-weight:normal;font-size:.16px}.scoreResult-ComplianceScoreWidget_YYLOR{color:#1b143f;font-weight:bold;font-size:.175px;text-transform:uppercase}.scoreResult-ComplianceScoreWidget_YYLOR.undetermined-ComplianceScoreWidget_IrwWn{font-size:.15px}.arrow-ComplianceScoreWidget_Ggn3Y{stroke-linejoin:round;fill:#000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": "circle-ComplianceScoreWidget_tkScL",
	"status": "status-ComplianceScoreWidget_a52Hd",
	"scoreResult": "scoreResult-ComplianceScoreWidget_YYLOR",
	"undetermined": "undetermined-ComplianceScoreWidget_IrwWn",
	"arrow": "arrow-ComplianceScoreWidget_Ggn3Y"
};
export default ___CSS_LOADER_EXPORT___;
