// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-Notification_elxXW{position:relative;width:64px;height:64px;cursor:pointer}.notification-Notification_elxXW > div:first-child{height:100%}.notification-Notification_elxXW::after{content:' ';position:absolute;right:0;bottom:0;left:0;-webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);background:#38a7d7;width:0;height:6px;margin:0 auto;-webkit-transition:width 250ms linear;transition:width 250ms linear}.notification-Notification_elxXW:hover::after{width:100%}.notification-Notification_elxXW .row-Notification_RpgNB{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center}.notification-Notification_elxXW .icon-Notification_eZICS{width:24px;height:24px;-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)\";filter:alpha(opacity=50);opacity:.5}.notification-Notification_elxXW .icon-Notification_eZICS svg{width:18px;height:26px}.notification-Notification_elxXW .box-Notification_bIVKz{position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "notification-Notification_elxXW",
	"row": "row-Notification_RpgNB",
	"icon": "icon-Notification_eZICS",
	"box": "box-Notification_bIVKz"
};
export default ___CSS_LOADER_EXPORT___;
