import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TaskType } from '@interfaces';
import { ComponentPermissionData, ProductType } from '@proliance-ai/typings';
import {
  basicFilter,
  companyDataService,
  ITask,
  ITaskCount,
  permissionService,
  streamService,
  TaskCount,
  TaskCountDictionary,
  TaskCountMessage,
  taskService,
  TaskTabDictionary
} from '@services';
import { Widget } from '@shared/components';
import { Badge, ICollection } from '@proliance-ai/design-system';
import { checkPermission } from '../../utilities';
import { AssessmentTasks, BasicTasks, TaskTabs } from '..';

interface IOwnProps {
  namespace: string | string[];
  permission: ComponentPermissionData;
  product: ProductType;
  taskCountData: TaskTabDictionary<number>;
  initialBasicTaskList: ITask[];
  initialAssessmentTaskData: TaskCountDictionary<ITaskCount>;
  tab: TaskCount;
  changeTabHandler: (value: TaskCount) => void;
}

type Props = IOwnProps;

const TasksWidget: FC<Props> = ({
  namespace,
  permission: initialPermission,
  product,
  taskCountData,
  initialBasicTaskList,
  initialAssessmentTaskData,
  tab,
  changeTabHandler
}) => {
  const { t } = useTranslation(namespace);
  
  const [ permission, setPermission ] = useState<ComponentPermissionData>(initialPermission);
  const getIsBasicTasksAvailable = (taskCount?: number): boolean => !!taskCount
    && checkPermission(permission, 'basicTasksWidget');
  const getIsAssessmentTasksAvailable = (taskCount?: number): boolean => !!taskCount
    && checkPermission(permission, 'assessmentTasksWidget')
    && product === 'privacy';
  const [ payload, setPayload ] = useState<TaskTabDictionary<number>>(taskCountData);
  const [ taskCount, setTaskCount ] = useState<TaskTabDictionary<number>>(taskCountData);
  const [ isBasicTasksAvailable, setIsBasicTasksAvailable ] = useState<boolean>(getIsBasicTasksAvailable(taskCount?.BASIC));
  const [ isAssessmentTasksAvailable, setIsAssessmentTasksAvailable ] = useState<boolean>(getIsAssessmentTasksAvailable(taskCount?.ASSESSMENT));
  const [ basicTaskLoading, setBasicTaskLoading ] = useState<boolean>(false);
  const [ assessmentTaskLoading, setAssessmentTaskLoading ] = useState<boolean>(false);
  const [ basicTaskList, setBasicTaskList ] = useState<ITask[]>(initialBasicTaskList);

  const getAcademyBasicTasks = (basicTaskList: ITask[]): ITask[] => basicTaskList
    .filter((task: ITask) => [ TaskType.ACADEMY, TaskType.ACADEMY_INVITE_EMPLOYEE ]
      .some((type: TaskType): boolean => type === task.type));

  const getBasicTaskListValue = (taskList: ITask[]): ITask[] => product === 'privacy'
    ? taskList
    : getAcademyBasicTasks(taskList);

  const [ basicTaskListValue, setBasicTaskListValue ] = useState<ITask[]>(getBasicTaskListValue(basicTaskList));
  const [ assessmentTaskData, setAssessmentTaskData ] = useState<TaskCountDictionary<ITaskCount>>(initialAssessmentTaskData);

  const updateBasicTaskList = (): void => {
    setBasicTaskLoading(true);
    if (!!payload.BASIC) {
      taskService
        .getTask({ ...basicFilter, pageSize: payload.BASIC })
        .pipe(
          take(1),
          takeUntil(unsubscribe$)
        )
        .subscribe((data: ICollection<ITask>) => {
          const basicTaskList: ITask[] = data.elements;
          const basicTaskListValue: ITask[] = product === 'privacy'
            ? basicTaskList
            : getAcademyBasicTasks(basicTaskList);
          setBasicTaskList(basicTaskListValue);
          setBasicTaskListValue(getBasicTaskListValue(basicTaskListValue));
          setBasicTaskLoading(false);
        });
    } else {
      setBasicTaskList([]);
      setBasicTaskLoading(false);
    }
  };

  const updateAssessmentTaskData = useCallback((): void => {
    setAssessmentTaskLoading(true);
    companyDataService
      .getCount()
      .pipe(
        take(1),
        takeUntil(unsubscribe$)
      )
      .subscribe((data: TaskCountDictionary<ITaskCount>) => {
        setAssessmentTaskData(data);
        setAssessmentTaskLoading(false);
      });
  }, [ taskCount ]);

  useEffect(() => {
    const {
      BASIC: basicTaskCount,
      ASSESSMENT: assessmentTaskCount
    } = payload;
    if (taskCount.BASIC !== basicTaskCount) {
      updateBasicTaskList();
    } else {
      setBasicTaskListValue(getBasicTaskListValue(basicTaskList));
    }
    if (taskCount.ASSESSMENT !== assessmentTaskCount) {
      updateAssessmentTaskData();
    }
    setIsBasicTasksAvailable(getIsBasicTasksAvailable(basicTaskCount));
    setIsAssessmentTasksAvailable(getIsAssessmentTasksAvailable(assessmentTaskCount));
    setTaskCount(payload);
  },
  [ payload, permission, product ]
  );

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    permissionService
      .componentPermissionSubject$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((payload: null | ComponentPermissionData): void => {
        if (payload) {
          setPermission(payload);
        }
      });
    streamService
      .subscribeTaskCountMessage()
      .pipe(takeUntil(unsubscribe$))
      .subscribe((message: TaskCountMessage) => {
        setPayload(message.payload);
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const {
    ASSESSMENT: assessmentCount,
    BASIC: basicCount
  } = taskCount;

  const basicCountValue = product === 'privacy'
    ? basicCount
    : basicTaskListValue.length;

  const assessmentTabHeading = (
    <Badge
      content={assessmentCount}
      color="danger"
      isRounded={true}
    >
      {t('tabs.assessment')}
    </Badge>
  );
  const basicTabHeading = (
    <Badge
      content={basicCountValue}
      color="danger"
      isRounded={true}
    >
      {t('tabs.basic')}
    </Badge>
  );
  if (isBasicTasksAvailable && isAssessmentTasksAvailable) {
    return (
      <TaskTabs
        namespace={namespace}
        tab={tab}
        changeTabHandler={changeTabHandler}
        basicTabHeading={basicTabHeading}
        basicTaskList={basicTaskListValue}
        basicTaskLoading={assessmentTaskLoading}
        assessmentTabHeading={assessmentTabHeading}
        assessmentTaskData={assessmentTaskData}
        assessmentTaskLoading={assessmentTaskLoading}
      />
    );
  } else if (isAssessmentTasksAvailable && !isBasicTasksAvailable) {
    return (
      <Widget
        name="assessmentTasksWidget"
        permission={permission}
        title={assessmentTabHeading}
      >
        <AssessmentTasks
          namespace={namespace}
          assessmentTaskData={assessmentTaskData}
          loading={assessmentTaskLoading}
        />
      </Widget>
    );
  } else {
    if (tab === TaskCount.ASSESSMENT) {
      changeTabHandler(TaskCount.BASIC);
    }

    if (checkPermission(permission, 'basicTasksWidget')) {
      return (
        <Widget
          name="basicTasksWidget"
          permission={permission}
          title={basicTabHeading}
        >
          <BasicTasks
            namespace={namespace}
            basicTaskList={basicTaskListValue}
            loading={basicTaskLoading}
          />
        </Widget>
      );
    }
  }
  return null;
};

export default TasksWidget;
