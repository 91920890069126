import React, { ReactElement } from 'react';
import { AsYouType } from 'libphonenumber-js';
import { IPhoneData } from '@proliance-ai/design-system';

export interface IGetPhoneLinkOptions {
  phone?: string | IPhoneData;
  fallback?: string;
  className?: string;
  fallbackClassName?: string;
}

function isPhoneData(phone: string | IPhoneData): phone is IPhoneData {
  return typeof (phone as IPhoneData) === 'object';
}
export const getPhoneLink = (options: IGetPhoneLinkOptions): ReactElement => {
  const { phone = null, fallback = '', className, fallbackClassName } = options;
  if (!phone) {
    return <span className={fallbackClassName || className}>{fallback}</span>;
  } else {
    let phoneString: string = isPhoneData(phone) ? `${ phone.countryDialCode }${ phone.phoneNumber }` : phone;
    if (phoneString.charAt(0) !== '+') {
      phoneString = `+${ phoneString }`;
    }
    const asYouType = new AsYouType();
    asYouType.input(phoneString);
    return <a className={className} href={`tel:+${ (asYouType as any).state.digits }`}>
      {(asYouType as any).formattedOutput}
    </a>;
  }
};
