import { faRectangleWide } from '@fortawesome/pro-regular-svg-icons';
import { classNameArrayToString } from '@proliance-ai/design-system';
import { addClass } from '../common';
import { IProperty, WidgetEditorLocalization } from '../interfaces';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { Question, ItemValue } from '@shared/surveyJs/reexport';

import './styles.styl';

export default class ButtonWidget extends WidgetAbstractClass<Question> {
  public hidePropertyNameList: string[] = [
    'indent',
    'inputFormat',
    'inputMask',
    'inputType',
    'placeholder',
    'prefix',
    'readOnly',
    'requiredErrorText',
    'size',
    'validators',
    'valueName',
    'correctAnswer',
    'acceptedTypes'
  ];

  public customPropertiesList: IProperty[] = [
    {
      type: 'itemvalue[]',
      name: 'choices',
      category: 'choices',
      choices: [ 'true', 'false' ],
      default: [
        {
          value: 'true',
          text: {
            default: 'Yes',
            de: 'Ja'
          }
        },
        {
          value: 'false',
          text: {
            default: 'No',
            de: 'Nein'
          }
        }
      ]
    }
  ];

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Button',
        de: 'Taste'
      }
    }
  };

  protected alternativeNameList = [ 'dsequestion' ];

  protected classNameDictionary: Record<string, string> = {
    wrap: 'button-widget',
    button: 'button-widget-button',
    input: 'button-widget-input',
    label: 'button-widget-label',
    disabled: 'disabled'
  };

  constructor() {
    super({
      name: 'button',
      iconName: 'button',
      icon: faRectangleWide
    });
  }

  public renderer(question: Question, element: HTMLElement): void {
    addClass(element, this.classNameDictionary.wrap);
    this.changeHandler = this.changeHandlerFactory(question);
    this.renderButtons(question, element);
  }

  public onChangeReadOnly(question: Question, element: HTMLElement): void {
    const input = this.getElement('input', element);
    input?.removeEventListener('change', this.changeHandler);
    element.innerHTML = '';
    this.renderButtons(question, element);
  }

  private renderButtons(question: Question, element: HTMLElement): void {
    question.choices.map((choice: ItemValue) => this.addButton(question, element, choice));
  }

  private changeHandler: EventListenerOrEventListenerObject = (_event: Event): void => {
    return;
  };

  private changeHandlerFactory = (question: Question): EventListenerOrEventListenerObject => (event: Event): void => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    if (target === null) {
      return;
    }
    question.value = target.value;
  };

  private addButton(question: Question, element: HTMLElement, choice: ItemValue): void {
    const input = document.createElement('input');
    input.setAttribute('id', `button-widget-${ question.id }-${ choice.value }`);
    input.setAttribute('class', this.classNameDictionary.input);
    input.setAttribute('type', 'radio');
    input.setAttribute('name', `button-widget-${ question.id }`);
    input.setAttribute('value', choice.value);

    if (question.isReadOnly) {
      input.setAttribute('disabled', 'disabled');
    }

    if (question.value === choice.value) {
      input.checked = true;
    }
    input.addEventListener('change', this.changeHandler);

    const label = document.createElement('label');
    label.setAttribute('for', `button-widget-${ question.id }-${ choice.value }`);
    const classNameString = classNameArrayToString([
      this.classNameDictionary.label,
      question.isReadOnly && this.classNameDictionary.disabled
    ]);
    label.setAttribute('class', classNameString!);
    label.innerHTML = choice.text;

    const button = document.createElement('div');
    button.setAttribute('class', this.classNameDictionary.button);

    button.append(input);
    button.append(label);
    element.append(button);
  }
}
