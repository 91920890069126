import { Observable } from 'rxjs';
import { Collection } from '@proliance-ai/typings';
import { ITask, TaskTabDictionary } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/task';

class TaskApiService extends Api {
  public getTaskCount(parameters: Record<string, any> = {}): Observable<TaskTabDictionary<number>> {
    return this.get<TaskTabDictionary<number>>({
      url: 'count',
      parameters
    });
  }

  public getTask(parameters: Record<string, any> = {}): Observable<Collection<ITask>> {
    return this.get<Collection<ITask>>({ parameters });
  }
}
export const taskApiService = new TaskApiService(apiUrl);
