import {
  Base,
  Question,
  IQuestion,
  JsonObject,
  Serializer,
  surveyLocalization,
  SvgRegistry
} from '@shared/surveyJs/reexport';
import { IconDefinition, icon } from '@fortawesome/fontawesome-svg-core';
import {
  TranslationInfo,
  TranslationPlural,
  TranslationPluralInfo,
  Locale
} from '@proliance-ai/typings';
import {
  ActivatedBy,
  IDefaultWidgetConfiguration,
  IProperty,
  IPropertyChangeOption,
  IValidator,
  IWidgetConfiguration,
  WidgetEditorLocalization,
  WidgetLocalization,
  WidgetPluralLocalization
} from '../widgets/interfaces';
import { getTitle, setEditorLocalization } from '../widgets/compatibility/localization';

const emptyCallback = () => {
  return;
};

export abstract class WidgetAbstractClass<T extends Question> {
  /**
   * @description The widget name. It should be unique and written in lowercase.
   * @type {string}
   */
  public name!: string;
  /**
   * @description The widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder.
   * @type {string}
   */
  public title: string = getTitle(this.name);
  /**
   * @description The name of the icon on the toolbox. Leave it empty for the standard icon.
   * @type {string}
   */
  public iconName!: string;
  /**
   * @description The link for icon on the toolbox. Use it if You set custom iconName.
   * @type {string}
   */
  public icon!: IconDefinition;
  /**
   * @description The size in pixels for icon on the toolbox. Optional. Use it if You set custom iconName.
   * @optional
   * @type {number} [iconSize = 24]
   */
  public iconSize: number = 24;
  /**
   * @description You should use it if your set the isDefaultRender to false.
   * @type {string}
   */
  public htmlTemplate!: string;
  /**
   * @description Parent class name.
   * @type {string}
   */
  public inheritClass = 'empty';
  /**
   * @description Switches rendering type.
   * If true - the default question rendering used.
   * If false use HTML template for rendering.
   * @readonly
   * @type {boolean}
   */
  public readonly isDefaultRender!: boolean;
  /**
   * @description The widget default JSON data.
   * @type {object}
   */
  public defaultJSON!: object;
  /**
   * @description ActivatedBy tells how your widget has been activated by.
   * @type {ActivatedBy}
   */
  public activatedBy!: ActivatedBy;

  /**
   * @description List of properties names that should be hidden for widget.
   * @type {string[]}
   */
  public hidePropertyNameList: string[] = [];
  /**
   * @description List of custom properties that should be added to widget.
   * @type {Array<IProperty>}
   */
  public customPropertiesList: IProperty[] = [];

  /**
   * @description Widget Survey localization data.
   * @type {WidgetLocalization}
   */
  public surveyLocalization: WidgetLocalization = {};

  /**
   * @description Widget Survey pluralization localization data.
   * @type {WidgetLocalization}
   */
  public surveyPluralLocalization: WidgetPluralLocalization = {};
  /**
   * @description Widget Editor localization data.
   * @type {WidgetEditorLocalization}
   */
  public editorLocalization: WidgetEditorLocalization = {};

  /**
   * @description The widget alternative name list. They should be unique and written in lowercase.
   * @type {string}
   */
  protected alternativeNameList: string[] = [];

  /**
   * @description Widget elements CSS class names dictionary.
   * @type {Record<string, string>}
   */
  protected classNameDictionary: Record<string, string> = {};

  /**
   * @description Widget default configuration object.
   * @type {IDefaultWidgetConfiguration}
   */
  private defaultConfiguration: IDefaultWidgetConfiguration = {
    iconName: '',
    icon: '',
    htmlTemplate: '<div/>',
    isDefaultRender: false,
    defaultJSON: {},
    activatedBy: 'customtype'
  };

  /**
   * Creates SurveyJS widget
   * @constructor
   * @protected
   * @param {ISurvey} survey - SurveyJS instance for registering widget.
   * @param {IWidgetConfiguration} configuration - Widget configuration object.
   */
  protected constructor(configuration: IWidgetConfiguration) {
    Object.assign(this, { ...this.defaultConfiguration, ...configuration });
  }

  /**
   * @description If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded.
   * @return {boolean}
   */
  public widgetIsLoaded(): boolean {
    return true;
  }

  /**
   * @description SurveyJS library calls this function for every question to check,
   * if it should use this widget instead of default rendering/behavior.
   * @param {Question} question
   * @return {boolean}
   */
  public isFit(question: T): boolean {
    return [ this.name, ...this.alternativeNameList ].includes(question.getType());
  }

  /**
   * @description Use this method to create a new class or add new properties or remove unneeded properties from widget
   * activatedBy tells how your widget has been activated by: property, type or customType
   * customtype - you are creating a new type.
   * type - you are changing the behaviour of entire question type.
   * property - it means that it will activated if a property of the existing question type is set to particular value.
   * @param {ActivatedBy} activatedBy
   */
  // @ts-ignore
  public activatedByChanged(activatedBy: ActivatedBy): void {
    this.setAlternativeNames();
    this.addClass();
    if (this.customPropertiesList.length) {
      this.addProperties(this.customPropertiesList);
    }
    if (this.hidePropertyNameList.length) {
      this.hideProperties();
    }
    if (
      Object.keys(this.surveyLocalization).length
      || Object.keys(this.surveyPluralLocalization).length
      || Object.keys(this.editorLocalization).length
    ) {
      this.addLocalizations();
    }
    if (this.icon) {
      this.addIcon();
    }
  }

  /**
   * @description The main method, rendering and two-way binding
   * @param {Question} question
   * @param {HTMLElement} element
   */
  // @ts-ignore
  public afterRender(question: T, element: HTMLElement): void {
    this.renderer(question, element);
    question.onPropertyChanged.clear();
    question.onPropertyChanged.add((sender: Base, options: IPropertyChangeOption<any>) => {
      this.onPropertyChangedHandler(sender as T, element, options);
    });
  }

  /**
   * @description Use it to destroy the widget. It is typically needed by jQuery widgets
   * @param {Question} question
   * @param {HTMLElement} element
   */
  public willUnmount(question: T, element: HTMLElement): void {
    element.innerHTML = '';
    question.readOnlyChangedCallback = emptyCallback;
    question.focusCallback = emptyCallback;
    question.surveyLoadCallback = emptyCallback;
    question.valueChangedCallback = emptyCallback;
    question.commentChangedCallback = emptyCallback;
  }

  /**
   * @description Abstract renderer method. Use for rendering and binding template.
   * @param {Question} question
   * @param {HTMLElement} element
   */
  protected abstract renderer(question: T, element: HTMLElement): void;

  /**
   * @description Method to bind question property change handlers
   * @param {Question} question
   * @param {HTMLElement} element
   * @param {any} options - Property change data. Depends on property type.
   */
  protected onPropertyChangedHandler(question: T, element: HTMLElement, options: IPropertyChangeOption<any>): void {
    if (options.name === 'readOnly') {
      this.onChangeReadOnly(question, element, options);
    }
  }

  /**
   * @description Question readOnly property change handler
   * @param {Question} question
   * @param {HTMLElement} element
   * @param {IPropertyChangeOption<boolean>} options
   */
  // @ts-ignore
  protected onChangeReadOnly(question: T, element: HTMLElement, options: IPropertyChangeOption<boolean>): void {
    return;
  }

  /**
   * @description Method to get widget inner HTML element by name
   * @param {string} name - element name from classNameDictionary
   * @param {HTMLElement} element
   */
  protected getElement<E extends HTMLElement>(name: string, element: HTMLElement): null | E  {
    const className: string | undefined = this.classNameDictionary[name];
    if (!className) {
      console.error(`Selector for element with name ${ name } not found`);
      return null;
    }
    return element.querySelector(`.${ className }`) as null | E;
  }

  /**
   * @description Method to get current survey locale
   * @param {Question} question
   */
  protected getCurrentLocale(question: T): string {
    return (
      (question as IQuestion).survey.locale ||
      surveyLocalization.defaultLocaleValue ||
      ''
    );
  }

  /**
   * @description Method to add validator for question
   * @param {Question} question
   * @param {string} surveyPropertyName - validator property name
   * @param {string} type - validator type
   * @param {any[]} properties - validator properties (validator constructor arguments)
   */
  protected addValidator(question: T, surveyPropertyName: string, type: string, properties: any[] = []): void {
    const isExistValidator = typeof (window as any)[surveyPropertyName] !== 'undefined';
    const isSetValidator = question.validators.some(
      (validator: IValidator) => validator.getType() === type
    );
    if (isExistValidator && !isSetValidator) {
      question.validators.push(new (window as any)[surveyPropertyName](...properties));
    }
  }

  protected setAlternativeNames(): void {
    this.alternativeNameList.map(
      (alternativeName: string) => {
        Serializer.removeClass(alternativeName);
        Serializer.addAlterNativeClassName(alternativeName, this.name);
      }
    );
  }

  /**
   * @description Add widget class to survey and set properties from list as hidden
   * @param {ISurvey} survey
   * @param {IProperty[]} propertyList
   * @param {string} inheritClass
   * @param {string} [name=this.name]
   */
  protected addClass(
    propertyList: IProperty[] = [],
    name: string = this.name,
    inheritClass: string = this.inheritClass
  ): void {
    JsonObject.metaData.addClass(
      name,
      propertyList,
      undefined,
      inheritClass
    );
    this.alternativeNameList.map((alternativeName: string) => {
      JsonObject.metaData.removeClass(alternativeName);
      JsonObject.metaData.addClass(
        alternativeName,
        propertyList,
        undefined,
        inheritClass
      );
    });
  }

  /**
   * @description Add widget custom properties to survey
   * @param {ISurvey} survey
   * @param {IProperty[]} [propertyList=this.customPropertiesList]
   * @param {string} [name=this.name]
   */
  protected addProperties(
    propertyList: IProperty[] = this.customPropertiesList,
    name: string = this.name
  ): void {
    propertyList = propertyList
      .map((property: IProperty) => {
        if (this.hidePropertyNameList.includes(property.name!)) {
          property.visible = false;
        }
        return property;
      });
    JsonObject.metaData.addProperties(name, propertyList);
    this.alternativeNameList.map((alternativeName: string) =>
      JsonObject.metaData.addProperties(alternativeName, propertyList));
  }

  /**
   * @description Add widget custom properties to survey
   * @param {ISurvey} survey
   * @param {string | string[]} propertyName
   * @param {string} [name=this.name]
   */
  // @ts-ignore
  protected removeProperties(
    propertyName: string | string[],
    name: string = this.name
  ): void {
    const propertyNameList = Array.isArray(propertyName) ? propertyName : [ propertyName ];
    propertyNameList.map(
      (property: string) => JsonObject.metaData.removeProperty(name, property)
    );
  }

  /**
   * @description Hide widget properties from editor
   * @param {ISurvey} survey
   */
  protected hideProperties(): void {
    this.hidePropertyNameList.map(
      (name: string) => {
        const property = JsonObject.metaData.findProperty(this.name, name);
        if (property) {
          if (property.visible) {
            JsonObject.metaData.addProperty(this.name, { ...property, visible: false });
          }
        } else {
          JsonObject.metaData.addProperty(this.name, { name, visible: false });
        }
      }
    );
  }

  protected getLocalization(question: T, key: string): string {
    return surveyLocalization.locales[this.getCurrentLocale(question)][key];
  }

  protected getPluralLocalization(question: T, key: string): TranslationPlural {
    return surveyLocalization.locales[this.getCurrentLocale(question)][key];
  }

  /**
   * @description Add widget localizations to survey localization
   */
  private addLocalizations(): void {
    if (Object.keys(this.surveyLocalization).length) {
      Object.keys(this.surveyLocalization)
        .map((key: string) => {
          const translationInfo: TranslationInfo = this.surveyLocalization[key];
          (Object.keys(translationInfo) as Locale[])
            .map((locale: Locale) => {
              if (typeof surveyLocalization.locales[locale] === 'undefined') {
                surveyLocalization.locales[locale] = {};
              }
              surveyLocalization.locales[locale][key] = translationInfo[locale];
            });
        });
    }
    if (Object.keys(this.surveyPluralLocalization).length) {
      Object.keys(this.surveyPluralLocalization)
        .map((key: string) => {
          const translationPluralInfo: TranslationPluralInfo = this.surveyPluralLocalization[key];
          (Object.keys(translationPluralInfo) as Locale[])
            .map((locale: Locale) => {
              surveyLocalization.locales[locale][key] = translationPluralInfo[locale];
            });
        });
    }
    if (Object.keys(this.editorLocalization).length) {
      setEditorLocalization(this.editorLocalization);
    }
  }

  /**
   * @description Add widget icon CSS class to style element
   */
  private addIcon(): void {
    const iconData = icon(this.icon);
    if (!iconData) {
      return;
    }
    const svg = iconData.html[0];
    SvgRegistry.registerIconFromSvg(this.iconName, svg, '');
  }
}
