import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons';
import { useDataAttributes } from '@proliance-ai/design-system';
import { FileUploadListItem, IFileUploadListItem } from './fileUploadListItem';

import styles from './FileUpload.styl';

export const initialFileUploadData: IFileUploadData = {
  files: [],
  isInvalid: false
};
/**
 *
 * @param  multiple - Set true for multiple file upload
 * @param  acceptedTypes - Collection of accepted file types, by default all from enum FileUploadTypes
 * @param  maxSize - Maximum file size in MB
 * @param  showRequired - Show require message
 * @param  getData - Callback return {files: [], isInvalid: boolean}
 * @example <FileUpload getData={(data) => {...}}
 * multiple={true}
 * acceptedTypes={[FileUploadTypes.pdf]}
 * maxSize={2}
 * showRequired={false} />
 */

export const FileUpload: FC<Props> = ({
  multiple = false,
  acceptedTypes = allFileUploadTypes,
  maxSize = 0,
  showRequired = false,
  getData
}) => {
  const { t } = useTranslation();
  const [ required, setRequired ] = useState<boolean>(showRequired);
  const [ data, setData ] = useState<IFileUploadData>(initialFileUploadData);
  const [ list, setList ] = useState<React.ReactElement[]>([]);
  const [ maxSizeInvalid, setMaxSizeInvalid ] = useState<boolean>(false);

  const fileUploadHandle = (evt: FileUploadEventType) => {
    let files = getFiles(evt, multiple);
    if (multiple) {
      files = data.files.concat(files);
      files = removeDuplicates(files);
    }
    buildFileList(setList, setData, getData, setMaxSizeInvalid, files, data, acceptedTypes, maxSize);
  };
  useEffect(() => {
    setRequired(showRequired);
  }, [ showRequired ]);

  return (
    <div className={styles.fileupload}>
      <div onDragOver={(evt) => evt.preventDefault()} onDrop={fileUploadHandle} className={styles.box}>
        <FontAwesomeIcon
          className={styles.uploadIcon}
          icon={faCloudArrowUp}
        />
        <div className={styles.title}>{t('fileUpload.title')}</div>
        <div className={styles.subTitle}>{t('fileUpload.subTitle')}</div>
        <label className="button primary">
          <input
            onChange={fileUploadHandle}
            multiple={multiple}
            type="file"
            accept={getFileExtensions(acceptedTypes)}
            {...useDataAttributes({
              test: { 'file-upload': 'input' },
              guide: { 'file-upload': 'input' }
            }).dataAttributes}
          />
          {multiple ? t('fileUpload.uploads') : t('fileUpload.upload')}
        </label>
        <div className={`${ styles.acceptedTypes } ${ styles.info }`}>{`${ t('fileUpload.acceptedTypes') }${ formatTypes(
          acceptedTypes
        ) }`}</div>
        <div
          className={`${ styles.maxSize } ${ styles.info } ${ maxSizeInvalid ? styles.error : '' }`}
          {...useDataAttributes({
            test: { 'file-upload': 'maxSizeError' },
            guide: { 'file-upload': 'maxSizeError' }
          }).dataAttributes}
        >
          {maxSize > 0 ? t('fileUpload.maxSize', { maxSize }) : ''}
        </div>
        <div
          className={`${ styles.required } ${ styles.error }`}
          {...useDataAttributes({
            test: { 'file-upload': 'fileUploadRequired' },
            guide: { 'file-upload': 'fileUploadRequired' }
          }).dataAttributes}
        >
          {required ? t('fileUpload.required') : ''}
        </div>
      </div>
      <div className={styles.fileList}>{list}</div>
    </div>
  );
};

interface IOwnProps {
  multiple?: boolean;
  acceptedTypes?: FileUploadType;
  maxSize?: number;
  showRequired?: boolean;
  getData?: (data: IFileUploadData) => void;
}
type Props = IOwnProps;
export interface IFileUploadData {
  files: File[];
  isInvalid: boolean;
}
export enum FileUploadTypes {
  pdf = 'pdf',
  png = 'png',
  doc = 'doc',
  docx = 'docx',
  xls = 'xls',
  xlsx = 'xlsx',
  ppt = 'ppt',
  pptx = 'pptx',
}
type FileUploadType = Array<keyof typeof FileUploadTypes>;
const allFileUploadTypes = Object.values(FileUploadTypes);

function getFileExtensions(types: FileUploadType) {
  return types.map((type) => `.${ type.trim() }`).join(',');
}
function formatTypes(types: FileUploadType) {
  return types
    .map((type, idx) => {
      return `${ type.trim() }${ types.length - 1 === idx ? '' : ',' } `;
    })
    .join(' ');
}
function getFiles(evt: FileUploadEventType, multiple: boolean) {
  evt.preventDefault();
  const uploadFiles = evt.target.files;
  const dropFiles = getFilesFromDataTransfer(evt.dataTransfer);
  const files = uploadFiles ?? dropFiles;

  if (!files || !files.length) {
    return [];
  }
  const fileCollection = Array.prototype.slice.call(files) as File[];
  evt.target.value = '';
  evt.target.type = 'file';
  if (!multiple) {
    return [ fileCollection[0] ];
  }
  return fileCollection;
}
type FileUploadEventType = React.ChangeEvent<HTMLInputElement> & React.DragEvent<HTMLDivElement>;
function getFileList(files: File[], acceptedTypes: FileUploadType, maxSize: number): Promise<IGetFileList> {
  let totalSize = 0;
  let fileExtensionInvalid = false;

  const list: FileListType = files.map((file) => {
    totalSize += file.size;
    const isFileExtensionInvalid = validateFileExtension(file, acceptedTypes);
    if (isFileExtensionInvalid) {
      fileExtensionInvalid = isFileExtensionInvalid;
    }
    return { file, isFileExtensionInvalid };
  });
  return Promise.resolve({ files: list, fileExtensionInvalid, maxSizeInvalid: validateMaxSize(totalSize, maxSize) });
}
type FileListType = Array<Pick<IFileUploadListItem, 'file' | 'isFileExtensionInvalid'>>;
function buildFileList(
  setList: SetStateType<React.ReactElement[]>,
  setData: SetStateType<IFileUploadData>,
  getData: ((data: IFileUploadData) => void) | undefined,
  setMaxSizeInvalid: SetStateType<boolean>,
  files: File[],
  data: IFileUploadData,
  acceptedTypes: FileUploadType,
  maxSize: number
) {
  getFileList(files, acceptedTypes, maxSize).then((dataList) => {
    const list = dataList.files.map((props) => {
      return (
        <FileUploadListItem
          removeFile={(fileName) => {
            const idx = files.findIndex((v) => v.name === fileName);
            files.splice(idx, 1);
            buildFileList(setList, setData, getData, setMaxSizeInvalid, files, data, acceptedTypes, maxSize);
          }}
          key={props.file.name}
          {...props}
        />
      );
    });
    setList(list);
    setMaxSizeInvalid(dataList.maxSizeInvalid);
    const isInvalid = dataList.maxSizeInvalid || dataList.fileExtensionInvalid;
    const result = { files, isInvalid };
    setData(result);
    if (typeof getData === 'function') {
      getData(result);
    }
  });
}
type SetStateType<T> = React.Dispatch<React.SetStateAction<T>>;
interface IGetFileList {
  files: FileListType;
  fileExtensionInvalid: boolean;
  maxSizeInvalid: boolean;
}
function validateMaxSize(totalSize: number, maxSize: number) {
  if (maxSize === 0) {
    return false;
  }
  return totalSize / Math.pow(1024, 2) >= maxSize;
}
function validateFileExtension(file: File, acceptedTypes: FileUploadType) {
  const types = acceptedTypes.join('|').replace(/\s/gi, '');
  const regExpTypesStr = `(${ types })$`;
  const checkFileTypesRegExp = new RegExp(regExpTypesStr, 'gi');
  return !checkFileTypesRegExp.test(file.name);
}
function getFilesFromDataTransfer(dt: DataTransfer) {
  if (!dt) {
    return;
  }
  const items = dt.items ?? dt.files;
  const files = Array.prototype.slice.call(items);
  return files.map((file) => (file.getAsFile ? file.getAsFile() : file));
}
function removeDuplicates(files: File[]) {
  return files.filter((item, i) => {
    return files.findIndex((value) => value.name === item.name) === i;
  });
}
