import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Button } from '@proliance-ai/design-system';
import { notificationService, modalHandler, modalService } from '@proliance-ai/react-ui';
import { pendoService, userService } from '@services';
import LanguageToggle from '@layout/AppLayout/components/LanguageToggle/LanguageToggle';
import { ConsentModal } from '../../';

import styles from '../Outlet.styl';

export const useConsentModal = (): void => {
  const { i18n, t } = useTranslation();
  const [ isShowModal, setIsShowModal ] = useState(false);

  const submit = (value: boolean) => () => {
    userService.updateAnalyticsAllowed(value)
      .pipe(
        take(1),
        catchError(() => {
          const dataAttributesDictionary = {
            test: { notificationError: 'updateAnalyticsAllowed' },
            guide: { notificationError: 'updateAnalyticsAllowed' }
          };
          notificationService.error({
            textTranslationKey: 'consentModal.message.error',
            dataAttributesDictionary
          });
          return of(null);
        })
      )
      .subscribe((): void => {
        pendoService.setGuidesDisabled(false);
        if (value) {
          pendoService.loadGuides();
        }
      });
  };

  const getActionsElement = () => (
    <>
      <Button
        preset="default"
        dataAttributesDictionary={ {
          test: { button: 'decline' },
          guide: { button: 'decline' }
        } }
        text={ t('consentModal.decline') }
        onClick={ modalHandler(submit(false)) }
      />
      <Button
        preset="primary"
        dataAttributesDictionary={ {
          test: { button: 'agree' },
          guide: { button: 'agree' }
        } }
        text={ t('consentModal.agree') }
        onClick={ modalHandler(submit(true)) }
      />
    </>
  );

  const showConsentModal = (): void => {
    modalService.show({
      id: 'consentOutlet',
      size: 'large',
      heading: {
        title: () => t('consentModal.title'),
        actionsElement: <LanguageToggle/>,
        showCloseButton: false
      },
      content: {
        contentElement: <ConsentModal/>
      },
      footer: {
        actionsClassName: styles.actions,
        actionsElement: getActionsElement()
      }
    });
  };

  useEffect(
    (): void => {
      if (isShowModal) {
        modalService
          .patchModalOptions({
            id: 'consentOutlet',
            footer: {
              actionsElement: getActionsElement()
            }
          });
      }
    },
    [ i18n.language ]
  );

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    userService.isShowConsentModal$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((isShowConsentModal: boolean): void => {
        setIsShowModal(isShowConsentModal);
      });
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  useEffect(
    (): void => {
      if (isShowModal) {
        showConsentModal();
      }
    },
    [ isShowModal ]
  );
};
