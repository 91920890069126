import { SoftwareFormKey } from '@proliance-ai/typings';

export const softwareSteps: SoftwareFormKey[] = [
  'management',
  'type',
  'operators',
  'vendor',
  'dataTypes',
  'description',
  'categories',
  'accesses',
  'rpaAsController'
];
