import { useMemo } from 'react';
import { Column } from 'react-table';
import { TranslationInfo } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { Systems, SystemsEntity } from '@services';
import getBusinessPremiseColumnList from './getBusinessPremiseColumnList';
import getAssociatedCompaniesColumnList from './getAssociatedCompaniesColumnList';
import getWebsiteColumnList from './getWebsiteColumnList';
import getSoftwareColumnList from './getSoftwareColumnList';
import getServiceProviderColumnList from './getServiceProviderColumnList';
import getFreelancerColumnList from './getFreelancerColumnList';
import getDataStorageSelfHostedColumnList from './getDataStorageSelfHostedColumnList';
import getDataStorageThirdPartyHostedColumnList from './getDataStorageThirdPartyHostedColumnList';
import getDataStorageCloudHostedColumnList from './getDataStorageCloudHostedColumnList';
import getPrincipalColumnList from './getPrincipalColumnList';
import { useTranslation } from 'react-i18next';

export const getColumns = (
  namespace: string | string[],
  systemsType: Systems,
  countryList: Array<IDictionaryResponse<TranslationInfo>>
): Array<Column<SystemsEntity>> => {
  const { t } = useTranslation(namespace);
  switch (systemsType) {
    case Systems.BUSINESS_PREMISE:
      return useMemo(
        () => getBusinessPremiseColumnList(t, systemsType, countryList) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.ASSOCIATED_COMPANY:
      return useMemo(
        () => getAssociatedCompaniesColumnList(t, systemsType, countryList) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.WEBSITE:
      return useMemo(
        () => getWebsiteColumnList(t, systemsType) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.SOFTWARE:
      return useMemo(
        () => getSoftwareColumnList(t, systemsType) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.SERVICE_PROVIDER:
      return useMemo(
        () => getServiceProviderColumnList(t, systemsType, countryList) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.PRINCIPAL:
      return useMemo(
        () => getPrincipalColumnList(t, systemsType, countryList) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.FREELANCER:
      return useMemo(
        () => getFreelancerColumnList(t, systemsType, countryList) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_SELF_HOSTED:
      return useMemo(
        () => getDataStorageSelfHostedColumnList(t, systemsType) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_THIRD_PARTY_HOSTED:
      return useMemo(
        () => getDataStorageThirdPartyHostedColumnList(t, systemsType) as Array<Column<SystemsEntity>>,
        []
      );
    case Systems.DATA_STORAGE_CLOUD_HOSTED:
      return useMemo(
        () => getDataStorageCloudHostedColumnList(t, systemsType) as Array<Column<SystemsEntity>>,
        []
      );
  }
};
