import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Collection } from '@proliance-ai/typings';
import { TaskTypeEncode, TaskTypeGroup, TaskTypeType } from '@interfaces';
import { academyService, companyService, ITask, ITaskService, TaskReturnType, TaskTabDictionary } from '@services';
import { taskApiService } from '@services/api';

import {
  faDiceD6,
  faShield,
  faFileSearch,
  faBallot,
  faChartNetwork,
  faClipboardListCheck,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons';

export const basicFilter = {
  page: 1,
  pageSize: 10,
  sortField: 'updatedAt',
  sortDirection: 'desc',
  status: 'OPEN',
  group: 'BASIC'
};

const taskTypesDictionary = {
  [TaskTypeGroup.GENERAL]: [ 'DPO_NOMINATION', 'DPO_PRIVACY_POLICY', 'DPO_NOTIFICATION', 'DEFINE_TEAM', 'ACADEMY_INVITE_EMPLOYEE' ],
  [TaskTypeGroup.PROCESS_SELECTION]: [ 'PROCESS_SELECTION', 'ADDITIONAL_PROCESS_SELECTION' ],
  [TaskTypeGroup.PROCESS_CONTEXT]: [ 'PROCESS_CONTEXT', 'PROCESS_DETAIL' ],
  [TaskTypeGroup.AUDIT]: [ 'AUDIT_CONCEPT' ],
  [TaskTypeGroup.ACTION]: [ 'ACTION' ],
  [TaskTypeGroup.TOM]: [ 'TOM' ],
  [TaskTypeGroup.ACADEMY]: [ 'ACADEMY' ]
};

export const encodeTaskType = (type: TaskTypeType) => {
  return TaskTypeEncode[type];
};

export const getTaskType = (itemType: string): TaskReturnType => {
  const isType = (type: string) => type === itemType;

  if (taskTypesDictionary[TaskTypeGroup.TOM].some(isType)) {
    return TaskTypeGroup.TOM;
  }
  if (taskTypesDictionary[TaskTypeGroup.GENERAL].some(isType)) {
    return TaskTypeGroup.GENERAL;
  }
  if (taskTypesDictionary[TaskTypeGroup.ACTION].some(isType)) {
    return TaskTypeGroup.ACTION;
  }
  if (taskTypesDictionary[TaskTypeGroup.AUDIT].some(isType)) {
    return TaskTypeGroup.AUDIT;
  }
  if (taskTypesDictionary[TaskTypeGroup.PROCESS_SELECTION].some(isType)) {
    return TaskTypeGroup.PROCESS_SELECTION;
  }
  if (taskTypesDictionary[TaskTypeGroup.PROCESS_CONTEXT].some(isType)) {
    return TaskTypeGroup.PROCESS_CONTEXT;
  }
};

const getTaskIcon = (type: TaskTypeType | TaskTypeGroup.TOM): null | IconDefinition => {
  switch (getTaskType(type)) {
    case TaskTypeGroup.GENERAL:
      return faDiceD6;
    case TaskTypeGroup.AUDIT:
      return faFileSearch;
    case TaskTypeGroup.PROCESS_SELECTION:
      return faBallot;
    case TaskTypeGroup.PROCESS_CONTEXT:
      return faChartNetwork;
    case TaskTypeGroup.ACTION:
      return faClipboardListCheck;
    case TaskTypeGroup.TOM:
      return faShield;
    default:
      return null;
  }
};

const getTaskCount = (parameters: Record<string, any> = {}): Observable<TaskTabDictionary<number>> => {
  parameters.companyId = companyService.company$.value!.id;
  return taskApiService.getTaskCount(parameters);
};

const getTask = (parameters: Record<string, any> = {}): Observable<Collection<ITask>> => {
  parameters.companyId = companyService.company$.value!.id;
  return taskApiService
    .getTask(parameters)
    .pipe(
      switchMap((response: Collection<ITask>) => {
        const idList = response
          .elements
          .reduce(
            (result: string[], item: ITask) => {
              if (item.type === 'ACADEMY') {
                result.push(item.id);
              }
              return result;
            },
            []
          );
        return academyService
          .getCourseLinkDictionary(idList)
          .pipe(
            map(
              (linkDictionary: Record<string, string>): Collection<ITask> => {
                const elements = response
                  .elements
                  .map(
                    (item: ITask): ITask => {
                      const link = linkDictionary[item.id] || '';
                      return { ...item, link };
                    }
                  );
                return { ...response, elements } as Collection<ITask>;
              }
            )
          );
      })
    );
};

export const taskService: ITaskService = {
  getTaskCount,
  getTask,
  getTaskIcon
};
