// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-Dashboard_hjh6V{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;width:100%}.content-Dashboard_rOPHt{-webkit-flex:1;-ms-flex:1;flex:1;margin-right:16px}.sideBar-Dashboard_cXeIb{max-width:353px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:0 0 353px;-ms-flex:0 0 353px;flex:0 0 353px}.sideBar-Dashboard_cXeIb:empty{display:none}.main-Dashboard_KvIxa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;width:100%}.carousel-Dashboard_VS_jW{position:relative;width:100%;height:360px;padding:24px 10px 24px 24px;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto}.carousel-Dashboard_VS_jW.single-Dashboard_mXIYJ{height:280px}.dots-Dashboard_WI7qi{margin:24px auto 0}.areaScoreWidget-Dashboard_WF1di{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center}.activitiesWidget-Dashboard_cpfc5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-Dashboard_hjh6V",
	"content": "content-Dashboard_rOPHt",
	"sideBar": "sideBar-Dashboard_cXeIb",
	"main": "main-Dashboard_KvIxa",
	"carousel": "carousel-Dashboard_VS_jW",
	"single": "single-Dashboard_mXIYJ",
	"dots": "dots-Dashboard_WI7qi",
	"areaScoreWidget": "areaScoreWidget-Dashboard_WF1di",
	"activitiesWidget": "activitiesWidget-Dashboard_cpfc5"
};
export default ___CSS_LOADER_EXPORT___;
