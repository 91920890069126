import {
  CalendarOptions,
  createElement,
  DatePickerPlugin,
  DateRangePickerPlugin,
  DateRangePickerValue,
  IDatePickerSettings,
  IDateRangePickerSettings,
  isEmptyValue,
  removeClass,
  setClass,
  setElementAttributes,
  setElementContent,
  Shortcut,
  ShortcutItemResult,
  ShortcutItemValue
} from '@proliance-ai/design-system';
import { IProperty, IPropertyChangeOption, WidgetEditorLocalization, WidgetLocalization } from '../interfaces';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { Question, ItemValue } from '@shared/surveyJs/reexport';

import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

import { addClass } from '../common';
import { useIcon } from './utilities';
import {
  allowClearProperty,
  allowSingleDateProperty,
  clearButtonProperty,
  closeOnInputClickProperty,
  labelProperty,
  maxDateProperty,
  maxRelativeDateProperty,
  minDateProperty,
  minRelativeDateProperty,
  placeholderProperty,
  resetButtonProperty,
  restoreButtonProperty,
  shortcutsProperty,
  shortModeProperty,
  showTimeProperty,
  singleDateProperty,
  singleCalendarProperty,
  startWeekFromSundayProperty
} from './properties';

import './styles.styl';

export type CalendarQuestionValue = null | string | Date | DateRangePickerValue<null | string | Date>;
export type CalendarValue = undefined | null | string | DateRangePickerValue<null | number | string | Date>;

export interface ICalendarQuestion extends Question {
  options: CalendarOptions;
  value: CalendarQuestionValue;
  label?: string;
  placeholder?: string;
  allowClear: boolean;
  singleDate: boolean;
  singleCalendar: boolean;
  showTime: boolean;
  shortMode: boolean;
  startWeekFromSunday: boolean;
  maxDate: Date;
  minDate: Date;
  maxRelativeDate: string;
  minRelativeDate: string;
  allowSingleDate: boolean;
  clearButton: boolean;
  restoreButton: boolean;
  resetButton: boolean;
  closeOnInputClick: boolean;
  shortcuts: ItemValue[];
  plugin: any;
}

export default class CalendarWidget extends WidgetAbstractClass<Question> {
  public customPropertiesList: IProperty[] = [
    labelProperty,
    placeholderProperty,
    allowClearProperty,
    singleDateProperty,
    allowSingleDateProperty,
    singleCalendarProperty,
    showTimeProperty,
    shortModeProperty,
    startWeekFromSundayProperty,
    maxDateProperty,
    minDateProperty,
    maxRelativeDateProperty,
    minRelativeDateProperty,
    shortcutsProperty,
    clearButtonProperty,
    restoreButtonProperty,
    resetButtonProperty,
    closeOnInputClickProperty
  ];

  public hidePropertyNameList: string[] = [
    'defaultValueExpression',
    'state'
  ];

  public surveyLocalization: WidgetLocalization = {
  };

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Calendar',
        de: 'Kalender'
      }
    },
    pehelp: {
      shortcuts: {
        en: `Use RTS (https://devhub.datenschutzexperte.de/proliance-ai/proliance-ai_platform/-/wikis/Relative-time-syntax) or
        JavaScript Date constructor (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date) compatible value
         for single date values.
         For date range values use '{"from":"today","to":"-1day"}' format.
         To create label just set 'label' to value.
         `,
        de: `RTS verwenden (https://devhub.datenschutzexperte.de/proliance-ai/proliance-ai_platform/-/wikis/Relative-time-syntax) oder
         JavaScript-Datumskonstruktor (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date) kompatibler Wert
          für einzelne Datumswerte.
          Verwenden Sie für Datumsbereichswerte das Format '{"from":"today","to":"-1day"}'.
          Um ein Label zu erstellen, setzen Sie einfach 'label' auf einen Wert.
          `
      }
    },
    p: {
      label: {
        en: 'Label',
        de: 'Label'
      },
      placeholder: {
        en: 'Placeholder',
        de: 'Platzhalter'
      },
      singleDate: {
        en: 'Single date',
        de: 'Einzeldatum'
      },
      allowClear: {
        en: 'Allow clear',
        de: 'Löschen zulassen'
      },
      shortMode: {
        en: 'Short mode',
        de: 'Kurzmodus'
      },
      maxDate: {
        en: 'Max date',
        de: 'Max. Datum'
      },
      maxRelativeDate: {
        en: 'Max relative date',
        de: 'Max. relatives Datum'
      },
      minDate: {
        en: 'Min date',
        de: 'Min. Datum'
      },
      minRelativeDate: {
        en: 'Min relative date',
        de: 'Min. relatives Datum'
      },
      startWeekFromSunday: {
        en: 'From sunday',
        de: 'Von Sonntag'
      },
      singleCalendar: {
        en: 'Single calendar',
        de: 'Einzelkalender'
      },
      allowSingleDate: {
        en: 'Allow single date',
        de: 'Einzeldatum zulassen'
      },
      showTime: {
        en: 'Show time',
        de: 'Uhrzeit'
      },
      shortcuts: {
        en: 'Shortcuts',
        de: 'Kürzel'
      },
      clearButton: {
        en: 'Clear button',
        de: 'Löschen-Button'
      },
      restoreButton: {
        en: 'Restore button',
        de: 'Wiederherstellen-Button'
      },
      resetButton: {
        en: 'Reset button',
        de: 'Zurücksetzen-Button'
      },
      closeOnInputClick: {
        en: 'Close on input click',
        de: 'Eingabeklick schließt'
      }
    }
  };

  protected alternativeNameList: string[] = [ 'dsedatepicker' ];

  protected classNameDictionary: Record<string, string> = {
    wrap: 'calendar-widget',
    input: 'calendar-input',
    label: 'calendar-label',
    reset: 'calendar-reset',
    show: 'calendar-show',
    icon: 'calendar-icon',
    slot_1: 'slotRight_1',
    slot_2: 'slotRight_2'
  };

  constructor() {
    super({
      name: 'calendar',
      iconName: 'calendar',
      icon: faCalendar
    });
  }

  public renderer(question: ICalendarQuestion, element: HTMLElement): void {
    addClass(element, this.classNameDictionary.wrap);

    const input = createElement<'input'>({
      tagName: 'input',
      classNames: [
        this.classNameDictionary.input,
        ...(question.allowClear ? [ this.classNameDictionary.slot_2 ] : [ this.classNameDictionary.slot_1 ])
      ],
      attributes: {
        id: question.name,
        type: 'text',
        ...(question.placeholder && { placeholder: question.placeholder })
      }
    });
    element.append(input);

    const label = createElement<'label'>({
      tagName: 'label',
      classNames: this.classNameDictionary.label,
      attributes: {
        htmlFor: question.name
      },
      content: question.label
    });
    element.appendChild(label);

    const icon = createElement({
      classNames: this.classNameDictionary.icon
    });
    useIcon(icon, 'icon');
    element.appendChild(icon);

    const reset = createElement({
      classNames: [
        this.classNameDictionary.reset,
        ...(question.allowClear && !!question.value ? [ this.classNameDictionary.show ] : [])
      ]
    });
    useIcon(reset, 'reset');
    element.appendChild(reset);
  }

  public afterRender(
    question: ICalendarQuestion,
    element: HTMLElement
  ): void {
    super.afterRender(question, element);

    const input = this.getElement<HTMLInputElement>('input', element);
    if (input === null) {
      console.error('Element "input" not found');
      return;
    }

    const {
      singleDate,
      singleCalendar,
      allowSingleDate,
      showTime,
      shortMode,
      startWeekFromSunday,
      maxDate,
      minDate,
      maxRelativeDate,
      minRelativeDate,
      clearButton: showClearButton,
      restoreButton: showRestoreButton,
      resetButton: showResetButton,
      closeOnInputClick
    } = question;

    const reset = this.getElement<HTMLDivElement>('reset', element);
    const onClear = () => {
      question.value = '';
      removeClass(input, this.classNameDictionary.show);
    };
    if (reset) {
      reset.addEventListener('click', onClear);
    }

    const setValue = (value: CalendarQuestionValue) => {
      question.value = value;
      this.updateResetState(question, element);
    };

    question.options = {
      value: question.value as CalendarValue,
      language: this.getCurrentLocale(question),
      shortcuts: this.getShortCuts(question.shortcuts),
      setValue,
      utc: !showTime,
      returnFormat: 'YYYY-MM-DDTHH:mm:ss',
      alternativeFormatList: [ 'YYYY-MM-DDTHH:mm' ],
      defaultTime: '00:00:00',
      singleCalendar,
      allowSingleDate,
      showTime,
      shortMode,
      startWeekFromSunday,
      maxDate,
      minDate,
      maxRelativeDate,
      minRelativeDate,
      showClearButton,
      showRestoreButton,
      showResetButton,
      closeOnInputClick
    } as CalendarOptions;

    question.plugin = singleDate
      ? new DatePickerPlugin(input, question.options as Partial<IDatePickerSettings>)
      : new DateRangePickerPlugin(input, question.options as Partial<Partial<IDateRangePickerSettings>>);

    this.onChangeReadOnly(question, element);
  }

  public onChangeReadOnly(question: ICalendarQuestion, element: HTMLElement): void {
    const input = this.getElement<HTMLInputElement>('input', element);
    if (!input) {
      return;
    }
    question.isReadOnly
      ? input.setAttribute('disabled', 'disabled')
      : input.removeAttribute('disabled');
    this.updateResetState(question, element);
  }

  public updateResetState(question: ICalendarQuestion, element: HTMLElement): void {
    const reset = this.getElement<HTMLDivElement>('reset', element);
    if (!reset) {
      return;
    }
    const isCanReset = question.allowClear
      && !question.isReadOnly
      && !isEmptyValue(question.singleDate, question.value as any);
    setClass(reset, this.classNameDictionary.show, isCanReset);
    setClass(element, this.classNameDictionary.slot_2, isCanReset);
    setClass(element, this.classNameDictionary.slot_1, !isCanReset);
  }

  public willUnmount(question: ICalendarQuestion, element: HTMLElement): void {
    question.plugin.close();
    super.willUnmount(question, element);
  }

  protected onPropertyChangedHandler(
    question: ICalendarQuestion,
    element: HTMLElement,
    options: IPropertyChangeOption<any>): void {
    const input = this.getElement<HTMLInputElement>('input', element);
    if (!input) {
      return;
    }
    switch (options.name) {
      case 'readOnly':
        this.onChangeReadOnly(question, element);
        return;
      case 'label':
        const label = this.getElement<HTMLLabelElement>('label', element);
        if (label) {
          setElementContent(label, options.newValue);
        }
        return;
      case 'value':
        question.plugin.update({
          ...question.options,
          value: options.newValue
        });
        this.updateResetState(question, element);
        return;
      case 'placeholder':
        setElementAttributes(input, { placeholder: options.newValue });
        return;
      case 'allowClear':
        this.updateResetState(question, element);
        return;
      case 'minDate':
        if (question.maxDate && question.maxDate < options.newValue) {
          question.maxDate = options.newValue;
        }
        return;
      case 'maxDate':
        if (question.minDate && question.minDate > options.newValue) {
          question.minDate = options.newValue;
        }
        return;
    }
  }

  private getShortCuts(shortcutList: ItemValue[]): Array<Shortcut<ShortcutItemValue, ShortcutItemResult>> {
    let currentShortcut: null | Shortcut<ShortcutItemValue, ShortcutItemResult> = null;
    return shortcutList
      .reduce((result: Array<Shortcut<ShortcutItemValue, ShortcutItemResult>>, item: ItemValue, index: number) => {
        const { en: englishLanguage, de: germanLanguage, default: defaultLanguage } = item.locTextValue.values;
        const en: string = englishLanguage || defaultLanguage || '';
        const de: string = germanLanguage || defaultLanguage || '';
        if (item.value === 'label') {
          if (currentShortcut !== null) {
            result.push(currentShortcut);
            currentShortcut = null;
          }
          currentShortcut = {
            label: { en, de },
            itemList: []
          };
        } else {
          if (currentShortcut === null) {
            currentShortcut = {
              itemList: []
            };
          }
          currentShortcut.itemList.push({
            value: item.value,
            name: { en, de }
          });
        }
        if (index === shortcutList.length - 1) {
          result.push(currentShortcut);
        }
        return result;
      },
      []
      );
  }
}
