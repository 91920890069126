import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  RefObject,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'react-router5';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { classNameArrayToString } from '@proliance-ai/design-system';
import { menuService } from '@proliance-ai/react-ui';
import {
  companyService,
  Company,
  PagePermissionData,
  permissionService,
  productService,
  userService
} from '@services';
import { defaultRoute, isExternalRoute } from '@router';
import { RouteListType } from '@router/configuration';
import { LanguageSwitcher } from '@layout/components';
import { UserMenu } from '../UserMenu';
import { ProductSwitcherButton } from '../ProductSwitcherButton';
import { CompanySwitcherButton } from '../CompanySwitcherButton';
import { HorizontalMenu, HorizontalMenuItemData } from './components';

import { faBuilding } from '@fortawesome/pro-light-svg-icons';
import styles from './Header.styl';

export type Ref = HTMLDivElement;

interface HeaderProperties {
  ref?: RefObject<Ref>;
  hideMenu?: boolean;
  hideUser?: boolean;
}

export const Header: ForwardRefExoticComponent<Omit<HeaderProperties, 'ref'> & RefAttributes<HTMLDivElement>> = forwardRef<Ref, HeaderProperties>(
  ({ hideMenu = false, hideUser = false }, ref: ForwardedRef<Ref>) => {
    const { t } = useTranslation();
    const { router } = useRoute();
    const currentRouteName = router.getState().name;
    const defaultRouteValue = defaultRoute();
    const defaultRouteLink = currentRouteName === defaultRouteValue || isExternalRoute(defaultRouteValue)
      ? undefined
      : defaultRouteValue as RouteListType;

    const unsubscribe$ = new Subject<void>();

    const [ organizationPagePermission, setOrganizationPagePermission ] = useState<undefined | boolean>();
    const [ isMultitenancy, setIsMultitenancy ] = useState<null | boolean>(null);
    const [ currentCompany, setCurrentCompany ] = useState<null | Company>(null);
    const [ isMultiProduct, setIsMultiProduct ] = useState<null | boolean>(null);
    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(menuService.isCollapsed$.value);

    useEffect(() => {
      companyService.isMultitenancy$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((value: null | boolean): void => setIsMultitenancy(value));
      companyService.company$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((company: Company | null): void => setCurrentCompany(company));
      productService.isMultiProduct$
        .pipe(takeUntil(unsubscribe$))
        .subscribe(setIsMultiProduct);
      permissionService.pagePermissionSubject$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((pagePermissionData: PagePermissionData | null) => {
          setOrganizationPagePermission(pagePermissionData?.organization);
        });
      menuService.isCollapsed$
        .pipe(takeUntil(unsubscribe$))
        .subscribe((isCollapsed: boolean) => setIsCollapsed(isCollapsed));
      return () => {
        unsubscribe$.next();
        unsubscribe$.complete();
      };
    }, []);

    const isImpersonated = userService.userSubject$.value?.impersonatedBy;
    const languageSwitcher = isImpersonated
      ? <LanguageSwitcher/>
      : null;
    const user = hideUser
      ? null
      : (
        <>
          { languageSwitcher }
          <UserMenu/>
        </>
      );

    const delimiter = <div className={ `${ styles.divider } ${ styles.wide }` }></div>;

    const menuData: HorizontalMenuItemData[] = [
      ...(typeof organizationPagePermission !== 'undefined'
        ? [ {
          icon: faBuilding,
          name: t('menu.organization'),
          routeName: 'organization' as RouteListType,
          disabled: !organizationPagePermission
        } ]
        : []
      )
    ];

    const className = classNameArrayToString([
      styles.container,
      ...[ isCollapsed && styles.collapsed ]
    ]);

    return (
      <header
        ref={ ref }
        className={ styles.header }
      >
        <div className={ className }>
          <div className={ styles.columnLeft }>
            <ProductSwitcherButton
              route={ defaultRouteLink }
              hideMenu={ hideMenu }
              isCollapsed={ isCollapsed }
              currentCompany={ currentCompany }
              isMultiProduct={ isMultiProduct }
              isMultitenancy={ isMultitenancy }
            />
            <CompanySwitcherButton
              currentCompany={ currentCompany }
              isMultiProduct={ isMultiProduct }
              isMultitenancy={ isMultitenancy }
            />
          </div>
          <div className={ styles.columnRight }>
            <HorizontalMenu menuData={ menuData }/>
            { delimiter }
            { user }
          </div>
        </div>
      </header>
    );
  });
