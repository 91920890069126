import React, { FC, useCallback, useEffect } from 'react';
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  TableInstance,
  Column,
  UseSortByInstanceProps,
  UseSortByOptions,
  UseTableOptions,
  UseFiltersOptions,
  UsePaginationOptions,
  UseTableInstanceProps,
  UsePaginationInstanceProps,
  UseFiltersInstanceProps,
  TableState,
  UseSortByState,
  UseFiltersState,
  UsePaginationState,
  RowPropGetter
} from 'react-table';
import { SystemsEntity } from '@services';
import { TableHead, TableBody, TablePagination, debounce, tableStyles } from '@proliance-ai/design-system';
import { isEqual } from '@proliance-ai/utilities';

const styles = tableStyles.default;

interface ITableOptions<D extends object = {}> extends UseTableOptions<D>,
  UseFiltersOptions<D>,
  UsePaginationOptions<D>,
  UseSortByOptions<D> {
}

type SystemsEntityTableOptions = ITableOptions<SystemsEntity>;

interface ITableInstance<D extends object = {}> extends TableInstance<D>,
  UseTableInstanceProps<D>,
  UseFiltersInstanceProps<D>,
  UsePaginationInstanceProps<D>,
  UseSortByInstanceProps<D> {
}

interface ITableState<D extends object = {}> extends TableState<D>,
  UseFiltersState<D>,
  UsePaginationState<D>,
  UseSortByState<D> {
}

interface IOwnProps {
  columns: Array<Column<SystemsEntity>>;
  data: SystemsEntity[];
  pageCount: number;
  initialState: Partial<ITableState<SystemsEntity>>;
  loading: boolean;
  fetchData: (state: Partial<ITableState<SystemsEntity>>) => void;
  emptyText: string;
  propGetter?: RowPropGetter<SystemsEntity>;
  total?: number;
}

type Props = IOwnProps;

const Table: FC<Props> = ({
  columns,
  data,
  pageCount: controlledPageCount,
  loading,
  fetchData,
  initialState,
  emptyText,
  propGetter,
  total
}) => {
  const tableOptions: SystemsEntityTableOptions = {
    columns,
    initialState,
    data,
    pageCount: controlledPageCount,
    manualFilters: true,
    manualSortBy: true,
    manualPagination: true,
    disableMultiSort: true,
    disableSortRemove: true
  };

  const tableInstance = useTable(
    tableOptions,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    state,
    pageCount,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    nextPage,
    previousPage
  } = tableInstance as ITableInstance<SystemsEntity>;

  const { pageIndex, pageSize, sortBy } = state as ITableState;
  const fetchDataDebounced = useCallback(debounce(fetchData, 200), []);
  useEffect(() => {
    fetchDataDebounced({ pageIndex, pageSize, sortBy });
  }, [ pageIndex, pageSize, sortBy ]);

  return (
    <div className={ styles.wrap }>
      <table { ...getTableProps() } className={ styles.table }>
        <TableHead
          headerGroups={ headerGroups }
          hideSorting={ data.length < 2 && isEqual(sortBy, initialState.sortBy) }
        />
        <TableBody
          data={ data }
          loading={ loading }
          page={ page }
          prepareRow={ prepareRow }
          emptyText={ emptyText }
          columns={ columns }
          propGetter={ propGetter }
        />
      </table>
      <div className={ styles.footer }>
        <TablePagination
          canPreviousPage={ canPreviousPage }
          canNextPage={ canNextPage }
          nextPage={ nextPage }
          previousPage={ previousPage }
          gotoPage={ gotoPage }
          setPageSize={ setPageSize }
          pageIndex={ pageIndex }
          pageCount={ pageCount }
          pageSize={ pageSize }
          total={ total }
        />
      </div>
    </div>
  );
};

export default Table;
