import { ThirdParty } from '@services';
import { documentsMapper, evidencesMapper, legalStatusesMapper, waitForLocalization } from '.';

export const thirdPartyMapper = async (thirdParty: ThirdParty) => {
  await waitForLocalization('assetManagement:evidence');
  const evidencesMapperResult = await evidencesMapper(thirdParty);
  return {
    ...thirdParty,
    ...legalStatusesMapper(thirdParty),
    ...documentsMapper(thirdParty),
    ...evidencesMapperResult
  };
};
