// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rule-PasswordValidator_ZpiSg{color:#797979;font-size:14px;font-weight:400;letter-spacing:.26px;line-height:1.4;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}@media all and (max-width:1025px){.rule-PasswordValidator_ZpiSg{font-size:12px}}@media all and (max-width:560px){.rule-PasswordValidator_ZpiSg{font-size:10px;line-height:1.7}}.ruleWrap-PasswordValidator_BqOXf{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}.ruleText-PasswordValidator_f1KCV{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;margin-top:0;margin-bottom:.5rem;font-size:14px;font-weight:300;letter-spacing:.26px;color:#4f4f4f}@media all and (max-width:1025px){.ruleText-PasswordValidator_f1KCV{font-size:12px}}@media all and (max-width:560px){.ruleText-PasswordValidator_f1KCV{font-size:10px}}.ruleList-PasswordValidator_qus2s{margin-top:0;margin-bottom:0}.rulePass-PasswordValidator_eLBCh{color:#27ae60 !important}.ruleFail-PasswordValidator_vEEa2{color:#eb5757 !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rule": "rule-PasswordValidator_ZpiSg",
	"ruleWrap": "ruleWrap-PasswordValidator_BqOXf",
	"ruleText": "ruleText-PasswordValidator_f1KCV",
	"ruleList": "ruleList-PasswordValidator_qus2s",
	"rulePass": "rulePass-PasswordValidator_eLBCh",
	"ruleFail": "ruleFail-PasswordValidator_vEEa2"
};
export default ___CSS_LOADER_EXPORT___;
