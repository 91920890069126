import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  ReactElement,
  RefAttributes,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Footer as FooterComponent, FooterOwnProps } from '@proliance-ai/react-ui';
import { systemsService } from '@services';

export type Ref = HTMLDivElement;
const Footer: ForwardRefExoticComponent<Omit<FooterOwnProps, 'ref'> & RefAttributes<HTMLDivElement>> = forwardRef<Ref, FooterOwnProps>(
  ({ disableMenu }, ref: ForwardedRef<Ref>): ReactElement => {
    const { t } = useTranslation();
    const [ year, setYear ] = useState<null | number>(new Date().getFullYear());

    const unsubscribe$ = new Subject<void>();
    useEffect(
      () => {
        systemsService
          .getCurrentTime()
          .pipe(takeUntil(unsubscribe$))
          .pipe(catchError(() => of(new Date().toString())))
          .subscribe((currentTime: string) => {
            setYear(new Date(currentTime).getFullYear());
          });
        return () => {
          unsubscribe$.next();
          unsubscribe$.complete();
        };
      },
      []
    );

    const copyright = t('footer.copyright', { year });

    const lobbyPublicUrl = (window as any).env.lobbyPublicUrl;
    const menu = [
      {
        text: t('footer.links.imprint'),
        url: `${ lobbyPublicUrl }/imprint`
      },
      {
        text: t('footer.links.privacyPolicy'),
        url: `${ lobbyPublicUrl }/privacy-policy`
      },
      {
        text: t('footer.links.genderNotice'),
        url: `${ lobbyPublicUrl }/gender-notice`
      }
    ];

    return (
      <FooterComponent
        ref={ ref }
        disableMenu={ disableMenu }
        copyright={ copyright }
        menu={ menu }
      />
    );
  });

export default Footer;
