import { Observable } from 'rxjs';
import { Collection, CollectionParameters } from '@proliance-ai/typings';
import { Document, IDocumentService } from '@services';
import { DocumentType } from './document.typings';
import { documentApiService } from '@services/api';
import { getFilterParameters } from '@shared/utilities';
import { defaultPageSize } from '@proliance-ai/design-system';

const defaultOptions = {
  currentPage: 0,
  pageSize: defaultPageSize,
  sortBy: [
    {
      id: 'createdAt',
      desc: true
    }
  ],
  filters: [
    {
      id: 'type',
      value: DocumentType.PRIVATE
    }
  ]
};

const getParameters = (options: any): CollectionParameters => {
  const { pageSize, currentPage, sortBy, filters } = options;
  const filterParameters = getFilterParameters(filters);
  const parameters: CollectionParameters = { ...filterParameters };
  parameters.page = currentPage + 1;
  parameters.pageSize = pageSize;
  if (typeof options.sortBy[0] !== 'undefined') {
    parameters.sortField = sortBy[0].id;
    parameters.sortDirection = sortBy[0].desc ? 'desc' : 'asc';
  }
  return parameters;
};

const getDocumentCollection = (options?: object): Observable<Collection<Document>> => {
  const parameters = getParameters({ ...defaultOptions, ...options });
  return documentApiService.getDocumentCollection(parameters);
};
export const documentService: IDocumentService = {
  getDocumentCollection,
  uploadDocument: documentApiService.uploadDocument.bind(documentApiService),
  deleteDocument: documentApiService.deleteDocument.bind(documentApiService)
};
