import { Observable } from 'rxjs';
import { Api } from '@services/api';
import { Collection, CollectionParameters } from '@proliance-ai/typings';
import { RiskState, IRiskCase, ISurveyData, ExportRiskFormat } from '@services';

const apiUrl = '/api/survey/risk';

class RiskApiService extends Api {
  public getRiskCollection(parameters: CollectionParameters): Observable<Collection<IRiskCase>> {
    return this.get<Collection<IRiskCase>>({ parameters });
  }

  public updateRiskState(id: string, state: RiskState): Observable<Observable<ISurveyData>> {
    const parameters = { state };
    return this.patch<Observable<ISurveyData>>({
      url: id,
      parameters
    });
  }

  public deleteRisk(id: string): Observable<void> {
    return this.delete({ url: id });
  }

  public getActiveRiskCount(): Observable<number> {
    return this.get<number>({ url: 'count' });
  }

  public exportRisk(id: string, fileType: ExportRiskFormat, locale: string): Observable<void> {
    return this.post({
      url: `${ id }/export`,
      parameters: { fileType, locale }
    });
  }
}

export const riskApiService = new RiskApiService(apiUrl);
