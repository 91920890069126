import { Observable } from 'rxjs';
import { Collection, CollectionParameters, TranslationInfo, User } from '@proliance-ai/typings';
import { ITableParameters, SortDirection } from '@interfaces';
import { IBasicScoreData } from '@services';

export type CompanyCourseListSortField = 'id' | 'title';

export interface ICompanyCourseListRequestParameters {
  sortField: CompanyCourseListSortField;
  sortDirection: SortDirection;
}

export type UserCourseListSortField = 'title' | 'courseStatus' | 'courseCompletedAt';
export interface IUserCourseListRequestParameters {
  page: number;
  pageSize?: number;
  sortField: UserCourseListSortField;
  sortDirection: string;
}

export interface IUserCourseRequestParameters extends IUserCourseListRequestParameters {
  companyCourseId: number;
}

export enum CourseStatus {
  OPENED = 'OPENED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
  RETIRED = 'RETIRED'
}

export type AcademyReportFileType = 'pdf' | 'xlsx';

export interface IUserCourse {
  id: number;
  courseId: number;
  user: User;
  courseCompletedAt: null | string;
  courseStatus: CourseStatus;
  userStatus: UserStatus;
  recurring: boolean;
  availableReopen: boolean;
}
export interface ICourse {
  id: number;
  titleTranslations: TranslationInfo;
  description: TranslationInfo;
  learningObjectives: TranslationInfo;
  targetAudience: null | string;
  durationMinutes: number;
  courseStatus: CourseStatus;
  courseCompletedAt: string;
  courseLinks: Record<string, string>;
  tag: string;
  mediaFileId: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  recurrentPeriodDays: number;
}

export interface ICompanyCourse {
  id: number;
  invitationToken?: string;
  course: ICourse;
  licenseCount: IBasicScoreData;
  userCourses: IUserCourse[];
  recurrent: boolean;
  recurrentPeriodDays: number;
}

export interface IMyCourseResult {
  id: number;
  courseStatus: CourseStatus;
  courseCompletedAt: null | string;
}
export interface IMyCourse {
  id: number;
  course: ICourse;
  courseLinks: TranslationInfo;
  currentResult: IMyCourseResult;
  completedResults: IMyCourseResult[];
  recurring: boolean;
}

export interface IRecurrenceData {
  recurrent: boolean;
  recurrentPeriodDays: number;
  closeRecurrentTasks?: null | boolean;
}

export interface IUpdateCourseRequest {
  recurrentPeriodDays: number;
  closeRecurrentTasks?: boolean;
}

export interface ICompanyCourseRequestParameters extends CollectionParameters {
  companyId: number;
}

export interface IReopenUsrCourseData {
  userId: number;
  companyCourseId: number;
}

export interface IAcademyService {
  companyCourseInitialState: ICompanyCourseListRequestParameters;
  userCoursesFilter: IUserCourseListRequestParameters;
  myCoursesInitialState: Partial<ITableParameters<IMyCourse>>;
  updateCourseRecurrence: (companyCourseId: number, parameters: IRecurrenceData) => Observable<IMyCourse>;
  getCertificateLink: (locale: string, userCourseId: number, resultId?: number) => string;
  getCourseReportLink: (locale: string, filetype?: AcademyReportFileType) => string;
  getCourseLinkDictionary: (idList: string[]) => Observable<Record<string, string>>;
  getCompanyCourse: (parameters?: ICompanyCourseListRequestParameters) => Observable<ICompanyCourse[]>;
  getUserCourse: (parameters: IUserCourseRequestParameters) => Observable<Collection<IUserCourse>>;
  getLicenceScore: (companyCourseId: number) => Observable<IBasicScoreData>;
  orderLicenses: (companyCourseId: number, licenses: number) => Observable<null>;
  sendReminder: (userCourseId: number) => Observable<null>;
  getUserCourseList: (parameters?: IUserCourseListRequestParameters) => Observable<Collection<IMyCourse>>;
  reopenUserCourse: (parameters: IReopenUsrCourseData) => Observable<IUserCourse>;
}
