import { State } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { modalService } from '@proliance-ai/react-ui';
import { modalService as oldModalService } from '@proliance-ai/design-system';

export const modalMiddleware = () => (
  _toState: State,
  _fromState: State,
  done: DoneFn
) => {
  if (modalService.isOpen.value) {
    modalService.hide();
  }
  if (oldModalService.isOpen.value) {
    oldModalService.hide();
  } // TODO: Remove this after all modals are migrated to the new DS
  return done();
};
