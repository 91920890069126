import { mergeObjects } from '@proliance-ai/design-system';
import { surveyCss } from '@shared/surveyJs/reexport';

const customSurveyCss = {
  html: {
    root: 'html-content'
  },
  navigation: {
    complete: 'sv_complete_btn p360-button primary',
    prev: 'sv_prev_btn p360-button default',
    next: 'sv_next_btn p360-button primary',
    start: 'sv_start_btn p360-button primary'
  },
  progressTextInBar: 'progressText',
  paneldynamic: {
    root: 'sv_panel_dynamic',
    button: 'p360-button primary',
    buttonAdd: 'panel-dynamic-add-btn p360-button small primary',
    buttonRemove: 'panel-dynamic-remove-btn p360-button small danger',
    buttonRemoveRight: 'sv-paneldynamic__remove-btn--right p360-button small danger',
    buttonPrev: 'sv-paneldynamic__prev-btn',
    buttonNext: 'sv-paneldynamic__next-btn',
    progress: 'sv-progress',
    progressBar: 'sv-progress__bar',
    progressContainer: 'sv-paneldynamic__progress-container',
    buttonPrevDisabled: 'sv-paneldynamic__prev-btn--disabled',
    buttonNextDisabled: 'sv-paneldynamic__next-btn--disabled',
    progressBtnIcon: 'icon-progressbutton'
  }
};

const current = surveyCss.currentType;
surveyCss[current] = mergeObjects(surveyCss[current], customSurveyCss);
