import { Namespace } from 'react-i18next';
import { Route } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { take } from 'rxjs/operators';
import { defaultTitleConfiguration, getObjectValue, pageTitleService, TitleConfiguration } from '@proliance-ai/design-system';
import { IRouteState } from '../interfaces';

export const pageTitleMiddleware = () => (
  toState: Route,
  _fromState: Route,
  done: DoneFn
): void => {
  const { error, params, data } = toState as IRouteState;
  if (error) {
    return done();
  }
  const { namespace, titleOptions } = data;
  const configuration: TitleConfiguration = { ...defaultTitleConfiguration, ...titleOptions };
  const parameters = {
    data: getObjectValue(data, configuration.path!, data),
    params
  };

  pageTitleService.setRoute({
    namespace: Array.isArray(namespace)
      ? namespace[0] as Namespace
      : namespace as Namespace,
    configuration,
    parameters
  });

  pageTitleService.title$
    .pipe(take(1))
    .subscribe((pageTitle: string) => {
      (toState as IRouteState).data.pageTitle = pageTitle;
      return done();
    });
};
