// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-HorizontalMenu_IUulq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-end;-ms-justify-content:flex-end;justify-content:flex-end;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu-HorizontalMenu_IUulq"
};
export default ___CSS_LOADER_EXPORT___;
