import { TranslationInfo, Locale } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { DataFormat } from 'select2';

const countryListMapper = (
  countryList: Array<IDictionaryResponse<TranslationInfo>>,
  locale: Locale
): DataFormat[] => countryList
  .map(
    (item: IDictionaryResponse<TranslationInfo>) => ({
      id: item.id,
      text: item.title[locale]
        ? `${ item.title[locale] } (${ item.id })`
        : ''
    })
  );

export default countryListMapper;
