// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".language-LanguageSwitcher_NaWdT{position:relative;z-index:11;width:64px;height:64px;line-height:16px;cursor:pointer;border-radius:3px;-webkit-transition:background-color .2s linear;transition:background-color .2s linear}.language-LanguageSwitcher_NaWdT:hover{background-color:rgba(0,0,0,0.05)}.language-LanguageSwitcher_NaWdT > div:first-child{height:100%}.language-LanguageSwitcher_NaWdT .row-LanguageSwitcher_LdHHe{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center}.language-LanguageSwitcher_NaWdT .glob-LanguageSwitcher_SdAU2{width:19px;height:19px}.language-LanguageSwitcher_NaWdT .box-LanguageSwitcher_M3pq7{position:relative;color:#38a7d7}.language-LanguageSwitcher_NaWdT .langCode-LanguageSwitcher_HOCal{width:19px;height:14px;text-transform:uppercase;position:absolute;border-radius:10px;background:#fff;text-align:center;bottom:-7px;left:-8px}.language-LanguageSwitcher_NaWdT .title-LanguageSwitcher_LuJ9U{position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;padding:2px 4px;font-size:9px;font-weight:400;letter-spacing:.7px;color:#1b143f;line-height:9px}.language-LanguageSwitcher_NaWdT .contextMenu-LanguageSwitcher_EbC_n{position:absolute;top:64px;right:0;z-index:1000}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language": "language-LanguageSwitcher_NaWdT",
	"row": "row-LanguageSwitcher_LdHHe",
	"glob": "glob-LanguageSwitcher_SdAU2",
	"box": "box-LanguageSwitcher_M3pq7",
	"langCode": "langCode-LanguageSwitcher_HOCal",
	"title": "title-LanguageSwitcher_LuJ9U",
	"contextMenu": "contextMenu-LanguageSwitcher_EbC_n"
};
export default ___CSS_LOADER_EXPORT___;
