import { Collection } from '@proliance-ai/typings';
import { ObservableInput } from 'rxjs/src/internal/types';
import { ThirdParty } from '@services';
import { thirdPartyMapper } from '.';

export const thirdPartyCollectionMapper = async (collection: Collection<ThirdParty>): ObservableInput<Collection<ThirdParty>> => {
  const { elements } = collection;
  const promiseList = elements
    .map((thirdParty: ThirdParty) => thirdPartyMapper(thirdParty));
  const updated = await Promise.all(promiseList);
  return {
    ...collection,
    elements: updated
  };
};
