// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-ErrorLayout_dOJRQ{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-height:100%;padding:64px 0 0;margin:0 16px}.language-ErrorLayout_FkFiT{position:absolute;top:0;left:0;width:100%;padding:0 1rem;box-sizing:border-box}.languageSwitcher-ErrorLayout_YweBa{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:flex-end;-ms-justify-content:flex-end;justify-content:flex-end;box-sizing:border-box}.children-ErrorLayout_fbQDQ{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1 0 auto;-ms-flex:1 0 auto;flex:1 0 auto;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;box-sizing:border-box;padding:1rem 0}@media all and (max-width:1025px){.children-ErrorLayout_fbQDQ{padding:1rem 1rem}}@media all and (max-width:560px){.children-ErrorLayout_fbQDQ{-webkit-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;padding:.5rem .5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-ErrorLayout_dOJRQ",
	"language": "language-ErrorLayout_FkFiT",
	"languageSwitcher": "languageSwitcher-ErrorLayout_YweBa",
	"children": "children-ErrorLayout_fbQDQ"
};
export default ___CSS_LOADER_EXPORT___;
