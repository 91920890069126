import { State } from 'router5';
import { notificationService } from '@proliance-ai/react-ui';

export const notificationParameterName = 'notification';

export const showNotificationMiddleware = () => (toState: State): Promise<boolean> => {
  const { params } = toState;
  const notification = params[notificationParameterName];
  if (notification) {
    const dataAttributesDictionary = {
      test: { notificationWarning: `notification-${ notification }` },
      guide: { notificationWarning: `notification-${ notification }` }
    };
    notificationService.warn({
      namespaces: 'common',
      textTranslationKey: `sse.${ notification }`,
      dataAttributesDictionary
    });
  }
  return Promise.resolve(true);
};
