import { State } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { IRouteState } from '../interfaces';
import { routerDependencies, routerMeta } from '@router';
import { i18n, permissionService } from '@services';
import { RouteListType } from '@router/configuration';
import { Namespace } from '@interfaces';
import { TitleOptions } from '@proliance-ai/design-system';

const getDictionaryData = <T>(
  dictionaryData: Record<string, T>,
  fallback: T
): T => {
  const data: undefined | T = (Object.keys(dictionaryData) as RouteListType[])
    .reduce(
      (result: undefined | T, route: RouteListType) => {
        if (typeof result !== 'undefined') {
          return result;
        }
        return permissionService.isPageAvailable(route)
          ? dictionaryData[route] as T
          : result;
      },
      undefined
    ) || fallback;
  if (typeof data === 'undefined') {
    return fallback;
  } else {
    return data;
  }
};

export const metadataMiddleware = () => (
  toState: State,
  _fromState: State,
  done: DoneFn
): void => {
  const { error } = toState as IRouteState;
  if (error) {
    return done();
  } else {
    const { dynamic } = routerDependencies[toState.name];
    const namespaceData = routerMeta[toState.name].namespace;
    const namespace: Namespace = dynamic
      ? getDictionaryData<Namespace>(namespaceData as Record<string, Namespace>, [])
      : namespaceData as Namespace;
    const titleOptionsData = routerMeta[toState.name].titleOptions;
    const titleOptions = dynamic
      ? getDictionaryData<TitleOptions>(titleOptionsData as Record<string, TitleOptions>, {})
      : titleOptionsData as TitleOptions;
    (toState as IRouteState).data = {
      ...(toState as IRouteState).data,
      ...routerMeta[toState.name],
      namespace,
      titleOptions
    };
    i18n.loadNamespaces(namespace as string | readonly string[])
      .then(() => done());
  }
};
