import { Observable } from 'rxjs';
import { CollectionParameters, TranslationInfo } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { Api } from '@services/api';
import { SystemsEntity } from '@services';
import { ICollection } from '@proliance-ai/design-system';

const apiUrl = '/api/survey/dictionary';

class DictionaryApiService extends Api {
  public apiRequest(
    url: string,
    parameters: Record<string, number | boolean | string> = {}
  ): Observable<ICollection<IDictionaryResponse<string>>> {
    return this.get<ICollection<IDictionaryResponse<string>>>({
      ignoreApiUrl: true,
      url,
      parameters
    });
  }

  public getApiData(url: string, parameters: CollectionParameters): Observable<ICollection<SystemsEntity>> {
    return this.get({
      url,
      parameters,
      ignoreApiUrl: true
    });
  }

  public getCustomDictionary(url: string): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({ url });
  }

  public get country(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'countries'
    });
  }
  public get dataBreachTypes(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'data-breach-types'
    });
  }
  public get industry(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'industries'
    });
  }
  public get legalForm(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'legal-forms'
    });
  }
  public get responsibilityTypes(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'responsibility-types'
    });
  }
  public get state(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'states'
    });
  }
  public get tag(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'tags'
    });
  }
  public get targetAudience(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'target-audience'
    });
  }
  public get userTags(): Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return this.get<Array<IDictionaryResponse<TranslationInfo>>>({
      url: 'user-tags'
    });
  }
}

export const dictionaryApiService = new DictionaryApiService(apiUrl);
