import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'react-router5';
import { of, Subject } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { FunctionalArea } from '@proliance-ai/typings';
import { Button, loadingIndicatorService, PageTitle } from '@proliance-ai/design-system';
import { notificationService } from '@proliance-ai/react-ui';
import { Case, snakeCaseToKebabCase } from '@proliance-ai/utilities';
import { RouteStateData, redirect } from '@router';
import { ISurveyData, streamService, surveyService } from '@services';
import { AppLayout } from '@layout';
import { useCancelSurvey } from '@hooks';
import { hasClass } from '@shared/utilities'; // TODO replace it
import { getDefaultFunctionalArea } from '@pages/Processes/utilities';
import ProcessSelection from './ProcessSelection';

interface IOwnProps {
  functionalArea: FunctionalArea;
  processSelectionData: ISurveyData;
}

type Props = RouteStateData & IOwnProps;

export const translationKeyMapping: Record<string, string> = {
  HR: 'process_selection_hr',
  PURCHASE: 'process_selection_purchase',
  SALES: 'process_selection_sales',
  FINANCE: 'process_selection_finance',
  IT: 'process_selection_it',
  SECURITY: 'process_selection_security',
  THIRD_PARTY_PROCESSING: 'process_selection_dpm'
};

const ProcessSelectionPage: FC<Props> = ({
  namespace,
  pageTitle,
  functionalArea,
  processSelectionData
}) => {
  const { t } = useTranslation(namespace);
  const { route } = useRoute();

  const [ survey, setSurvey ] = useState<ISurveyData>(processSelectionData);

  const updateSurvey = (): void => {
    loadingIndicatorService.show();
    surveyService
      .getSurveyByFunctionalArea({ functionalArea })
      .pipe(
        take(1),
        catchError((error: AjaxError) => of(error))
      )
      .subscribe((data: AjaxError | ISurveyData): void => {
        loadingIndicatorService.hide();
        if (data instanceof Error) {
          clearLeavePageHandler();
          return redirect(route.name, { ...route.params, error: data.status }, { reload: true, replace: true });
        }
        if (data.template.draft === survey.template.draft) {
          return;
        }
        setSurvey(data);
        const dataAttributesDictionary = {
          test: { notificationWarning: 'surveyModeUpdated' },
          guide: { notificationWarning: 'surveyModeUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.surveyModeUpdated',
          dataAttributesDictionary
        });
      });
  };

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  useEffect(() => {
    const subscription = streamService
      .draftChanged$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((): void => {
        updateSurvey();
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [ survey ]);

  const { template } = survey;
  const { draft, readonly: isReadonly } = template;
  const label = draft
    ? t('common:draft')
    : undefined;

  const redirectName = 'processes';
  const defaultFunctionalArea: FunctionalArea = getDefaultFunctionalArea();
  const redirectParams = functionalArea === defaultFunctionalArea
    ? {}
    : { routeTab: snakeCaseToKebabCase(functionalArea, Case.lower) };

  const useHandlerFn = () => {
    const activeElement = document.activeElement;
    if (!activeElement) {
      return true;
    }
    return !hasClass(activeElement, 'link-widget');
  };
  const {
    cancelCallback,
    clearLeavePageHandler
  } = useCancelSurvey({
    redirectName,
    redirectParams,
    isReadonly,
    useHandlerFn
  });

  return (
    <AppLayout>
      <div className="page-wrap">
        <PageTitle
          label={label}
          name={pageTitle}
        >
          <Button
            preset="default"
            text={t(isReadonly ? 'common:button.return' : 'common:button.cancel')}
            onClick={cancelCallback}
          />
        </PageTitle>
        <ProcessSelection
          functionalArea={functionalArea}
          survey={survey}
          clearLeavePageHandler={clearLeavePageHandler}
        />
      </div>
    </AppLayout>
  );
};

export default ProcessSelectionPage;
