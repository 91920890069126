import { constants } from 'router5';
import { setRoute } from '../utilities/setRoute';
import { routeList, RouteListType } from './routeList';
import { routeComponents as components } from './routeComponents';
import { routeResolver as resolver } from './routeResolvers';
import { getTitleKeyOptions } from '@proliance-ai/design-system';
import { translationKeyMapping } from '@pages/ProcessSelection/ProcessSelectionPage';

export const routes = [
  setRoute({
    name: 'documentError',
    component: components.DocumentError,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('404.pageTitle'),
    resolve: resolver.documentError
  }),
  setRoute({
    name: 'notAllowed',
    component: components.ErrorPage,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('403.pageTitle'),
    resolve: resolver.error(403)
  }),
  setRoute({
    name: constants.UNKNOWN_ROUTE as RouteListType,
    path: 'notFound',
    params: { path: routeList.notFound },
    component: components.ErrorPage,
    namespace: 'error',
    titleOptions: getTitleKeyOptions('404.pageTitle'),
    resolve: resolver.error()
  }),

  setRoute({
    name: 'default',
    lazy: false
  }),

  setRoute({
    name: 'dpoNomination',
    component: components.DpoNomination,
    resolve: {
      ...resolver.common,
      ...resolver.dpoNomination
    }
  }),
  setRoute({
    name: 'dpoPrivacyPolicy',
    component: components.DpoPrivacyPolicy,
    resolve: {
      ...resolver.common,
      ...resolver.dpoPrivacyPolicy
    }
  }),
  setRoute({
    name: 'dpoNotification',
    component: components.DpoNotification,
    resolve: {
      ...resolver.common,
      ...resolver.dpoNotification
    }
  }),

  setRoute({
    name: 'surveyTaskSystemAssignment',
    namespace: 'dashboard',
    component: components.SurveyTask,
    titleOptions: {
      path: 'surveyData',
      titleKey: 'surveyPageTitle',
      optionKey: 'template.title'
    },
    resolve: {
      ...resolver.common,
      ...resolver.surveySystemAssignment()
    }
  }),
  setRoute({
    name: 'customProcesses',
    component: components.CustomProcesses,
    titleOptions: {
      path: 'customProcessesData.result.result',
      isConditional: true,
      conditionalKey: 'name'
    },
    resolve: {
      ...resolver.common,
      ...resolver.customProcesses()
    }
  }),
  setRoute({
    name: 'customProcessesAdd',
    namespace: 'customProcesses',
    component: components.CustomProcesses,
    titleOptions: {
      path: 'customProcessesData.result.result',
      isConditional: true,
      conditionalKey: 'name'
    },
    resolve: {
      ...resolver.common,
      ...resolver.customProcesses(false)
    }
  }),
  setRoute({
    name: 'processSelection',
    namespace: 'dashboard',
    component: components.ProcessSelection,
    titleOptions: {
      titleKey: 'functionalArea',
      titlePrefix: 'task',
      map: translationKeyMapping
    },
    resolve: {
      ...resolver.common,
      ...resolver.processSelection
    }
  }),
  setRoute({
    name: 'surveyTask',
    namespace: 'dashboard',
    component: components.SurveyTask,
    titleOptions: {
      path: 'surveyData',
      titleKey: 'surveyPageTitle',
      optionKey: 'template.title'
    },
    resolve: {
      ...resolver.common,
      ...resolver.surveyTask()
    }
  }),
  setRoute({
    name: 'surveyTaskView',
    namespace: 'dashboard',
    component: components.SurveyTask,
    titleOptions: {
      path: 'surveyData',
      titleKey: 'surveyPageTitle',
      optionKey: 'template.title'
    },
    resolve: {
      ...resolver.common,
      ...resolver.surveyTask(true, true)
    }
  }),

  setRoute({
    name: 'dashboard',
    component: components.Dashboard,
    resolve: {
      ...resolver.common,
      ...resolver.dashboard
    }
  }),

  setRoute({
    name: 'dashboardTab',
    namespace: 'dashboard',
    component: components.Dashboard,
    resolve: {
      ...resolver.common,
      ...resolver.dashboard
    }
  }),

  setRoute({
    name: 'analytics',
    component: components.Analytics,
    resolve: {
      ...resolver.analytics
    }
  }),
  setRoute({
    name: 'analyticsTab',
    namespace: 'analytics',
    component: components.Analytics,
    resolve: {
      ...resolver.analytics
    }
  }),

  setRoute({
    name: 'processes',
    component: components.Processes,
    resolve: {
      ...resolver.processes
    }
  }),
  setRoute({
    name: 'processesTab',
    namespace: 'processes',
    component: components.Processes,
    resolve: {
      ...resolver.processes
    }
  }),

  setRoute({
    name: 'tom',
    component: components.Tom,
    resolve: {
      ...resolver.tom
    }
  }),

  setRoute({
    name: 'risk',
    namespace: {
      risk: 'risk',
      riskStatic: 'static'
    },
    titleOptions: {
      risk: {},
      riskStatic: { titleKey: 'risk.pageTitle' }
    },
    component: {
      risk: components.Risk,
      riskStatic: components.Static
    },
    dynamic: true,
    resolve: {
      risk: {
        ...resolver.risk
      },
      riskStatic: {
        ...resolver.staticPage(),
        ...resolver.staticPageInfo(false)
      }
    }
  }),
  setRoute({
    name: 'riskTab',
    namespace: 'risk',
    component: components.Risk,
    resolve: {
      ...resolver.risk
    }
  }),
  setRoute({
    name: 'riskAdd',
    namespace: 'riskSurvey',
    titleOptions: {
      titleKey: 'pageTitle.create'
    },
    component: components.RiskSurvey,
    resolve: {
      ...resolver.common,
      ...resolver.riskSurvey(false)
    }
  }),
  setRoute({
    name: 'riskEdit',
    namespace: 'riskSurvey',
    component: components.RiskSurvey,
    titleOptions: {
      titleKey: 'pageTitle.edit'
    },
    resolve: {
      ...resolver.common,
      ...resolver.riskSurvey()
    }
  }),
  setRoute({
    name: 'riskView',
    namespace: 'riskSurvey',
    component: components.RiskSurvey,
    titleOptions: {
      titleKey: 'pageTitle.edit'
    },
    resolve: {
      ...resolver.common,
      ...resolver.riskSurvey(true, true, true)
    }
  }),

  setRoute({
    name: 'document',
    component: components.Document,
    resolve: {
      ...resolver.common,
      ...resolver.document
    }
  }),
  setRoute({
    name: 'documentTab',
    namespace: 'document',
    component: components.Document,
    resolve: {
      ...resolver.common,
      ...resolver.document
    }
  }),

  setRoute({
    name: 'websites',
    namespace: 'static',
    titleOptions: { titleKey: 'websites.pageTitle' },
    component: components.Static,
    resolve: {
      ...resolver.dpo,
      ...resolver.staticPage()
    }
  }),

  setRoute({
    name: 'dataSubjectRequests',
    namespace: 'static',
    titleOptions: { titleKey: 'dataSubjectRequests.pageTitle' },
    component: components.Static,
    resolve: {
      ...resolver.dpo,
      ...resolver.staticPage()
    }
  }),

  setRoute({
    name: 'dataBreach',
    namespace: {
      dataBreach: 'dataBreach',
      dataBreachStaticDefault: 'static',
      dataBreachStaticCustom: 'static'
    },
    titleOptions: {
      dataBreach: {},
      dataBreachStaticDefault: { titleKey: 'dataBreach.default.pageTitle' },
      dataBreachStaticCustom: { titleKey: 'dataBreach.custom.pageTitle' }
    },
    component: {
      dataBreach: components.DataBreach,
      dataBreachStaticDefault: components.Static,
      dataBreachStaticCustom: components.Static
    },
    dynamic: true,
    resolve: {
      dataBreach: {
        ...resolver.dataBreach
      },
      dataBreachStaticDefault: {
        ...resolver.dpo,
        ...resolver.staticPage('dataBreachStaticDefault')
      },
      dataBreachStaticCustom: {
        ...resolver.dpo,
        ...resolver.staticPage('dataBreachStaticCustom')
      }
    }
  }),
  setRoute({
    name: 'dataBreachTab',
    namespace: 'dataBreach',
    component: components.DataBreach,
    resolve: {
      ...resolver.dataBreach
    }
  }),
  setRoute({
    name: 'dataBreachAdd',
    namespace: 'dataBreachSurvey',
    titleOptions: { titleKey: 'pageTitle.create' },
    component: components.DataBreachSurvey,
    resolve: {
      ...resolver.common,
      ...resolver.dataBreachSurvey(false)
    }
  }),
  setRoute({
    name: 'dataBreachEdit',
    namespace: 'dataBreachSurvey',
    component: components.DataBreachSurvey,
    titleOptions: { titleKey: 'pageTitle.edit' },
    resolve: {
      ...resolver.common,
      ...resolver.dataBreachSurvey()
    }
  }),
  setRoute({
    name: 'dataBreachView',
    namespace: 'dataBreachSurvey',
    component: components.DataBreachSurvey,
    titleOptions: { titleKey: 'pageTitle.edit' },
    resolve: {
      ...resolver.common,
      ...resolver.dataBreachSurvey(true, true, true)
    }
  }),

  setRoute({
    name: 'courseManagement',
    namespace: [ 'courseManagement', 'course' ],
    component: components.CourseManagement,
    resolve: {
      ...resolver.courseManagement
    }
  }),
  setRoute({
    name: 'myCourses',
    namespace: [ 'myCourses', 'course' ],
    component: components.MyCourses,
    resolve: {
      ...resolver.myCourses
    }
  }),

  setRoute({
    name: 'team',
    component: components.Team,
    resolve: {
      ...resolver.team
    }
  }),
  setRoute({
    name: 'teamDetails',
    component: components.TeamDetails,
    resolve: {
      ...resolver.teamDetails
    }
  }),

  setRoute({
    name: 'systems',
    component: components.Systems,
    resolve: {
      ...resolver.common,
      ...resolver.systems
    }
  }),
  setRoute({
    name: 'systemsList',
    component: components.SystemsList,
    resolve: {
      ...resolver.common,
      ...resolver.systemsList
    }
  }),
  setRoute({
    name: 'systemsAdd',
    namespace: 'systemsDetails',
    component: components.SystemsDetails,
    titleOptions: { titleKey: 'pageTitle.create' },
    resolve: {
      ...resolver.common,
      ...resolver.systemsAdd
    }
  }),
  setRoute({
    name: 'systemsDetails',
    component: components.SystemsDetails,
    titleOptions: { titleKey: 'pageTitle.edit' },
    resolve: {
      ...resolver.common,
      ...resolver.systemsDetails
    }
  }),
  setRoute({
    name: 'assetManagement',
    component: components.AssetManagement,
    resolve: {
      ...resolver.assetManagement
    }
  }),
  setRoute({
    name: 'assetManagementTab',
    namespace: 'assetManagement',
    component: components.AssetManagement,
    resolve: {
      ...resolver.assetManagementTab
    }
  })
];
