// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".activity-ActivitiesWidget_dG8Qj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-align-items:stretch;align-items:stretch;padding:18px 0;margin:0 24px;position:relative;border-bottom:thin solid #e0e0e0}.activity-ActivitiesWidget_dG8Qj:last-of-type{border:0 none}.activityData-ActivitiesWidget_hPfif{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-align-items:stretch;align-items:stretch;margin-bottom:10px}.activityUser-ActivitiesWidget_mffPQ{font-size:12px;line-height:15px;font-weight:500;color:#1b143f}.onBehalf-ActivitiesWidget_CGcQl{display:block}.activityDate-ActivitiesWidget_jByAY{margin-left:9px;font-size:12px;line-height:15px;font-weight:400;color:#828282}.activityName-ActivitiesWidget_JfV4l{font-size:14px;line-height:17px;font-weight:400;color:#4f4f4f}.demoMessage-ActivitiesWidget_NwWCO{color:#4f4f4f;margin:auto;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activity": "activity-ActivitiesWidget_dG8Qj",
	"activityData": "activityData-ActivitiesWidget_hPfif",
	"activityUser": "activityUser-ActivitiesWidget_mffPQ",
	"onBehalf": "onBehalf-ActivitiesWidget_CGcQl",
	"activityDate": "activityDate-ActivitiesWidget_jByAY",
	"activityName": "activityName-ActivitiesWidget_JfV4l",
	"demoMessage": "demoMessage-ActivitiesWidget_NwWCO"
};
export default ___CSS_LOADER_EXPORT___;
