import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SVG } from '@proliance-ai/design-system';
import { getStaticBrowserIconResourceUrl, getStaticIconResourceUrl } from '@proliance-ai/utilities';

import styles from './UpgradeBrowser.styl';

const attention = getStaticIconResourceUrl({ name: 'attention.svg' });
const chrome = getStaticBrowserIconResourceUrl({ name: 'chrome.png' });
const firefox = getStaticBrowserIconResourceUrl({ name: 'firefox.png' });
const edge = getStaticBrowserIconResourceUrl({ name: 'edge.png' });

const UpgradeBrowser: FC = (): ReactElement => {
  const { t } = useTranslation('upgradeBrowser');
  return (
    <div className={styles.notification}>
      <SVG src={attention} className={styles.attention} />
      <div className="styles.textBlock">
        <p className={styles.heading}>{t('title')}</p>
        <p className={styles.text} dangerouslySetInnerHTML={{ __html: t('text') }} />
        <p className={styles.text}>{t('hint')}</p>
        <div className={styles.browser}>
          <div>
            <a href={t('links.chrome')}>
              <img src={chrome} alt="chrome" />
              <span>Google Chrome</span>
            </a>
          </div>
          <div>
            <a href={t('links.firefox')}>
              <img src={firefox} alt="firefox" />
              <span>Mozilla Firefox</span>
            </a>
          </div>
          <div>
            <a href={t('links.edge')}>
              <img src={edge} alt="edge" />
              <span> Microsoft Edge</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeBrowser;
