import { State } from 'router5';
import { IRouteState } from '../interfaces';
import { RouteListType } from '@router/configuration';
import { Company, companyService, productService } from '@services';
import { notificationService } from '@proliance-ai/react-ui';
import { getPath, redirectParameterName, removeStorageRedirectData } from '../';

export const companyIdParameterName = 'companyId';
export const companyIdStorageName = 'companyId';

export const openModalParameterName = 'openModal';

export const companyMiddleware = () => (toState: State, fromState: State): Promise<boolean> => {
  const companyIdParameter = toState.params[companyIdParameterName];
  const companyIdStored = sessionStorage.getItem(companyIdStorageName);
  const companyId = companyIdParameter || companyIdStored;

  return new Promise((resolve: (value: boolean) => void): void => {
    if (!companyId) {
      return resolve(true);
    }
    const { isLoggedIn, isRouted } = toState as IRouteState;
    if (!isLoggedIn) {
      sessionStorage.setItem(companyIdStorageName, companyId);
      return resolve(true);
    } else {
      if (companyService.company$.value?.id === +companyId) {
        sessionStorage.removeItem(companyIdStorageName);
        return resolve(true);
      }
      companyService
        .selectCurrentCompany(companyId)
        .subscribe((company: null | Company) => {
          if (company === null || company?.id !== +companyId) {
            const dataAttributesDictionary = {
              test: { notificationError: 'notInCompany' },
              guide: { notificationError: 'notInCompany' }
            };
            notificationService.error({
              textTranslationKey: 'notInCompany',
              dataAttributesDictionary
            });
          }
          sessionStorage.removeItem(companyIdStorageName);
          productService.setCurrentProductByRouteName(toState.name as RouteListType);
          if (isRouted) {
            removeStorageRedirectData(getPath(fromState.path));
          } else {
            removeStorageRedirectData(getPath(toState.path));
          }
          (toState as IRouteState)[redirectParameterName] = true;
          toState.meta!.params[openModalParameterName] = 'productSelection';
          return resolve(true);
        });
    }
  });
};
