import i18n from 'i18next';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { sidebarService } from '@proliance-ai/react-ui';
import {
  Asset,
  Collection,
  Locale,
  SortItem,
  ThirdParty,
  TranslationInfo
} from '@proliance-ai/typings';
import { IDictionaryResponse } from '@proliance-ai/design-system';
import { dictionaryService } from '@services';
import { assetManagementApiService, commentApiService } from '@services/api';
import {
  AssetManagementService,
  AssetManagementSidebarData,
  GetCountryTitleOptions
} from './assetManagement.typings';
import { thirdPartyCollectionMapper } from './mappers';
import { getIcon, getNextStep, getPriority, getPriorityPreset } from './utilities';

export const assetManagementInitialState: SortItem<Asset>[] = [ {
  sortField: 'name',
  sortDirection: 'asc'
} ];

const sidebar = new BehaviorSubject<undefined | AssetManagementSidebarData>(undefined);
const sidebar$ = sidebar.asObservable();

const showNext = new BehaviorSubject<undefined | boolean>(undefined);

const openSidebar = (data: AssetManagementSidebarData): void => {
  if (sidebar.value?.name === data.name && sidebar.value?.asset.id === data.asset.id) {
    return;
  }
  showNext.next(undefined);
  sidebar.next(data);
  const { id } = sidebarService.sidebarOptions.value;
  updateSidebar(id, data); // TODO fix documents data handling
};

const updateSidebar = (id: string, data: AssetManagementSidebarData): void => {
  if (typeof sidebar.value !== 'undefined') {
    sidebarService.updateContentProperties(id, { ...sidebar.value, ...data });
  }
};

const closeSidebar = (): void => {
  if (typeof sidebar.value !== 'undefined') {
    sidebar.next(undefined);
  }
};
sidebarService.isOpen$
  .subscribe((isOpen: boolean): void => {
    if (!isOpen && !!sidebar.value) {
      closeSidebar();
    }
  });

let dictionaryCache: Array<IDictionaryResponse<TranslationInfo>>;
const getCountryTitleFromDictionaryData = ({
  country,
  locale,
  dictionaryData
}: GetCountryTitleOptions): string => dictionaryData
  .find((item: IDictionaryResponse<TranslationInfo>): boolean => item.id === country)?.title[locale] || '';

const getCountryTitle = (country: string): Promise<string> => new Promise((resolve) => {
  if (!dictionaryCache) {
    dictionaryService
      .getData('country')
      .pipe(take(1))
      .subscribe((dictionaryData: Array<IDictionaryResponse<TranslationInfo>>) => {
        dictionaryCache = dictionaryData;
        resolve(getCountryTitleFromDictionaryData({ country, locale: i18n.language as Locale, dictionaryData }));
      });
  } else {
    resolve(getCountryTitleFromDictionaryData({
      country,
      locale: i18n.language as Locale,
      dictionaryData: dictionaryCache
    }));
  }
});

const getThirdPartyCollection = (parameters?: Record<string, any>): Observable<Collection<ThirdParty>> => assetManagementApiService
  .getThirdPartyCollection(parameters)
  .pipe(mergeMap(thirdPartyCollectionMapper));

export const assetManagementService: AssetManagementService = {
  sidebar,
  sidebar$,
  openSidebar,
  updateSidebar,
  closeSidebar,

  showNext,

  getCountryTitle,

  getIcon,
  getNextStep,

  getPriority,
  getPriorityPreset,

  deleteFile: assetManagementApiService.deleteFile.bind(assetManagementApiService),

  getThirdPartyCollection,
  getThirdParty: assetManagementApiService.getThirdParty.bind(assetManagementApiService),
  createThirdParty: assetManagementApiService.createThirdParty.bind(assetManagementApiService),
  updateThirdParty: assetManagementApiService.updateThirdParty.bind(assetManagementApiService),
  getInstrumentDataList: assetManagementApiService.getInstrumentDataList.bind(assetManagementApiService),

  getSoftwareCollection: assetManagementApiService.getSoftwareCollection.bind(assetManagementApiService),
  getSoftware: assetManagementApiService.getSoftware.bind(assetManagementApiService),
  createSoftware: assetManagementApiService.createSoftware.bind(assetManagementApiService),
  updateSoftware: assetManagementApiService.updateSoftware.bind(assetManagementApiService),

  getDataStorageCollection: assetManagementApiService.getDataStorageCollection.bind(assetManagementApiService),
  getDataStorage: assetManagementApiService.getDataStorage.bind(assetManagementApiService),
  createDataStorage: assetManagementApiService.createDataStorage.bind(assetManagementApiService),
  updateDataStorage: assetManagementApiService.updateDataStorage.bind(assetManagementApiService),

  getCommentCollection: commentApiService.getCommentCollection.bind(commentApiService),
  getComment: commentApiService.getComment.bind(commentApiService),
  createComment: commentApiService.createComment.bind(commentApiService),
  updateComment: commentApiService.updateComment.bind(commentApiService),
  deleteComment: commentApiService.deleteComment.bind(commentApiService)
};
