// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-ExportModal_QjK7u .text-ExportModal_qwD9E{font-size:14px;line-height:21px;margin:0}.modal-ExportModal_QjK7u .note-ExportModal_cnCtP{margin-bottom:2rem;color:#828282;font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal-ExportModal_QjK7u",
	"text": "text-ExportModal_qwD9E",
	"note": "note-ExportModal_cnCtP"
};
export default ___CSS_LOADER_EXPORT___;
