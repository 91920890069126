import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { ContextMenuItem } from '@proliance-ai/typings';
import { classNameArrayToString, configurationService } from '@proliance-ai/design-system';
import { ContextMenu } from '@proliance-ai/react-ui';

import styles from './LanguageSwitcher.styl';

export const LanguageSwitcher: FC = () => {
  const { t, i18n } = useTranslation();
  const delimiter = '-';
  const [ langCode, setLangCode ] = useState('');
  const [ menuItems, setMenuItems ] = useState<ContextMenuItem[]>([]);
  const [ showMenu, setShowMenu ] = useState(false);

  const codeFlagMap: Record<string, string> = {
    en: 'gb',
    de: 'de'
  };

  useEffect(() => {
    let code = i18n.language;
    if (code && code.indexOf(delimiter) !== -1) {
      code = code.split(delimiter)[0];
    }
    setLangCode(code);
    const languageList = configurationService.getLanguageList();
    const translatedLangList = languageList
      .map((language: string) => ({
        id: language,
        content: (
          <>
            <span className={`country-flag flag-icon flag-icon-${ codeFlagMap[language] }`} />
            {t(`language.${ language }`)}
          </>
        ),
        action: () => changeLanguage(language)
      })
      );
    setMenuItems(translatedLangList);
  }, [ t, i18n.language ]);

  const changeLanguage = (languageCode?: string): void => {
    if (languageCode) {
      i18n.changeLanguage(languageCode);
    }
  };

  const toggleMenu = showMenu
    ? (
      <ContextMenu
        className={styles.contextMenu}
        itemList={menuItems}
        active={langCode}
      />
    )
    : null;

  const classNameString = classNameArrayToString([
    styles.language,
    'no-select'
  ]);

  return (
    <div
      className={classNameString}
      onMouseLeave={() => setShowMenu(false)}
      onClick={() => setShowMenu(!showMenu)}
    >
      <div className={styles.row}>
        <div className={styles.box}>
          <FontAwesomeIcon
            className={styles.glob}
            icon={faGlobe}
          />
          <div className={styles.langCode}>
            <div className={styles.title}>
              {langCode}
            </div>
          </div>
        </div>
      </div>
      {toggleMenu}
    </div>
  );
}; // TODO refactor it!
