import { BehaviorSubject, Observable } from 'rxjs';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  Asset,
  AssetFormKey,
  AssetManagementFetchData,
  AssetType,
  AssetTypeKey,
  Collection,
  CommentData,
  CommentRequestParameters,
  DataStorage,
  DataStorageData,
  DataStorageFormKey,
  DataStorageKey,
  IDictionaryResponse,
  InstrumentData,
  Locale,
  Priority,
  Software,
  SoftwareData,
  SoftwareFormKey,
  SoftwareKey,
  ThirdParty,
  ThirdPartyData,
  ThirdPartyFormKey,
  ThirdPartyKey,
  TranslationInfo
} from '@proliance-ai/typings';
import { ChipPreset } from '@proliance-ai/react-ui';

export enum AssetManagementTabsKey {
  SOFTWARE = 'SOFTWARE',
  THIRD_PARTIES = 'THIRD_PARTIES',
  DATA_STORAGES = 'DATA_STORAGES'
}

export enum ManagementSidebarTabsKey {
  ADMINISTRATION = 'ADMINISTRATION',
  COMMENTS = 'COMMENTS',
  DPO_REVIEW = 'DPO_REVIEW',
  PROTOCOL = 'PROTOCOL'
}

// export enum FileType {
//   PDF = 'application/pdf',
//   DOC = 'application/msword',
//   DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   XLS = 'application/vnd.ms-excel',
//   XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   CSV = 'text/csv',
//   OTHER = 'application/octet-stream'
// }

export type GetPriority = {
  (type: 'DataStorage', key: DataStorageKey): Priority;
  (type: 'Software', key: SoftwareKey): Priority;
  (type: 'ThirdParty', key: ThirdPartyKey): Priority;
};
export type GetNextStep = {
  (type: 'DataStorage', currentStep: DataStorageFormKey): null | DataStorageFormKey;
  (type: 'Software', currentStep: SoftwareFormKey): null | SoftwareFormKey;
  (type: 'ThirdParty', currentStep: ThirdPartyFormKey): null | ThirdPartyFormKey;
};

export type AssetManagementSidebarData = {
  name: AssetFormKey;
  asset: AssetType;
};

export type FetchAssetManagementData<T extends Asset> = (parameters?: AssetManagementFetchData<T>) => Promise<null | Collection<T>>;

export interface GetCountryTitleOptions {
  country: string;
  locale: Locale;
  dictionaryData: Array<IDictionaryResponse<TranslationInfo>>;
}

export interface AssetManagementService {
  sidebar: BehaviorSubject<undefined | AssetManagementSidebarData>;
  sidebar$: Observable<undefined | AssetManagementSidebarData>;
  openSidebar: (data: AssetManagementSidebarData) => void;
  updateSidebar: (id: string, data: AssetManagementSidebarData) => void;
  closeSidebar: () => void;

  showNext: BehaviorSubject<undefined | boolean>;

  getCountryTitle: (country: string) => Promise<string>;

  getIcon: (type: AssetTypeKey) => IconDefinition;
  getNextStep: GetNextStep;
  getPriority: GetPriority;
  getPriorityPreset: (priority: Priority) => ChipPreset;

  deleteFile: (id: string, documentId: string) => Observable<null>;

  getThirdPartyCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdParty>>;
  getThirdParty: (id: string) => Observable<ThirdParty>;
  createThirdParty: (body: ThirdPartyData) => Observable<ThirdParty>;
  updateThirdParty: (id: string, body: Partial<ThirdParty>) => Observable<ThirdParty>;
  getInstrumentDataList: (country: string) => Observable<InstrumentData[]>;

  getSoftwareCollection: (parameters?: Record<string, any>) => Observable<Collection<Software>>;
  getSoftware: (id: string) => Observable<Software>;
  createSoftware: (body: SoftwareData) => Observable<Software>;
  updateSoftware: (id: string, body: Partial<Software>) => Observable<Software>;

  getDataStorageCollection: (parameters?: Record<string, any>) => Observable<Collection<DataStorage>>;
  getDataStorage: (id: string) => Observable<DataStorage>;
  createDataStorage: (body: DataStorageData) => Observable<DataStorage>;
  updateDataStorage: (id: string, body: Partial<DataStorage>) => Observable<DataStorage>;

  getCommentCollection: (parameters: CommentRequestParameters) => Observable<Collection<Comment>>;
  getComment: (commentId: string) => Observable<Comment>;
  createComment: (commentData: CommentData) => Observable<Comment>;
  updateComment: (commentData: Comment) => Observable<Comment>;
  deleteComment: (commentId: string) => Observable<null>;
}
