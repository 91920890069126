import { DataFormat } from 'select2';
import { addClass, removeClass, clone } from '@proliance-ai/design-system';
import { IQuestionDropdown } from '@shared/surveyJs/widgets/Dropdown/dropdown.widget';
import { IProperty, IPropertyChangeOption, WidgetEditorLocalization } from '../interfaces';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { Base, ItemValue, QuestionCheckboxModel, Serializer, Survey, SurveyModel, Question } from '@shared/surveyJs/reexport';

import { faShareAlt } from '@fortawesome/pro-regular-svg-icons';

import './styles.styl';

export interface IOnValueChangedOptions {
  name: string;
  question: Question;
  value: any;
}
export type IOnValueChangedHandler = (sender: SurveyModel, options: IOnValueChangedOptions) => void;

export interface IOnPropertyChangedOptions {
  name: string;
  oldValue: any;
  newValue: any;
}
export type IOnPropertyChangedHandler = (sender: Base, options: IOnPropertyChangedOptions) => void;

export interface IOnLoadChoicesFromServerOptions {
  question: Question;
  choices: ItemValue[];
  serverResult: any;
}
export type IOnLoadChoicesFromServerHandler = (sender: Base, options: IOnLoadChoicesFromServerOptions) => void;

export interface IHandlerDictionary {
  valueChanged: null | IOnValueChangedHandler;
  propertyChanged: null | IOnPropertyChangedHandler;
  loadChoices: null | IOnLoadChoicesFromServerHandler;
}

export interface IQuestionAdapter extends Question {
  source: string;
  valuePropertyName: string;
  titlePropertyName: string;
  value: object[];
  debug: string;
}

export default class AdapterWidget extends WidgetAbstractClass<IQuestionAdapter> {
  public hidePropertyNameList: string[] = [
    // General
    'title',
    'description',
    'visible',
    'isRequired',
    'readOnly',
    // Attributes
    'autocompleteAttr',
    'testAttr',
    'nameAttr',
    // Logic
    'visibleIf',
    'enableIf',
    'requiredIf',
    'bindings',
    'defaultValueExpression',
    // Layout
    'page',
    'startWithNewLine',
    'hideNumber',
    'titleLocation',
    'descriptionLocation',
    'indent',
    'width',
    'minWidth',
    'maxWidth',
    'state',
    // Data
    'defaultValue',
    'correctAnswer',
    'useDisplayValuesInTitle',
    // Validation
    'requiredErrorText',
    'validators'
  ];
  public customPropertiesList: IProperty[] = [
    {
      type: 'supportedquestion',
      name: 'source',
      isLocalizable: false,
      category: 'general',
      visibleIndex: 1,
      isRequired: true
    },
    {
      type: 'string',
      name: 'valuePropertyName',
      isLocalizable: false,
      category: 'general',
      visibleIndex: 2,
      default: 'name',
      isRequired: true
    },
    {
      type: 'string',
      name: 'titlePropertyName',
      isLocalizable: false,
      category: 'general',
      visibleIndex: 3,
      default: 'title',
      isRequired: true
    },
    {
      type: 'switch',
      name: 'debug',
      isLocalizable: false,
      category: 'general',
      visibleIndex: 4,
      default: false
    }
  ];

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Adapter',
        de: 'Adapter'
      }
    },
    p: {
      source: {
        en: 'Source',
        de: 'Quelle'
      },
      valuePropertyName: {
        en: 'Value property name',
        de: 'Wert Eigenschaftsname '
      },
      titlePropertyName: {
        en: 'Title property name',
        de: 'Name der Titeleigenschaft'
      },
      debug: {
        en: 'Debug',
        de: 'Debuggen'
      }
    }
  };

  protected classNameDictionary: Record<string, string> = {
    adapter: 'adapter-widget',
    hidden: 'adapter-hidden',
    debug: 'adapter-debug'
  };

  private defaultHandlerDictionary: IHandlerDictionary = {
    valueChanged: null,
    propertyChanged: null,
    loadChoices: null
  };

  private handlerDictionary: Record<string, IHandlerDictionary> = {};

  constructor() {
    super({
      name: 'adapter',
      iconName: 'adapter',
      icon: faShareAlt,
      htmlTemplate: '<pre/>'
    });
  }

  public afterRender(question: IQuestionAdapter, element: HTMLElement): void {
    super.afterRender(question, element);

    addClass(element, this.classNameDictionary.adapter);

    if (typeof this.handlerDictionary[question.name] === 'undefined') {
      this.handlerDictionary[question.name] = { ...this.defaultHandlerDictionary };
    }
    const survey = question.survey as SurveyModel;

    if (survey.onValueChanged.hasFunc(this.handlerDictionary[question.name].valueChanged!)) {
      survey.onValueChanged.remove(this.handlerDictionary[question.name].valueChanged!);
    }
    this.handlerDictionary[question.name].valueChanged = this.onValueChangedHandler(question, element);
    survey.onValueChanged.add(this.handlerDictionary[question.name].valueChanged!);

    if (survey.onPropertyChanged.hasFunc(this.handlerDictionary[question.name].propertyChanged!)) {
      survey.onPropertyChanged.remove(this.handlerDictionary[question.name].propertyChanged!);
    }
    this.handlerDictionary[question.name].propertyChanged = this.onSurveyPropertyChangedHandler(question, element);
    survey.onPropertyChanged.add(this.handlerDictionary[question.name].propertyChanged!);

    if (survey.onLoadChoicesFromServer.hasFunc(this.handlerDictionary[question.name].loadChoices!)) {
      survey.onLoadChoicesFromServer.remove(this.handlerDictionary[question.name].loadChoices!);
    }
    this.handlerDictionary[question.name].loadChoices = this.onLoadChoicesFromServerHandler(question, element);
    survey.onLoadChoicesFromServer.add(this.handlerDictionary[question.name].loadChoices!);

    this.setContent(question, element);
  }

  public renderer(question: IQuestionAdapter, element: HTMLElement): void {
    if (typeof question.value === 'undefined') {
      const surveyData = clone((question.survey as Survey).data);
      if (surveyData[question.valueName]) {
        question.value = surveyData[question.valueName];
      } else if (surveyData[question.name]) {
        question.value = surveyData[question.name];
      } else {
        question.value = [];
        this.setQuestionValue(question, element, question.survey.getQuestionByName(question.source) as Question);
      }
    }
    const sourceQuestion = question.survey.getQuestionByName(question.source) as Question;
    this.setQuestionValue(question, element, sourceQuestion);
    question.titleLocation = 'hidden';
    this.updateProperties();
  }

  protected onPropertyChangedHandler(
    question: IQuestionAdapter,
    element: HTMLElement,
    options: IPropertyChangeOption<any>): void {
    if (options.name === 'debug') {
      this.setContent(question, element);
    }
  }

  private updateProperties(): void {
    const valueNameProperty = Serializer.findProperty('adapter', 'valueName');
    if (valueNameProperty) {
      valueNameProperty.category = 'general';
    }
  }

  private onValueChangedHandler(question: IQuestionAdapter, element: HTMLElement): IOnValueChangedHandler {
    const { source } = question;
    return (_sender: SurveyModel, options: IOnValueChangedOptions): void => {
      const { name, value, question: sourceQuestion } = options;
      if (name === source) {
        this.setQuestionValue(question, element, sourceQuestion, value);
      }
      if (name === question.valueName) {
        this.setContent(question, element);
      }
    };
  }

  private onSurveyPropertyChangedHandler(question: IQuestionAdapter, element: HTMLElement): IOnPropertyChangedHandler {
    return (_sender: Base, options: IOnPropertyChangedOptions): void => {
      if (options.name === 'locale') {
        setTimeout(() => this.updateLocalization(question, element));
      }
    };
  }

  private onLoadChoicesFromServerHandler(
    question: IQuestionAdapter,
    element: HTMLElement): IOnLoadChoicesFromServerHandler {
    return (_sender: Base, options: IOnLoadChoicesFromServerOptions) => {
      if (options.question.name === question.source) {
        setTimeout(() => this.updateLocalization(question, element));
      }
    };
  }

  private getOptionTitle(source: Question, value: string): string {
    if (!!(source as IQuestionDropdown)?.settings?.data?.length) {
      const choice = ((source as IQuestionDropdown).settings!.data as DataFormat[])
        .find((item: DataFormat) => item.id === value);
      if (choice) {
        return choice.text;
      }
    } else if ((source as QuestionCheckboxModel).choices) {
      const choice = {
        ...(source as QuestionCheckboxModel).choices
          .find((itemValue: ItemValue) => itemValue.value === value)
      };
      if (Object.keys(choice).length) {
        return choice.locTextValue.renderedText;
      }
    }
    return '';
  }

  private updateLocalization(question: IQuestionAdapter, element: HTMLElement): void {
    const { valuePropertyName, titlePropertyName } = question;
    const sourceQuestion = question.survey.getQuestionByName(question.source) as Question;
    question.value = question.value
      .map(
        (valueItem: any) => ({
          ...valueItem,
          [titlePropertyName]: this.getOptionTitle(sourceQuestion, valueItem[valuePropertyName])
        })
      );
    this.setContent(question, element);
  }

  private setQuestionValue(question: IQuestionAdapter, element: HTMLElement, source: Question, value?: any): void {
    const { valuePropertyName, titlePropertyName } = question;
    const sourceValue = typeof value === 'undefined'
      ? source
        ? source.value
        : null
      : value;
    if (sourceValue === null) {
      question.value = [];
    } else {
      if (sourceValue[0] === 'none') {
        question.value = [];
      } else {
        const newValue = sourceValue.reduce(
          (result: object[], valueItem: any) => {
            const currentItemValue = (clone(question.value) || [])
              .find((item: any) => valueItem === item[valuePropertyName]);
            if (currentItemValue) {
              result.push(currentItemValue);
            } else {
              result.push({
                [valuePropertyName]: valueItem,
                [titlePropertyName]: this.getOptionTitle(source, valueItem)
              });
            }
            return result;
          },
          []
        );
        question.value = clone(newValue);
      }
    }
    this.setContent(question, element);
  }

  private setContent(question: IQuestionAdapter, element: HTMLElement): void {
    const { debug, value } = question;
    if (debug) {
      removeClass(element, this.classNameDictionary.hidden);
      addClass(element, this.classNameDictionary.debug);
      element.innerHTML = `<pre>${ JSON.stringify(value, null, 4) }</pre>`;
    } else {
      removeClass(element, this.classNameDictionary.debug);
      addClass(element, this.classNameDictionary.hidden);
      if (element.innerHTML !== '') {
        element.innerHTML = '';
      }
    }
  }
}
