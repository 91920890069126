// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menuItem-HorizontalMenuItem_vVLRG{border-radius:3px;color:#828282;font-weight:500;font-size:16px;line-height:22px;cursor:pointer;-webkit-transition:color .2s linear,opacity .2s linear;transition:color .2s linear,opacity .2s linear}.menuItem-HorizontalMenuItem_vVLRG:hover{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)\";filter:alpha(opacity=70);opacity:.7}.menuItem-HorizontalMenuItem_vVLRG.active-HorizontalMenuItem_dvEHe{-ms-filter:none;filter:none;opacity:1}.menuItem-HorizontalMenuItem_vVLRG.active-HorizontalMenuItem_dvEHe .link-HorizontalMenuItem_YtU1Z{cursor:default}.menuItem-HorizontalMenuItem_vVLRG.active-HorizontalMenuItem_dvEHe,.menuItem-HorizontalMenuItem_vVLRG:hover{color:#1b143f}.menuItem-HorizontalMenuItem_vVLRG.disabled-HorizontalMenuItem_o_1Xl{cursor:not-allowed;background-color:#f5f5f5}.menuItem-HorizontalMenuItem_vVLRG.disabled-HorizontalMenuItem_o_1Xl.active-HorizontalMenuItem_dvEHe,.menuItem-HorizontalMenuItem_vVLRG.disabled-HorizontalMenuItem_o_1Xl:hover{color:#828282;-ms-filter:none;filter:none;opacity:1}.menuItem-HorizontalMenuItem_vVLRG.disabled-HorizontalMenuItem_o_1Xl .link-HorizontalMenuItem_YtU1Z{pointer-events:none}.link-HorizontalMenuItem_YtU1Z,.link-HorizontalMenuItem_YtU1Z:hover{display:block;width:100%;height:100%;padding:14px 1rem;box-sizing:border-box;color:inherit;text-decoration:none}.item-HorizontalMenuItem_cYONX{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "menuItem-HorizontalMenuItem_vVLRG",
	"active": "active-HorizontalMenuItem_dvEHe",
	"link": "link-HorizontalMenuItem_YtU1Z",
	"disabled": "disabled-HorizontalMenuItem_o_1Xl",
	"item": "item-HorizontalMenuItem_cYONX"
};
export default ___CSS_LOADER_EXPORT___;
