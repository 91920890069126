import { Observable, of } from 'rxjs';
import { Collection, Comment, CommentData, CommentRequestParameters, SortDirection, User } from '@proliance-ai/typings';
import { storageService } from '@proliance-ai/react-ui';
import { sortObjectsArray } from '@proliance-ai/utilities';
import { Api } from '@services/api';
import { userService } from '@services';

const apiUrl: string = '/api/survey/auth';

class CommentApiService extends Api {
  private storageKey = 'comments';

  private get commentList(): Comment[] {
    return storageService.get(this.storageKey) || [];
  }

  private set commentList(value: Comment[]) {
    storageService.set(this.storageKey, value);
  }

  private getTextFromHtml(text: string): string {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.innerText;
  }

  private generateUUID() {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  }

  private getDateString(): string {
    return new Date().toISOString().split('.')[0];
  }

  private getUserById(id: number): Observable<User> {
    return this.get({
      url: `/api/auth/user/${ id }`,
      ignoreApiUrl: true
    });
  }

  private getThreadInitialComment(id: string, commentList: Comment[]): Comment {
    let comment = commentList.find((comment: Comment) => comment.id === id);

    const getNextComment = (comment: Comment, commentList: Comment[]): Comment => commentList.find((item: Comment) => item.id === comment?.replyTo?.id);
    let nextComment = getNextComment(comment, commentList);
    if (!nextComment) {
      return comment;
    } else {
      comment = nextComment;
    }
    while (!!nextComment && !!nextComment.replyTo) {
      nextComment = getNextComment(comment, commentList);
      if (!nextComment) {
        break;
      }
      comment = nextComment;
    }
    return comment;
  }

  private getThreadByInitialComment(initialComment: Comment, commentList: Comment[]): Comment[] {
    const thread: Comment[] = [];
    let comment = initialComment;
    while (!!comment) {
      thread.push(comment);
      comment = commentList.find((item: Comment) => item?.replyTo?.id === comment.id);
    }
    return thread;
  }

  public getCommentCollection(parameters: CommentRequestParameters): Observable<Collection<Comment>> {
    const {
      id,
      search,
      thread,
      page = 1,
      pageSize = 100,
      sortField = 'createdAt',
      sortDirection = 'desc'
    } = parameters;
    let commentList = this.commentList.filter((comment: Comment) => comment.assetId === id);
    if (thread) {
      const initialComment = this.getThreadInitialComment(thread, commentList);
      commentList = this.getThreadByInitialComment(initialComment, commentList);
    }
    if (search) {
      commentList = commentList
        .filter((comment: Comment) => this.getTextFromHtml(comment.text).toLowerCase().includes(search.toLowerCase()));
    }
    const sorting = [
      {
        path: sortField,
        sortDirection: SortDirection[sortDirection]
      }
    ];
    commentList = sortObjectsArray(
      commentList,
      sorting
    );

    const totalResult = commentList.length;
    const elements = commentList
      .slice((page - 1) * pageSize, page * pageSize);
    return of({
      elements,
      totalResult,
      pageSize,
      currentPage: page,
      sortField,
      sortDirection
    });
  }

  public getComment(commentId: string): Observable<Comment> {
    const comment = this.commentList.find((item: Comment) => item.id === commentId);
    return of(comment);
  }

  public createComment(commentData: CommentData): Observable<Comment> {
    const id = this.generateUUID();
    const author = userService.userSubject$.value;
    const commentList = this.commentList;
    const replyTo = commentData.replyTo
      ? commentList.find((item: Comment) => item.id === commentData.replyTo)
      : undefined;
    const createdAt = this.getDateString();
    const comment: Comment = {
      ...commentData,
      id,
      author,
      replyTo,
      createdAt
    };
    commentList.push(comment);
    this.commentList = commentList;
    return of(comment);
  }

  public updateComment(commentData: Comment): Observable<Comment> {
    const index = this.commentList.findIndex((item: Comment) => item.id === commentData.id);
    if (index === -1) {
      return of();
    }
    const commentList = this.commentList;
    const comment = commentList[index];
    comment.text = commentData.text;
    comment.updatedAt = this.getDateString();
    commentList[index] = comment;
    this.commentList = commentList;
    return of(comment);
  }

  public deleteComment(commentId: string): Observable<void> {
    const commentList = this.commentList;
    const index = commentList.findIndex((item: Comment) => item.id === commentId);
    if (index === -1) {
      return of();
    }
    commentList.splice(index, 1);
    this.commentList = commentList;
    return of();
  }
}

export const commentApiService = new CommentApiService(apiUrl);
