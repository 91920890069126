import { Subject } from 'rxjs';
import { useEffect } from 'react';
import { State } from 'router5';
import { takeUntil } from 'rxjs/operators';
import { kebabCaseToSnakeCase } from '@proliance-ai/design-system';
import { Case } from '@proliance-ai/utilities';
import { routerStateReplaced$, setLastPageStorageValue } from '@router';
import { UseTabsRouteHandling } from './useTabsRouteHandling.typings';

export const useTabsRouteHandling = <T>(options: UseTabsRouteHandling<T>): void => {
  const {
    routeName,
    defaultTab,
    tabState: [ tab, setTab ]
  } = options;
  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    routerStateReplaced$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: State) => {
        setLastPageStorageValue(value.path);
        if (value.name === routeName && tab !== defaultTab) {
          setTab(defaultTab);
        } else if (value.name === `${ routeName }Tab`) {
          const routeTab: null | T = !!value.params.routeTab
            ? kebabCaseToSnakeCase(value.params.routeTab, Case.upper) as T
            : null;
          if (!!routeTab && routeTab !== tab) {
            setTab(routeTab);
          }
        }
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, [ tab ]);
};
