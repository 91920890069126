import { Observable } from 'rxjs';
import { ITableParameters } from '@interfaces';
import {
  companyService,
  IAcademyService, Company, ICompanyCourse,
  ICompanyCourseListRequestParameters,
  IMyCourse,
  IRecurrenceData,
  IUpdateCourseRequest,
  IUserCourseListRequestParameters
} from '@services';
import { academyApiService } from '@services/api';
import { defaultPageSize } from '@proliance-ai/design-system';
import { filter, switchMap, take } from 'rxjs/operators';

const companyCourseInitialState: ICompanyCourseListRequestParameters = {
  sortField: 'title',
  sortDirection: 'asc'
};

const userCoursesFilter: IUserCourseListRequestParameters = {
  page: 1,
  pageSize: defaultPageSize,
  sortField: 'courseStatus',
  sortDirection: 'desc'
};

const myCoursesInitialState: Partial<ITableParameters<IMyCourse>> = {
  page: userCoursesFilter.page,
  pageSize: userCoursesFilter.pageSize,
  sortBy: [
    {
      id: userCoursesFilter.sortField,
      desc: userCoursesFilter.sortDirection === 'desc'
    }
  ]
};

const getCompanyCourse = (parameters: ICompanyCourseListRequestParameters = companyCourseInitialState): Observable<ICompanyCourse[]> => {
  const companyId = companyService.company$.value?.id;
  const isCompany = (company: null | Company): company is Company => !company;
  return companyId
    ? academyApiService.getCompanyCourse({ companyId, ...parameters })
    : companyService.company$.asObservable()
      .pipe(
        filter<null | Company, Company>(isCompany),
        take(1),
        switchMap((company: Company) => {
          const { id: companyId } = company;
          return academyApiService.getCompanyCourse({ companyId, ...parameters });
        })
      );
};

const getUserCourseList = (parameters: IUserCourseListRequestParameters = userCoursesFilter) => academyApiService
  .getUserCourseList(parameters);

const updateCourseRecurrence = (companyCourseId: number, parameters: IRecurrenceData): Observable<IMyCourse> => {
  const { recurrent, recurrentPeriodDays, closeRecurrentTasks } = parameters;
  const body: IUpdateCourseRequest = { recurrentPeriodDays: recurrent ? recurrentPeriodDays : 0 };
  if (!recurrent) {
    body.closeRecurrentTasks = !!closeRecurrentTasks;
  }
  return academyApiService.updateCourseRecurrence(companyCourseId, body);
};

export const academyService: IAcademyService = {
  companyCourseInitialState,
  userCoursesFilter,
  myCoursesInitialState,
  getCompanyCourse,
  getUserCourseList,
  updateCourseRecurrence,
  getCertificateLink: academyApiService.getCertificateLink.bind(academyApiService),
  getCourseReportLink: academyApiService.getCourseReportLink.bind(academyApiService),
  getCourseLinkDictionary: academyApiService.getCourseLinkDictionary.bind(academyApiService),
  getUserCourse: academyApiService.getUserCourse.bind(academyApiService),
  getLicenceScore: academyApiService.getLicenceScore.bind(academyApiService),
  orderLicenses: academyApiService.orderLicenses.bind(academyApiService),
  sendReminder: academyApiService.sendReminder.bind(academyApiService),
  reopenUserCourse: academyApiService.reopenUserCourse.bind(academyApiService)
};
