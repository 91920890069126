import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslationInfo } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import DropdownWidget from './dropdown.widget';
import {
  allowClearProperty,
  dropdownAutoWidthProperty,
  labelProperty,
  maxSelectedChoicesProperty,
  optionsCaptionProperty,
  select2ConfigProperty,
  selectAllTextProperty,
  showPlaceholderProperty
} from './properties';
import {
  countryListMapper as dictionaryDataMapper,
  getCountrySelect2Options as getSelect2Config
} from './utilities';
import { IProperty, IWidgetConfiguration, WidgetLocalization } from '../interfaces';
import { dictionaryService } from '../compatibility';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

import { faFlag } from '@fortawesome/pro-regular-svg-icons';
import { faFlagCheckered } from '@fortawesome/pro-regular-svg-icons';
import './styles/country.styl';

const Country = ($: JQueryStatic): void => {
  const singleName: string = 'country';
  const multipleName: string = 'countrymultiple';
  const singleAlternativeNameList: string[] = [ 'dsecountrydropdown' ];
  const multipleAlternativeNameList: string[] = [];
  const customPropertiesList: IProperty[] = [
    showPlaceholderProperty,
    optionsCaptionProperty,
    allowClearProperty,
    labelProperty,
    select2ConfigProperty,
    dropdownAutoWidthProperty
  ];
  const singleCustomPropertiesList: IProperty[] = [
  ];
  const multipleCustomPropertiesList: IProperty[] = [
    selectAllTextProperty,
    maxSelectedChoicesProperty
  ];
  const hidePropertyNameList: string[] = [
    'allowAddNewTag',
    'select2Config',
    'choicesByUrl',
    'choices',
    'url',
    'choicesMin',
    'choicesMax',
    'choicesStep',
    'choicesOrder',
    'hasOther',
    'otherText',
    'otherPlaceHolder',
    'hasNone',
    'noneText',
    'hasSelectAll',
    'selectAllText',
    'otherErrorText'
  ];
  const singleHidePropertyNameList: string[] = [
    ...hidePropertyNameList
  ];
  const multipleHidePropertyNameList: string[] = [
    ...hidePropertyNameList,
    'colCount'
  ];
  const classNameDictionary = {
    wrap: 'dse-country-dropdown-wrap',
    select: 'p360Select',
    textarea: 'p360Textarea',
    textareaWrap: 'question-other-label-wrap',
    searchField: 'select2-search__field'
  };
  const surveyLocalization: WidgetLocalization = {
    countryOptionsCaption: {
      en: 'Select country',
      de: 'Land auswählen'
    }
  };
  const editorLocalization = {
    pe: {
      optionsCaption: {
        en: 'Placeholder',
        de: 'Platzhalter'
      },
      showPlaceholder: {
        en: 'Show placeholder',
        de: 'Platzhalter anzeigen'
      },
      dropdownAutoWidth: {
        en: 'Dropdown auto width',
        de: 'Automatische Dropdown-Breite'
      }
    }
  };
  const singleEditorLocalization = {
    qt: {
      [singleName]: {
        en: 'Country single',
        de: 'Land Single'
      }
    }
  };
  const multipleEditorLocalization = {
    qt: {
      [multipleName]: {
        en: 'Country multiselect',
        de: 'Land Multiselect'
      }
    }
  };
  let countryList: null | Array<IDictionaryResponse<TranslationInfo>> = null;
  let countryObservable: null | Observable<Array<IDictionaryResponse<TranslationInfo>>> = null;
  const dictionaryDataSource = () => {
    if (countryList !== null) {
      return of(countryList);
    }
    if (countryObservable === null) {
      countryObservable = dictionaryService.getData('country')
        .pipe(
          tap(
            (data: Array<IDictionaryResponse<TranslationInfo>>) => {
              countryList = data;
            })
        );
    }
    return countryObservable;
  };
  const singleDefaultJSON = {
    type: singleName,
    choices: [],
    optionsCaption: null
  };
  const multipleDefaultJSON = {
    type: multipleName,
    choices: [],
    optionsCaption: null
  };
  const singleConfiguration: IWidgetConfiguration = {
    name: singleName,
    iconName: singleName,
    icon: faFlag,
    alternativeNameList: singleAlternativeNameList,
    customPropertiesList: [ ...customPropertiesList, ...singleCustomPropertiesList ],
    hidePropertyNameList: singleHidePropertyNameList,
    classNameDictionary,
    surveyLocalization,
    editorLocalization: { ...editorLocalization, ...singleEditorLocalization },
    dictionaryDataSource,
    dictionaryDataMapper,
    getSelect2Config,
    defaultJSON: singleDefaultJSON,
    isMultiple: false,
    inheritClass: 'dropdownbase'
  };
  const multipleConfiguration: IWidgetConfiguration = {
    name: multipleName,
    iconName: multipleName,
    icon: faFlagCheckered,
    alternativeNameList: multipleAlternativeNameList,
    customPropertiesList: [ ...customPropertiesList, ...multipleCustomPropertiesList ],
    hidePropertyNameList: multipleHidePropertyNameList,
    classNameDictionary,
    surveyLocalization,
    editorLocalization: { ...editorLocalization, ...multipleEditorLocalization },
    dictionaryDataSource,
    dictionaryDataMapper,
    getSelect2Config,
    defaultJSON: multipleDefaultJSON,
    isMultiple: true,
    inheritClass: 'checkbox'
  };
  const singleWidget = new DropdownWidget($, singleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    singleWidget,
    singleWidget.activatedBy
  );
  const multipleWidget = new DropdownWidget($, multipleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    multipleWidget,
    multipleWidget.activatedBy
  );
};

export default Country;
