import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalArea } from '@proliance-ai/typings';
import { Badge } from '@proliance-ai/design-system';
import { Case, snakeCaseToKebabCase } from '@proliance-ai/utilities';
import { IBasicScoreData, ITaskCount, TaskCountType } from '@services';
import { ProgressBar } from '@shared/components';

import {
  faHandshake,
  faUserFriends,
  faPhoneLaptop,
  faMegaphone,
  faShoppingBag,
  faPiggyBank,
  faKey,
  faShield,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons';

type IconData = {
  [key in FunctionalArea | 'TOM']: IconDefinition;
};

import styles from './Tile.styl';

interface IOwnProps {
  type: TaskCountType;
  data?: ITaskCount;
}

type Props = IOwnProps;

const defaultData: ITaskCount = {
  companyOpen: 0,
  companyTotal: 0,
  currentUserOpen: 0
};

const Tile: FC<Props> = ({ type, data = defaultData }) => {
  const { t } = useTranslation('dashboard');

  const isTom = type === 'TOM';
  const noParametersTypes = [ 'TOM', Object.keys(FunctionalArea)[0] ];
  const hasParameters = !noParametersTypes.includes(type);
  const routeName = isTom ? 'tom' : hasParameters ? 'processesTab' : 'processes';
  const urlParameters = hasParameters ? { routeTab: snakeCaseToKebabCase(type, Case.lower) } : {};
  const queryParameters = { showOpenTasks: 'on' };
  const routeParams = { ...urlParameters, ...queryParameters };

  const iconDictionary: IconData = {
    THIRD_PARTY_PROCESSING: faHandshake,
    HR: faUserFriends,
    IT: faPhoneLaptop,
    SALES: faMegaphone,
    PURCHASE: faShoppingBag,
    FINANCE: faPiggyBank,
    SECURITY: faKey,
    TOM: faShield
  };

  const icon = iconDictionary[type];
  const iconBlock = (
    <div className={ styles.iconBlock }>
      <Badge
        content={ data?.currentUserOpen }
        color="danger"
        isRounded={ true }
      >
        <FontAwesomeIcon className={ styles.icon } icon={ icon }/>
      </Badge>
    </div>
  );

  const text = isTom ? (
    <p className={ styles.title }>
      { t('assessment.assessment') }
      <span title={ t('assessment.tom') } className={ styles.type }>
        { t('assessment.tom') }
      </span>
    </p>
  ) : (
    <p className={ styles.title }>
      { t('assessment.processAssessment') }
      <span title={ t(`assessment.functionalArea.${ type }`) } className={ styles.type }>
        { t(`assessment.functionalArea.${ type }`) }
      </span>
    </p>
  );

  const progress: IBasicScoreData = {
    current: data?.companyTotal - data?.companyOpen,
    total: data?.companyTotal
  };

  return (
    <li className={ styles.tile }>
      <Link
        data-link
        className={ styles.link }
        routeName={ routeName }
        routeParams={ routeParams }
      >
        { iconBlock }
        <div className={ styles.data }>
          { text }
          <ProgressBar data={ progress }/>
        </div>
      </Link>
    </li>
  );
};

export default Tile;
