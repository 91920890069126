// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper-DealPackage_K_QG8{position:relative;min-width:132px;min-height:43px}.package-DealPackage_FG4gy{padding:12px 20px;font-weight:500;border:thin solid #d4d4d4;display:inline-block;border-radius:.3rem;color:#38a7d7;min-width:90px;min-height:17px}.label-DealPackage_FNazn{position:absolute;top:-9px;left:15px;padding:2px 5px;box-sizing:border-box;background-color:#fff;border-radius:.3rem}.text-DealPackage_1PXh4{font-size:12px;font-weight:500}@media all and (max-width:560px){.text-DealPackage_1PXh4{font-size:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper-DealPackage_K_QG8",
	"package": "package-DealPackage_FG4gy",
	"label": "label-DealPackage_FNazn",
	"text": "text-DealPackage_1PXh4"
};
export default ___CSS_LOADER_EXPORT___;
