// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-UpgradeBrowser_sSLth{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:24px;-webkit-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;max-width:552px;background-color:#fff;z-index:999;margin-bottom:24px;border-radius:.3rem;box-shadow:0 3px 8px 0 rgba(0,0,0,0.09);border:1px solid rgba(24,24,24,0.19)}.attention-UpgradeBrowser_rRy42{min-width:51px;width:51px;height:33px;margin-right:24px;fill:#f2994a}.textBlock-UpgradeBrowser_LI3Ke{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;-webkit-flex:1;-ms-flex:1;flex:1}.heading-UpgradeBrowser_qp3gQ,.text-UpgradeBrowser_JzKg5{font-family:Roboto;color:#4f4f4f;margin:0}.heading-UpgradeBrowser_qp3gQ{font-weight:500;font-size:17px;line-height:22px;margin-bottom:4px}.text-UpgradeBrowser_JzKg5{font-weight:400;font-size:14px;line-height:16px}.browser-UpgradeBrowser_W6yeU{margin-top:16px;margin-bottom:0}.browser-UpgradeBrowser_W6yeU > div{display:inline-block}.browser-UpgradeBrowser_W6yeU > div > a{text-decoration:none}.browser-UpgradeBrowser_W6yeU > div img,.browser-UpgradeBrowser_W6yeU > div span{vertical-align:middle}.browser-UpgradeBrowser_W6yeU > div span{padding-left:5px;padding-right:15px;font-size:12px;color:#4f4f4f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "notification-UpgradeBrowser_sSLth",
	"attention": "attention-UpgradeBrowser_rRy42",
	"textBlock": "textBlock-UpgradeBrowser_LI3Ke",
	"heading": "heading-UpgradeBrowser_qp3gQ",
	"text": "text-UpgradeBrowser_JzKg5",
	"browser": "browser-UpgradeBrowser_W6yeU"
};
export default ___CSS_LOADER_EXPORT___;
