import React from 'react';
import { SVG } from '@proliance-ai/design-system';
import { getStaticLogoResourceUrl } from '@proliance-ai/utilities';

import styles from './Logo.styl';

const logo = getStaticLogoResourceUrl({ name: 'proliance360_the_privacy_platform.svg' });

const Logo: React.FC = () => (
  <div className={styles.wrap}>
    <SVG
      className={styles.logo}
      src={logo}
    />
  </div>
);

export default Logo;
