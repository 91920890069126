import { Observable, of } from 'rxjs';
import { Collection } from '@proliance-ai/typings';
import {
  AcademyReportFileType,
  companyService,
  IBasicScoreData,
  ICompanyCourse,
  ICompanyCourseRequestParameters,
  IMyCourse,
  IReopenUsrCourseData,
  IUpdateCourseRequest,
  IUserCourse,
  IUserCourseListRequestParameters,
  IUserCourseRequestParameters,
  userService
} from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/academy';

export class AcademyApiService extends Api {
  public getCertificateLink(locale: string, userCourseId: number, resultId?: number): string {
    return resultId
      ? `${ apiUrl }/user-course/${ userCourseId }/${ resultId }/certificate?locale=${ locale }`
      : `${ apiUrl }/user-course/${ userCourseId }/certificate?locale=${ locale }`;
  }

  public getCourseReportLink(locale: string, fileType?: AcademyReportFileType): string {
    return fileType
      ? `${ apiUrl }/company-course/academy-report?locale=${ locale }&fileType=${ fileType }`
      : `${ apiUrl }/company-course/academy-report?locale=${ locale }`;
  }

  public getCourseLinkDictionary(body: string[]): Observable<Record<string, string>> {
    if (!body.length) {
      return of({});
    }
    return this.post<Record<string, string>>({
      url: 'user-course/link/task',
      body
    });
  }

  public getCompanyCourse(parameters: ICompanyCourseRequestParameters): Observable<ICompanyCourse[]> {
    const { companyId, sortField, sortDirection } = parameters;
    return this.get<ICompanyCourse[]>({
      url: `company-course/data/${ companyId }`,
      parameters: { sortField, sortDirection }
    });
  }

  public getUserCourse(parameters: IUserCourseRequestParameters): Observable<Collection<IUserCourse>> {
    return this.get<Collection<IUserCourse>>({
      url: 'user-course',
      parameters
    });
  }

  public getLicenceScore(companyCourseId: number): Observable<IBasicScoreData> {
    return this.get<IBasicScoreData>({
      url: `company-course/${ companyCourseId }/licence-count`
    });
  }

  public getUserCourseList(parameters: IUserCourseListRequestParameters): Observable<Collection<IMyCourse>> {
    const companyId = companyService.company$.value?.id;
    const userId = userService.userSubject$.value?.id;
    return this.get<Collection<IMyCourse>>({
      url: 'user-course',
      parameters: { userId, companyId, ...parameters }
    });
  }

  public reopenUserCourse(body: IReopenUsrCourseData): Observable<IUserCourse> {
    return this.put({
      url: 'user-course/reopen',
      body
    });
  }

  public updateCourseRecurrence(companyCourseId: number, body: IUpdateCourseRequest): Observable<IMyCourse> {
    return this.patch({
      url: `company-course/${ companyCourseId }/recurrence`,
      body
    });
  }

  public orderLicenses(companyCourseId: number, licenses: number): Observable<null> {
    return this.post<null>({
      url: `company-course/${ companyCourseId }/order-licenses`,
      parameters: { licenses },
      suppressDefaultErrorHandler: true
    });
  }
  public sendReminder(userCourseId: number): Observable<null> {
    return this.post<null>({
      url: `user-course/${ userCourseId }/reminder`
    });
  }
}


export const academyApiService = new AcademyApiService(apiUrl);
