import { Cell } from 'react-table';
import { TranslationInfo, Locale } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';

export const CountryCell = (
  countryList: Array<IDictionaryResponse<TranslationInfo>>,
  language: Locale
) => (cell: Cell<any>): string => {
  const country = countryList
    .find((item: IDictionaryResponse<TranslationInfo>) => item.id === cell.row.original.country);
  return country ? country.title[language] : '';
};
