import { i18n } from '@services';

export const waitForLocalization = async (key: string): Promise<void> => new Promise((resolve) => {
  const check = (): void => {
    if (i18n.exists(key)) {
      resolve();
    }
  };
  check();
  i18n.on('loaded', check);
});
