import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from '@proliance-ai/design-system';

import styles from './ConsentModal.styl';

const ConsentModal: FC = (): null | ReactElement => {
  const { t } = useTranslation();

  const panel = t('consentModal.accordion.panel');

  const content = (
    <div
      className={ styles.content }
      dangerouslySetInnerHTML={ { __html: t('consentModal.accordion.content') } }
    />
  );

  return (
    <div className={ styles.content }>
      <h2 className={ styles.title }>{ t('consentModal.heading') }</h2>
      <p dangerouslySetInnerHTML={ { __html: t('consentModal.paragraph1') } }/>
      <p dangerouslySetInnerHTML={ { __html: t('consentModal.paragraph2') } }/>
      <Accordion
        allowAllCollapsed
        preset="flat"
      >
        <AccordionItem
          key="item"
          id="item"
          expanded={ false }
          summaryClassName={ styles.panel }
          panel={ panel }
          content={ content }
        />
      </Accordion>
      <p dangerouslySetInnerHTML={ { __html: t('consentModal.paragraph3') } }/>
      <p className={ styles.last } dangerouslySetInnerHTML={ { __html: t('consentModal.paragraph4') } }/>
    </div>
  );
};

export default memo(ConsentModal);
