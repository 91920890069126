import { cloneElement, ReactElement } from 'react';
import { IPermission } from '@proliance-ai/typings';
import { isEmpty } from '@utilities';

export interface IPermissionProperties extends Record<string, any> {
  readonly?: boolean;
  demo?: boolean;
  className?: string;
}

export const setPermission = (
  element: ReactElement,
  permission?: IPermission,
  readonlyKey?: string,
  readonlyValue?: any
): null | ReactElement => {
  const props: IPermissionProperties = {};
  if (typeof permission === 'undefined' || !permission.read) {
    return null;
  }
  if (permission.read && !permission.write) {
    const property = readonlyKey || 'readonly';
    props[property] = typeof readonlyValue === 'undefined'
      ? true
      : readonlyValue;
  }
  if (permission.demo) {
    props.demo = true;
    props.className = typeof element.props.className === 'undefined'
      ? 'demo'
      : `${ element.props.className } demo`;
  }
  return isEmpty(props)
    ? element
    : cloneElement(element, props);
};
