// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".toggle-LanguageToggle_AkJIZ{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;cursor:pointer}.flag-LanguageToggle_FAyI2{margin-right:8px}.text-LanguageToggle_trSt3{font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "toggle-LanguageToggle_AkJIZ",
	"flag": "flag-LanguageToggle_FAyI2",
	"text": "text-LanguageToggle_trSt3"
};
export default ___CSS_LOADER_EXPORT___;
