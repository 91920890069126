// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-CustomerRequestModalContent_TzWZ4{margin-top:1.5rem}.fileUpload-CustomerRequestModalContent_pJ65x > div{width:100%}.success-CustomerRequestModalContent_fHEaW{padding:64px 96px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem}.success-CustomerRequestModalContent_fHEaW .icon-CustomerRequestModalContent_Swyg1{width:40px;height:40px;color:#27ae60}.success-CustomerRequestModalContent_fHEaW .successMessage-CustomerRequestModalContent_RQnnK{text-align:center;max-width:400px;color:#4f4f4f;font-size:18px;line-height:22px;font-weight:500}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form-CustomerRequestModalContent_TzWZ4",
	"fileUpload": "fileUpload-CustomerRequestModalContent_pJ65x",
	"success": "success-CustomerRequestModalContent_fHEaW",
	"icon": "icon-CustomerRequestModalContent_Swyg1",
	"successMessage": "successMessage-CustomerRequestModalContent_RQnnK"
};
export default ___CSS_LOADER_EXPORT___;
