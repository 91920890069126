import { Systems, SystemsEntity } from '@services';
import { defaultPageSize, ITableState } from '@proliance-ai/design-system';

export const systemsInitialState: Record<Systems, Partial<ITableState<SystemsEntity>>> = {
  BUSINESS_PREMISE: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  ASSOCIATED_COMPANY: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  DATA_STORAGE_CLOUD_HOSTED: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  DATA_STORAGE_SELF_HOSTED: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  DATA_STORAGE_THIRD_PARTY_HOSTED: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  FREELANCER: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  PRINCIPAL: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  SERVICE_PROVIDER: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  SOFTWARE: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'name',
      desc: false
    } ]
  },
  WEBSITE: {
    pageSize: defaultPageSize,
    sortBy: [ {
      id: 'url',
      desc: false
    } ]
  }
};
