// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-Status_x9Hji{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-justify-content:flex-start;-ms-justify-content:flex-start;justify-content:flex-start;width:100%;padding:1.5rem;gap:1rem}.wrap-Status_x9Hji .area-Status_kwCkl{-webkit-flex:1;-ms-flex:1;flex:1;max-width:740px}.wrap-Status_x9Hji .areaChartWidget-Status_YdLt5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center}.wrap-Status_x9Hji .sideBar-Status_Fxjk9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:0 0 353px;-ms-flex:0 0 353px;flex:0 0 353px;max-width:353px}.wrap-Status_x9Hji .sideBar-Status_Fxjk9:empty{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-Status_x9Hji",
	"area": "area-Status_kwCkl",
	"areaChartWidget": "areaChartWidget-Status_YdLt5",
	"sideBar": "sideBar-Status_Fxjk9"
};
export default ___CSS_LOADER_EXPORT___;
