// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskList-AssessmentTasks_CDlBo{width:100%;list-style:none;margin:0;padding:0;box-shadow:0 3px 8px rgba(0,0,0,0.09)}.tileList-AssessmentTasks_thmaW{position:relative;width:100%;padding:24px 24px 8px;box-sizing:border-box;display:grid;-ms-grid-template-columns:minmax(100px,1fr) minmax(100px,1fr);grid-template-columns:minmax(100px,1fr) minmax(100px,1fr);-webkit-column-gap:16px;-moz-column-gap:16px;column-gap:16px;list-style:none;margin:0;box-shadow:0 3px 8px rgba(0,0,0,0.09)}.loading-AssessmentTasks_XROv5{width:100%;height:98px;position:relative}.empty-AssessmentTasks_tIb_n{width:100%;height:49px;text-align:center;font-size:14px;padding:16px;box-sizing:border-box;font-weight:500;color:#979797;line-height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskList": "taskList-AssessmentTasks_CDlBo",
	"tileList": "tileList-AssessmentTasks_thmaW",
	"loading": "loading-AssessmentTasks_XROv5",
	"empty": "empty-AssessmentTasks_tIb_n"
};
export default ___CSS_LOADER_EXPORT___;
