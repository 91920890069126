// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\";filter:alpha(opacity=0);opacity:0}.fade-enter-active{-ms-filter:none;filter:none;opacity:1;-webkit-transition:opacity .5s;transition:opacity .5s}.fade-exit{-ms-filter:none;filter:none;opacity:1}.fade-exit-active{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\";filter:alpha(opacity=0);opacity:0;-webkit-transition:opacity .5s;transition:opacity .5s}.carousel-View_SDM8A{width:100%;height:100%;position:relative;overflow:hidden}.item-View_tHd1L{width:100%;height:100%;position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": "carousel-View_SDM8A",
	"item": "item-View_tHd1L"
};
export default ___CSS_LOADER_EXPORT___;
