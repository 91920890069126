// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".statusIcon-StatusIcon_bI0Fy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center}.icon-StatusIcon_y0Y0V{width:22px;height:22px}.icon-StatusIcon_y0Y0V.default-StatusIcon_jLsYV{color:#d9d9d9}.icon-StatusIcon_y0Y0V.primary-StatusIcon_BSreQ{color:#38a7d7}.icon-StatusIcon_y0Y0V.success-StatusIcon_UTgCI{color:#27ae60}.icon-StatusIcon_y0Y0V.warning-StatusIcon_u2wR1{color:#f2994a}.icon-StatusIcon_y0Y0V.danger-StatusIcon_DCRnw{color:#eb5757}.label-StatusIcon_UaSmb{margin-left:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIcon": "statusIcon-StatusIcon_bI0Fy",
	"icon": "icon-StatusIcon_y0Y0V",
	"default": "default-StatusIcon_jLsYV",
	"primary": "primary-StatusIcon_BSreQ",
	"success": "success-StatusIcon_UTgCI",
	"warning": "warning-StatusIcon_u2wR1",
	"danger": "danger-StatusIcon_DCRnw",
	"label": "label-StatusIcon_UaSmb"
};
export default ___CSS_LOADER_EXPORT___;
