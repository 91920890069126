// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-CompanySwitcherModalContent_JkXUp{overflow:hidden;border:1px solid #e0e0e0;border-radius:4px;margin:1.5rem}.search-CompanySwitcherModalContent_s41l9{margin:1rem}.divider-CompanySwitcherModalContent_mYq_P{border-bottom:1px solid #e0e0e0}.emptyText-CompanySwitcherModalContent_cWWG3{margin:auto;font-size:14px;font-weight:500;color:#979797}.listItem-CompanySwitcherModalContent_Sv9db{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-height:120px;max-height:175px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "content-CompanySwitcherModalContent_JkXUp",
	"search": "search-CompanySwitcherModalContent_s41l9",
	"divider": "divider-CompanySwitcherModalContent_mYq_P",
	"emptyText": "emptyText-CompanySwitcherModalContent_cWWG3",
	"listItem": "listItem-CompanySwitcherModalContent_Sv9db"
};
export default ___CSS_LOADER_EXPORT___;
