import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { Website, SystemsType } from '@services';
import { Header } from '@proliance-ai/design-system';

const getWebsiteColumnList = (
  t: TFunction,
  systemsType: SystemsType
): Array<Column<Website>> => [
  {
    accessor: 'url',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  }
];

export default getWebsiteColumnList;
