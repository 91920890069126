import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DataFormat, DataParams, Event, GroupedDataFormat, Options } from 'select2';
import { TranslationInfo, Locale } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { clone, createElement, isEqual } from '@proliance-ai/design-system';
import { addClass } from '../common';
import { ActivatedBy, IWidgetConfiguration } from '../interfaces';
import { language } from './utilities';
import { getSorter, isEmpty, SortDirection } from '../compatibility';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { ItemValue, Question } from '@shared/surveyJs/reexport';

export interface IQuestionDropdown extends Question {
  $: JQueryStatic;
  placeholder: string;
  settings: Options;
  activatedBy: ActivatedBy;
  dictionary: string;
  select2Config: string | {};
  isSettingValue: boolean;
  choicesOrder: keyof typeof SortDirection;
  allowClear: boolean;
  allowAddNewTag: boolean;
  minimumResultsForSearch: number;
  maxSelectedChoices: number;
  showPlaceholder: boolean;
  dropdownAutoWidth: boolean;
  otherPlaceHolder: string;
  optionsCaption: string | (() => string);
  selectAll: () => void;
  renderedValue: null | string;
  isOtherSelected: boolean;
  hasSelectAll: boolean;
  isAllSelected: boolean;
  keepIncorrectValues: boolean;
  selectAllItemValue: ItemValue;
  choicesFromUrl: ItemValue[];
  visibleChoices: ItemValue[];
  choices: any;
  choicesByUrl: any;
  apiUrl?: string;
  pageSize?: number;
  cachedChoices: Record<string, string>;
}

export default class DropdownWidget extends WidgetAbstractClass<IQuestionDropdown> {
  public inheritClass = 'dropdownbase';

  public isMultiple: boolean = false;

  private readonly $!: JQueryStatic;

  constructor($: JQueryStatic, configuration: IWidgetConfiguration) {
    super(configuration);
    this.$ = $;
    Object.assign(this, configuration);
  }

  public widgetIsLoaded(): boolean {
    return typeof this.$ === 'function' && !!this.$.fn.select2;
  }

  public activatedByChanged(activatedBy: ActivatedBy): void {
    if (!this.widgetIsLoaded()) {
      return;
    }
    this.activatedBy = activatedBy;

    super.activatedByChanged(activatedBy);
  }

  public afterRender(question: IQuestionDropdown, element: HTMLElement): void {
    element.innerHTML = '';
    super.afterRender(question, element);
    question.settings = this.getSettings(question, element);
    this.setSelect(question, element);
    this.setTextarea(question, element);
    this.updateComment(question, element);
    if (this.isMultiple) {
      this.fixStyles(question, element);
    }
    question.valueChangedCallback = () => {
      this.updateValueHandler(question, element);
    };
    this.updateValueHandler(question, element);
  }

  public renderer(_question: IQuestionDropdown, element: HTMLElement): void {
    addClass(element, [ this.classNameDictionary.wrap, 'p360-dropdown-widget-base' ]);
    const select: HTMLSelectElement = document.createElement('SELECT') as HTMLSelectElement;
    addClass(select, this.classNameDictionary.select);
    select.style.width = '100%';
    if (this.isMultiple) {
      select.multiple = true;
    }
    const textarea = createElement({
      tagName: 'textarea',
      styles: { display: 'none' },
      classNames: this.classNameDictionary.textarea
    });
    element.appendChild(select);
    element.appendChild(textarea);
  }

  public onChangeReadOnly(question: IQuestionDropdown, element: HTMLElement): void {
    const select = this.getElement('select', element);
    if (select) {
      const $select = this.$(select);
      $select.prop('disabled', question.isReadOnly);
    }
    const textarea = this.getElement('textarea', element);
    if (textarea) {
      const $textarea = this.$(textarea) as JQuery<HTMLTextAreaElement>;
      $textarea.prop('disabled', question.isReadOnly);
    }
    question.isSettingValue = false;
  }

  public willUnmount(question: IQuestionDropdown, element: HTMLElement): void {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = this.$(select);
    if (!!$select.data('select2')) {
      $select
        .off('select2:select')
        .off('select2:unselect')
        .off('input')
        .select2('destroy');
    }

    super.willUnmount(question, element);
  }

  protected onPropertyChangedHandler(question: IQuestionDropdown, element: HTMLElement, options: any): void {
    super.onPropertyChangedHandler(question, element, options);
    if (options.name === 'visibleChoices') {
      this.updateChoices(question, element);
    }
    if (options.name === 'dictionary') {  // TODO refresh default value editor
      this.updateChoices(question, element);
    }
    if (options.name === 'showPlaceholder') {
      this.updateChoices(question, element);
    }
    if (options.name === 'optionsCaption') {
      this.updateChoices(question, element);
    }
  }

  private getSelect2Config(
    _select: HTMLSelectElement, _isMultiple: boolean, _locale: string, _question?: IQuestionDropdown
  ): Partial<Options> {
    return {};
  }

  private getSettings(question: IQuestionDropdown, element: HTMLElement): Options {
    const questionSelect2Config = question.select2Config;
    const select2Config = questionSelect2Config && typeof questionSelect2Config === 'string'
      ? { ...JSON.parse(questionSelect2Config) }
      : questionSelect2Config ?? {};
    const select = this.getElement('select', element) as HTMLSelectElement;
    const locale = this.getCurrentLocale(question);
    const widgetSelect2Config = this.getSelect2Config(
      select,
      this.isMultiple,
      locale,
      question
    );
    const closeOnSelect = !this.isMultiple;
    const sortDirection = SortDirection[question.choicesOrder as keyof typeof SortDirection] ?? SortDirection.none;
    const topItemList = [ 'none' ];
    const bottomItemList = [ 'selectall', 'other' ];
    const sorter = getSorter({ locale, sortDirection, topItemList, bottomItemList });
    const defaultSettings = { closeOnSelect, sorter };

    const settings = { ...defaultSettings, ...widgetSelect2Config, ...select2Config };
    settings.language = language(this.getCurrentLocale(question));

    const id: string = '';
    const text: string = question.showPlaceholder
      ? typeof question.optionsCaption === 'function'
        ? question.optionsCaption()
        : question.optionsCaption || question.placeholder || ''
      : '';
    settings.placeholder = { id, text };
    settings.allowClear = question.allowClear && !question.isReadOnly;
    settings.disabled = question.isReadOnly;
    if (question.maxSelectedChoices) {
      settings.maximumSelectionLength = question.maxSelectedChoices;
    }
    settings.theme = 'classic';
    if (this.isMultiple) {
      settings.tags = question.allowAddNewTag;
    }
    settings.minimumResultsForSearch = question.minimumResultsForSearch;
    settings.dropdownAutoWidth = question.dropdownAutoWidth;
    return settings;
  }

  private setSelect(question: IQuestionDropdown, element: HTMLElement): void {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = this.$(select);
    $select.select2(question.settings);
    if (this.isMultiple) {
      $select.on(
        'select2:select',
        (event: Event<HTMLSelectElement, DataParams>): void => {
          const value = +event.params.data.id || event.params.data.id;
          if (this.isAllItemSelected(question, value)) {
            question.selectAll();
          } else {
            const questionValue = question.value || [];
            const questionValueArray = Array.isArray(questionValue) ? questionValue : [ questionValue ];
            question.value = [ ...questionValueArray, value ];
          }
          this.updateComment(question, element);
        }
      );
      $select.on(
        'select2:unselect',
        (event: Event<HTMLSelectElement, DataParams>): void => {
          const value = +event.params.data.id || event.params.data.id;
          const index = (question.value || []).indexOf(value);
          if (this.isAllItemSelected(question, value)) {
            question.clearValue();
          } else if (index !== -1) {
            const newValue = [].concat(question.value);
            newValue.splice(index, 1);
            question.value = newValue;
          }
          this.updateComment(question, element);
        }
      );
    } else {
      $select.on(
        'select2:select',
        (event: Event<HTMLSelectElement, DataParams>): void => {
          question.value = +event.params.data.id || event.params.data.id;
          this.updateComment(question, element);
        }
      );
      $select.on(
        'select2:unselecting',
        (): void => {
          question.renderedValue = null;
          this.updateComment(question, element);
        }
      );
      $select.on(
        'change',
        (): void => {
          question.value = $select.val();
          this.updateComment(question, element);
        }
      ); // Fix autocomplete not firing select2:select issue
    }

    this.updateChoices(question, element);
  }

  private setTextarea(question: IQuestionDropdown, element: HTMLElement): void {
    const textarea = this.getElement('textarea', element);
    if (!textarea) {
      console.error('Element "textarea" not found');
      return;
    }
    question.isSettingValue = false;
    const $textarea = this.$(textarea) as JQuery<HTMLTextAreaElement>;
    if (this.isMultiple
      && !!question.survey
      && !!(question.survey as any).css &&
      !!(question.survey as any).css.checkbox
    ) {
      addClass(textarea,
        (question.survey as any).css.checkbox.other
          ? (question.survey as any).css.checkbox.other.split(' ')
          : ''
      );
    } else {
      addClass(
        textarea,
        question.cssClasses.other
          ? question.cssClasses.other.split(' ')
          : ''
      );
    }
    $textarea.prop('disabled', question.isReadOnly);
    $textarea.on(
      'input propertychange',
      this.textareaChangeHandler(question, element)
    );
  }

  private textareaChangeHandler(question: IQuestionDropdown, element: HTMLElement): () => void {
    return () => {
      if (question.isSettingValue) {
        return;
      }
      const textarea = this.getElement('textarea', element) as HTMLTextAreaElement;
      if (!textarea) {
        console.error('Element "textarea" not found');
        return;
      }
      question.comment = textarea.value;
    };
  }

  private updateComment(question: IQuestionDropdown, element: HTMLElement) {
    const textarea = this.getElement('textarea', element) as HTMLTextAreaElement;
    if (!textarea) {
      console.error('Element "textarea" not found');
      return;
    }
    textarea.value = question.comment || '';
    textarea.placeholder = question.hasOther
      ? question.otherPlaceHolder || ''
      : '';
    const $textarea = this.$(textarea);
    setTimeout(() => {
      const parent = $textarea.parent();
      if (parent.hasClass(this.classNameDictionary.textareaWrap)) {
        if (question.isOtherSelected) {
          parent.show();
        } else {
          parent.hide();
        }
      } else {
        if (question.isOtherSelected) {
          $textarea.show();
        } else {
          $textarea.hide();
        }
      }
    });
  }

  private updateValueHandler(question: IQuestionDropdown, element: HTMLElement) {
    if (question.isSettingValue) {
      return;
    }
    question.isSettingValue = true;
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = this.$(select) as JQuery<HTMLSelectElement>;
    if (this.isMultiple) {
      if (question.hasSelectAll && question.isAllSelected) {
        $select
          .val([ question.selectAllItemValue.value ].concat(question.value))
          .trigger('change');
      } else {
        this.checkIncorrectValue(question);
        $select.val(question.value).trigger('change');
        if (question.allowAddNewTag && ($select.val() as string).length !== question.value.length
        ) {
          question.value.forEach((choiceValue: string) => {
            if (($select.val() as string[]).indexOf(choiceValue) === -1) {
              if (question.value && (!question.choicesByUrl.isEmpty || question.dictionary)) {
                $select.next().find('.select2-selection').addClass('loading');
              } else {
                $select.append(this.getNewOption(question)).trigger('change');
              }
            }
          });
        }
      }
    } else {
      if ($select.find('option[value=\'' + question.value + '\']').length) {
        $select.val(question.value).trigger('change');
      } else {
        if (question.value && (!question.choicesByUrl.isEmpty || question.dictionary)) {
          $select.next().find('.select2-selection').addClass('loading');
        } else if (question.value !== null && question.value !== undefined) {
          $select.append(this.getNewOption(question)).trigger('change');
        }
      }
    }

    this.fixStyles(question, element);
    this.updateComment(question, element);
    question.isSettingValue = false;
  }

  private getNewOption(question: IQuestionDropdown): HTMLOptionElement {
    return new Option(
      question.value, // TODO if question value is object then need to improve
      question.value,
      true,
      true
    );
  }

  private dictionaryDataSource(
    _question: IQuestionDropdown
  ): null | Observable<Array<IDictionaryResponse<TranslationInfo>>> {
    return null;
  }

  private dictionaryDataMapper(_data: Array<IDictionaryResponse<TranslationInfo>>, _locale: Locale): DataFormat[] {
    return [];
  }

  private getDataFromChoices(choiceList: ItemValue[]): DataFormat[] {
    return choiceList
      .map(
        (choice: ItemValue) => ({
          id: choice.value,
          text: choice.text
        })
      );
  }

  private getChoicesData(question: IQuestionDropdown): Observable<DataFormat[]> {
    const source = this.dictionaryDataSource(question);
    if (source === null) {
      return of(
        question.choicesByUrl.url
          ? question.choicesFromUrl
            ? this.getDataFromChoices(question.choicesFromUrl)
            : []
          : question.visibleChoices
            ? this.getDataFromChoices(question.visibleChoices)
            : []
      );
    } else {
      question.keepIncorrectValues = true;
      const locale: Locale = this.getCurrentLocale(question);
      return source
        .pipe(
          map(
            (dataList: Array<IDictionaryResponse<TranslationInfo>>) => this.dictionaryDataMapper(
              dataList,
              locale
            )
          )
        );
    }
  }

  private checkIncorrectValue(question: IQuestionDropdown): void {
    if (
      question.keepIncorrectValues
      || isEmpty(question.value)
      || !question.settings.data
      || !question.settings.data.length
    ) {
      return;
    }
    const idList: Array<string | number> = [];
    question.settings.data
      .forEach((item: DataFormat | GroupedDataFormat) => {
        if (!!item.id) {
          idList.push(item.id);
        }
      });
    this.setChoices(question);
    question.value = question
      .value
      .filter((value: string) => idList.includes(value));
  }

  private setChoices(question: IQuestionDropdown): void {
    const choices = (question.settings.data as DataFormat[])
      .map((item: DataFormat) => ({
        value: item.id,
        text: item.text
      } as ItemValue))
      .filter((item: ItemValue) => ![ 'selectall', 'other', 'none' ].includes(item.value));
    const questionChoices = clone(question.choices)
      .map((item: ItemValue) => ({
        value: item.value,
        text: item.text
      })
      );
    if (!question.survey.isDesignMode && !isEqual(Object.keys(choices), Object.keys(questionChoices))) {
      question.choices = choices;
    }
    question.survey.loadedChoicesFromServer({ ...question, choices } as unknown as Question);
  }

  private updateChoices(question: IQuestionDropdown, element: HTMLElement) {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = this.$(select) as JQuery<HTMLSelectElement>;
    $select.select2().empty();

    const id: string = '';
    const text: string = !question.showPlaceholder
      ? ''
      : typeof question.optionsCaption === 'function'
        ? question.optionsCaption()
        : question.optionsCaption || question.placeholder || '';
    question.settings.placeholder = { id, text };

    if (this.isMultiple) {
      if (question.settings.ajax && question.getType() !== 'apidropdownmultiple') {
        $select.select2(question.settings);
        question.keepIncorrectValues = true;
      } else if (!question.settings.data?.length) {
        this.getChoicesData(question)
          .pipe(take(1))
          .subscribe((data: DataFormat[]) => {
            question.settings.data = data;
            this.setChoices(question);
            this.checkIncorrectValue(question);
            $select
              .empty()
              .select2(question.settings)
              .val(question.value)
              .trigger('change');
          });
      } else {
        this.setChoices(question);
        this.checkIncorrectValue(question);
        $select
          .empty()
          .select2(question.settings)
          .val(question.value)
          .trigger('change');
      }
    } else {
      if (!question.settings.theme) {
        question.settings.theme = 'classic';
      }
      question.settings.disabled = question.isReadOnly;
      if (question.settings.ajax && question.getType() !== 'apidropdown') {
        $select.select2(question.settings);
        question.keepIncorrectValues = true;
      } else if (!question.settings.data?.length) {
        this.getChoicesData(question)
          .pipe(take(1))
          .subscribe((choices: DataFormat[]) => {
            question.settings.data = [ ...choices ];
            this.setChoices(question);
            $select
              .empty()
              .select2(question.settings);
            if (question.settings.data && question.settings.data.length) {
              $select
                .val(question.value)
                .trigger('change');
            }
          });
      } else {
        this.setChoices(question);
        $select
          .empty()
          .select2(question.settings);
        if (question.settings.data && question.settings.data.length) {
          $select
            .val(question.value)
            .trigger('change');
        }
      }
      // fixed width accrording to
      // https://stackoverflow.com/questions/45276778/select2-not-responsive-width-larger-than-container
      if (!!element.querySelector('.select2')) {
        (element.querySelector('.select2') as HTMLElement).style.width = '100%';
      }
      if (!!element.nextElementSibling) {
        (element.nextElementSibling as HTMLElement).style.marginBottom = '1px';
      }
    }
    this.updateValueHandler(question, element);
  }

  private fixStyles(_question: IQuestionDropdown, element: HTMLElement) {
    if (!this.isMultiple) {
      return;
    }
    const searchField = this.getElement('searchField', element);
    if (!searchField) {
      console.error('Element "searchField" not found');
      return;
    }
    searchField.style.border = 'none';
  }

  private isAllItemSelected(question: IQuestionDropdown, value: number | string) {
    if (!question.hasSelectAll) {
      return false;
    }
    return value === question.selectAllItemValue.value;
  }
}
