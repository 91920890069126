import { Observable } from 'rxjs';
import { Collection } from '@proliance-ai/typings';
import { activityApiService } from '@services/api';
import { companyService, IActivityService, IActivity } from '@services';
import { QueryStringValue } from '@proliance-ai/design-system';

export const activityFilter = {
  page: 1,
  pageSize: 10,
  sortField: 'createdAt',
  sortDirection: 'desc'
};

const getActivity = (parameters: Record<string, QueryStringValue> = {}): Observable<Collection<IActivity>> => {
  parameters!.companyId = companyService.company$.value!.id;
  parameters!.type = 'USER';
  return activityApiService.getActivity(parameters);
};

export const activityService: IActivityService = {
  getActivity
};
