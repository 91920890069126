import { tokenService } from '@proliance-ai/design-system';
import { FunctionFactory, ISurvey, Question } from '@shared/surveyJs/reexport';

interface IAsyncValidator {
  func: (params: string[]) => void;
  question: Question;
  returnResult: (result?: boolean) => void;
  survey: ISurvey;
}

const acceptableContextList = [ 'ceo', 'dpo' ];

function isEmailAvailable(this: IAsyncValidator, params: string[]): void {
  if (params.length < 1) {
    this.returnResult();
  }
  const [ email, context = acceptableContextList[0] ] = params;
  if (!email) {
    this.returnResult();
  }
  if (!acceptableContextList.includes(context.toLowerCase())) {
    console.error(`Context value ${ context } is not supported!`);
    this.returnResult();
  }
  const url = `/api/survey/team/${ context.toLowerCase() }/email-availability?email=${ encodeURIComponent(email) }`;
  const method = 'GET';
  const headers = { Authorization: `Bearer ${ tokenService.getToken() }` };
  fetch(
    url,
    {
      headers,
      method
    }
  )
    .then((response: Response) => response.json())
    .then((allowed: boolean) => {
      this.returnResult(allowed);
    });
}

FunctionFactory
  .Instance
  .register('isEmailAvailable', isEmailAvailable, true);
