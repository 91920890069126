// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-Header_Tv9rE{width:100%;height:64px;box-shadow:0 1px 8px 0 rgba(0,0,0,0.17);z-index:2;background-color:#fff}.header-Header_Tv9rE > div{height:4rem}.container-Header_nKXdd{box-sizing:border-box;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;padding-left:16px;-webkit-transition:padding .2s linear;transition:padding .2s linear}.container-Header_nKXdd.collapsed-Header_OFJ4G{padding-left:8px;padding-right:8px}.container-Header_nKXdd.collapsed-Header_OFJ4G .columnLeft-Header_SVvfS{-webkit-column-gap:24px;-moz-column-gap:24px;column-gap:24px}.columnLeft-Header_SVvfS{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;-webkit-column-gap:1rem;-moz-column-gap:1rem;column-gap:1rem;-webkit-transition:column-gap .2s linear;transition:column-gap .2s linear}.columnRight-Header_riefH{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;margin-left:auto;-webkit-column-gap:.5rem;-moz-column-gap:.5rem;column-gap:.5rem}.divider-Header_LrzJD{height:30px;border-left:1px solid;border-color:#1b143f;overflow:hidden;-webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)\";filter:alpha(opacity=50);opacity:.5}.divider-Header_LrzJD.wide-Header_FQ3b8{margin:0 -8px 0 8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header-Header_Tv9rE",
	"container": "container-Header_nKXdd",
	"collapsed": "collapsed-Header_OFJ4G",
	"columnLeft": "columnLeft-Header_SVvfS",
	"columnRight": "columnRight-Header_riefH",
	"divider": "divider-Header_LrzJD",
	"wide": "wide-Header_FQ3b8"
};
export default ___CSS_LOADER_EXPORT___;
