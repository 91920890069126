import { ComponentPermissionData } from '@proliance-ai/typings';
import { RouteListType } from '@router/configuration';
import { permissionService } from '@services';
import { isEmpty } from '@utilities';

export const getDynamicRoutePermission = (routeList: RouteListType[]): ComponentPermissionData =>
  routeList.reduce((result: ComponentPermissionData, route: RouteListType): ComponentPermissionData => {
    if (!isEmpty(result)) {
      return result;
    }
    return permissionService.isPageAvailable(route)
      ? permissionService.getComponentsPermission(route)
      : result;
  }, {});
