// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-ErrorFactory_NrXts{position:relative;min-height:15px;margin:5px 20px;font-size:12px;line-height:12px;letter-spacing:.26px;word-break:break-word;color:#bc1e32}.error-ErrorFactory_NrXts:first-letter{text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "error-ErrorFactory_NrXts"
};
export default ___CSS_LOADER_EXPORT___;
