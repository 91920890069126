import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseModalButton, ModalOptions, modalService } from '@proliance-ai/react-ui';
import { Company } from '@services';
import { CompanySwitcherButton } from '../../CompanySwitcherButton';
import { ProductSwitcherModalContent } from '../components';

export type UseSwitcherModalOptions = {
  id: string;
  currentCompany: null | Company;
  isMultiProduct: null | boolean;
  isMultitenancy: null | boolean;
};

export type UseProductSwitcherModalReturn = {
  modalOptions: ModalOptions;
};

export type UseProductSwitcherModalOptions = (options: UseSwitcherModalOptions) => UseProductSwitcherModalReturn;

export const useProductSwitcherModalOptions: UseProductSwitcherModalOptions = (options: UseSwitcherModalOptions): UseProductSwitcherModalReturn => {
  const {
    id,
    currentCompany,
    isMultiProduct,
    isMultitenancy
  } = options;

  useEffect((): void => {
    modalService.patchModalOptions({
      id,
      footer: {
        actionsElement: getActionsElement(),
        secondaryActionsElement: getSecondaryActionsElement()
      }
    });
  }, [ options ]);

  const { t } = useTranslation();

  const getActionsElement = () => (
    <CloseModalButton
      dataAttributesDictionary={ {
        test: { 'product-switcher': 'close' },
        guide: { 'product-switcher': 'close' }
      } }
    />
  );

  const getSecondaryActionsElement = () => (
    <CompanySwitcherButton
      isModalActionButton={ true }
      currentCompany={ currentCompany }
      isMultiProduct={ isMultiProduct }
      isMultitenancy={ isMultitenancy }
    />
  );

  const modalOptions: ModalOptions = {
    id,
    size: 'large',
    dataAttributesDictionary: {
      test: { 'product-switcher': 'modal' },
      guide: { 'product-switcher': 'modal' }
    },
    heading: {
      title: t('products.title.select')
    },
    content: {
      contentElement: <ProductSwitcherModalContent options={ options }/>,
      usePadding: false
    },
    footer: {
      actionsElement: getActionsElement(),
      secondaryActionsElement: getSecondaryActionsElement()
    }
  };

  return { modalOptions };
};
