import i18n from 'i18next';
import { sort, sortArray } from '@proliance-ai/utilities';
import { ColumnMappingType, ISortingItem, Sorter } from '../useAccordionState.typings';

function sorter<E, S extends ISortingItem<C>, C>(
  a: E,
  b: E,
  sorting: S[],
  level: number = 0,
  getValue: ColumnMappingType<E, C>
): number {
  const levelSorting: undefined | S = sorting[level];
  if (typeof levelSorting === 'undefined') {
    return 0;
  }
  const { column, sortDirection } = levelSorting;
  if (!sortDirection) {
    return sorter(a, b, sorting, level + 1, getValue);
  }
  const direction = sortDirection === 'desc'
    ? 1
    : -1;
  const aValue = getValue(a, column);
  const bValue = getValue(b, column);
  if (Array.isArray(aValue) && Array.isArray(bValue)) {
    return sortArray(aValue, bValue, direction);
  }
  if (Array.isArray(aValue) || Array.isArray(bValue)) {
    return sorter(a, b, sorting, level + 1, getValue);
  }
  const comparison = sort(aValue, bValue, direction, i18n.language);
  if (comparison !== 0) {
    return comparison * direction;
  }
  return sorter(a, b, sorting, level + 1, getValue);
}

export function getAccordionStateSorter<
  E,
  S extends ISortingItem<C>,
  C
>(columnMapping: ColumnMappingType<E, C>): Sorter<E, S> {
  return (a: E, b: E, sorting: S[]): number => sorter<E, S, C>(a, b, sorting, 0, columnMapping);
}
