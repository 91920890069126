import { defaultDropdownPageSize } from '@proliance-ai/design-system';
import { IProperty } from '../../interfaces';

const pageSizeProperty: IProperty = {
  name: 'pageSize',
  category: 'choices',
  type: 'number',
  default: defaultDropdownPageSize,
  visible: true
};

export default pageSizeProperty;
