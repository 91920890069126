import * as yup from 'yup';
import { file } from '@proliance-ai/utilities';

export const schema = yup
  .object()
  .shape({
    type: yup.string().nullable().required('chooseOption'),
    topic: yup.string().nullable()
      .when(
        'type',
        {
          is: 'CONSULTING_REQUEST',
          then: (currentSchema) => currentSchema.required('chooseOption')
        }
      ),
    message: yup.string().nullable().required('required'),
    attachments: file()
      .acceptedTypes([ 'JP(E)G', 'PNG', 'WEBP', 'TXT', 'PDF', 'DOC(X)' ])
      .maxUploadSize(20)
  });
