import { TranslationPlural } from '@proliance-ai/typings';
import {
  JsonObject,
  SurveyValidator,
  ValidatorResult,
  surveyLocalization
} from '@shared/surveyJs/reexport';
import { FileUploadWidgetError, IFileUploadQuestion } from '@shared/surveyJs/widgets/FileUpload/fileUpload.typings';
import { getConvertedSize, megabytesToBytes, pluralize } from '@shared/surveyJs/widgets/FileUpload/utilities';

const validatorName = 'fileuploadwidgetvalidator';
export const setupFileUploadWidgetValidator = (): void => {
  class FileUploadWidgetValidator extends SurveyValidator {
    public get question(): IFileUploadQuestion {
      return this.errorOwner as unknown as IFileUploadQuestion;
    }
    /**
     * The minCount property.
     */
    public get minCount(): number {
      return this.question.minFileCount
        ? +this.question.minFileCount
        : 0;
    }
    /**
     * The maxCount property.
     */
    public get maxCount(): number {
      return this.question.maxFileCount
        ? +this.question.maxFileCount
        : 0;
    }
    /**
     * The maxSize property.
     */
    public get maxSize(): number {
      const propertyValue = this.question.maxSize || this.question.maxFileSize;
      return propertyValue
        ? megabytesToBytes(+propertyValue)
        : 0;
    }

    constructor() {
      super();
    }

    public getType(): string {
      return validatorName;
    }

    public validate(
      value: any,
      _name?: string,
      _values?: any,
      _properties?: any
    ): ValidatorResult {
      const locale = this.getLocale() || 'en';
      const errorOwner = this.errorOwner as unknown as IFileUploadQuestion;
      const result = new ValidatorResult(value);
      const getCurrentLocale = () => locale;
      const getLocalization = (_question: IFileUploadQuestion, key: string) => surveyLocalization.locales[locale][key];
      const getPluralLocalization = (_question: IFileUploadQuestion, key: string) => surveyLocalization.locales[locale][key];
      const errorList = errorOwner.errorList || [];
      if (this.minCount && errorList.includes(FileUploadWidgetError.MIN)) {
        result.error = this
          .createCustomError(surveyLocalization
            .getString('minFileCountErrorText', locale)
            .format(`${ this.minCount } ${ pluralize(
              this.minCount,
              'file',
              errorOwner,
              getCurrentLocale,
              getLocalization as (question: IFileUploadQuestion, key: string) => TranslationPlural
            ) }`
            ));
        return result;
      }
      if (this.maxCount && errorList.includes(FileUploadWidgetError.MAX)) {
        result.error = this
          .createCustomError(surveyLocalization
            .getString('maxFileCountErrorText', locale)
            .format(`${ this.maxCount } ${ pluralize(
              this.maxCount,
              'file',
              errorOwner,
              getCurrentLocale,
              getPluralLocalization
            ) }`
            ));
        return result;
      }
      if (errorList.includes(FileUploadWidgetError.OVERSIZE)) {
        const maxSizeString = getConvertedSize(
          this.maxSize,
          errorOwner,
          getLocalization
        );
        result.error = this
          .createCustomError(surveyLocalization
            .getString('maxFileSizeErrorText', locale)
            .format(
              pluralize(
                errorOwner.fileCount,
                'file',
                errorOwner,
                getCurrentLocale,
                getPluralLocalization
              ),
              maxSizeString
            )
          );
        return result;
      }
      if (errorList.includes(FileUploadWidgetError.HAS_ERRORS)) {
        result.error = this
          .createCustomError(surveyLocalization
            .getString('hasErrors', locale));
        return result;
      }
      if (errorOwner.isUploadingFiles) {
        result.error = this
          .createCustomError(surveyLocalization
            .getString('filesLoading', locale));
        return result;
      }
      if (errorList.includes(FileUploadWidgetError.REQUIRED)) {
        result.error = this
          .createCustomError(surveyLocalization
            .getString('required', locale));
        return result;
      }
      return result;
    }

    protected getErrorText(text: string): string {
      return this.text || this.getDefaultErrorText(text);
    }

    protected getDefaultErrorText(text: string) {
      return text;
    }
  }

  (window as any).FileUploadWidgetValidator = FileUploadWidgetValidator;

  JsonObject.metaData.addClass(
    validatorName,
    [],
    () => new FileUploadWidgetValidator(),
    'surveyvalidator'
  );
};
