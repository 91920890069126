import { State } from 'router5';
import {
  configurationService,
  leadingCharacter
} from '@proliance-ai/design-system';
import { notificationService, storageService } from '@proliance-ai/react-ui';
import {
  checkPathPermission,
  getPageType,
  matchUrl
} from '@router';
import { IRouteState, PageType } from '../interfaces';

export const redirectUrnParameterName = configurationService.getRedirectUrnParameterName();
export const redirectUrnStorageName = 'redirectData';
export const redirectParameterName = 'redirected';

export const getStorageRedirectData = (key: string): null | string => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData === null) {
    return null;
  }
  return typeof storageData[key] === 'undefined'
    ? null
    : leadingCharacter(storageData[key], '/');
};

export const setStorageRedirectData = (key: string, value: string): void => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData !== null) {
    storageService.set(redirectUrnStorageName, { ...storageData, [key]: value });
  } else {
    storageService.set(redirectUrnStorageName, { [key]: value });
  }
};

export const removeStorageRedirectData = (key: string): void => {
  const storageData = storageService.get<Record<string, string>>(redirectUrnStorageName);
  if (storageData !== null) {
    delete storageData[key];
    storageService.set(redirectUrnStorageName, storageData);
  }
};

export const getRouteData = (urn: string | null): null | State => urn === null
  ? null
  : matchUrl(urn);

export const getPath = (path: string): string => path.split('?')[0];

export const redirectMiddleware = () => (toState: State, fromState: State): Promise<boolean> => {
  const { isRouted, params, path, redirected } = toState as IRouteState;
  if (isRouted && (toState as IRouteState)[redirectParameterName] === false && !redirected) {
    const routePath = getPath(fromState.path);
    const redirectUrn = getStorageRedirectData(routePath);
    const redirectData = getRouteData(redirectUrn);
    if (redirectUrn && redirectData) {
      removeStorageRedirectData(routePath);
      if (!checkPathPermission(redirectUrn)) {
        const dataAttributesDictionary = {
          test: { notificationError: 'notAllowedPage' },
          guide: { notificationError: 'notAllowedPage' }
        };
        notificationService.error({
          textTranslationKey: 'notAllowedPage',
          dataAttributesDictionary
        });
      }
      redirectData.params[redirectParameterName] = true;
      return Promise.reject({ redirect: redirectData });
    }
  }
  const redirectParameter = params[redirectUrnParameterName];
  if (typeof redirectParameter !== 'undefined' && !redirected) {
    const redirectPath = leadingCharacter(getPath(redirectParameter), '/');
    const targetState = getRouteData(redirectPath);
    if (targetState !== null && getPageType(targetState.name) !== PageType.public) {
      setStorageRedirectData(getPath(path), redirectParameter);
    }
  }
  return Promise.resolve(true);
};
