import $ from 'jquery';
import {
  AsYouType,
  CountryCode
} from 'libphonenumber-js';
import { DataFormat, DataParams, Event } from 'select2';
import { createRef, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { resizeObserver } from '@proliance-ai/design-system';
import { getCallingCode, getCountryCodeDropdownSettings, getCountryList } from './utilities';
import { addClass, setClass } from '../common';
import { WidgetAbstractClass } from '../widget.abstract.class';
import {
  IProperty,
  IPropertyChangeOption,
  WidgetEditorLocalization,
  WidgetLocalization
} from '../../widgets/interfaces';
import { Question } from '@shared/surveyJs/reexport';

import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import './styles.styl';

export interface IAsYouTypeState {
  callingCode?: string;
  digits: string;
  nationalSignificantNumber: string;
  nationalPrefix: string;
}

export interface IPhoneWidgetValue {
  countryDialCode: string;
  phoneNumber: string;
}

export interface IQuestionPhone extends Question {
  defaultValue: undefined | IPhoneWidgetValue;
  viewValue: IPhoneWidgetValue;
  value: undefined | IPhoneWidgetValue;
  id: string;
}

export default class PhoneWidget extends WidgetAbstractClass<IQuestionPhone> {
  public customPropertiesList: IProperty[] = [
    {
      name: 'viewValue',
      visible: false,
      default: {
        countryDialCode: '',
        phoneNumber: ''
      }
    }
  ];

  public hidePropertyNameList: string[] = [
    'autocompleteAttr',
    'nameAttr',
    'requiredIf',
    'hideNumber',
    'titleLocation',
    'descriptionLocation',
    'valueName',
    'useDisplayValuesInTitle',
    'correctAnswer'
  ];

  public surveyLocalization: WidgetLocalization = {
    phoneErrorText: {
      en: 'Invalid phone number',
      de: 'Ungültige Telefonnummer'
    }
  };

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Phone',
        de: 'Telefon'
      }
    },
    validators: {
      phonevalidator: {
        en: 'phone',
        de: 'telefon'
      }
    },
    p: {
      phoneErrorText: {
        en: 'Phone error text',
        de: 'Telefonfehlertext'
      }
    },
    pe: {
      phoneErrorText: {
        en: 'Invalid phone number',
        de: 'Ungültige Telefonnummer'
      }
    }
  };

  public observer = createRef<ResizeObserver>();
  public elementRef: RefObject<HTMLDivElement> = createRef();

  protected alternativeNameList: string[] = [ 'dsephonenumber' ];

  protected classNameDictionary: Record<string, string> = {
    wrapper: 'phone-widget-wrap',
    label: 'plus',
    select: 'phone-country',
    input: 'phone-number',
    focus: 'focus',
    disabled: 'disabled'
  };

  private onlyNumberRegexp: RegExp = /\D/g;

  private readonly emptyValue = {
    countryDialCode: '',
    phoneNumber: ''
  };

  private selectionStart: null | number = null;
  private selectionEnd: null | number = null;
  private nonDigitCharacters: number = 0;

  constructor() {
    super({
      name: 'phone',
      iconName: 'phone',
      icon: faPhone
    });
  }

  public renderer(question: IQuestionPhone, element: HTMLElement): void {
    const wrapper = document.createElement('div');
    addClass(wrapper, this.classNameDictionary.wrapper);
    setClass(wrapper, this.classNameDictionary.disabled, question.isReadOnly);
    element.appendChild(wrapper);
    const select = document.createElement('select');
    addClass(select, this.classNameDictionary.select);
    wrapper.appendChild(select);
    const label = document.createElement('label');
    addClass(label, this.classNameDictionary.label);
    wrapper.appendChild(label);
    const input = document.createElement('input');
    input.type = 'tel';
    addClass(input, this.classNameDictionary.input);
    wrapper.appendChild(input);
  }

  public afterRender(
    question: IQuestionPhone,
    element: HTMLElement
  ): void {
    super.afterRender(question, element);

    this.addPhoneNumberValidation(question);

    const label = this.getElement('label', element);
    if (!label) {
      console.error('Element "label" not found');
      return;
    }

    const input = this.getElement('input', element);
    if (!input) {
      console.error('Element "input" not found');
      return;
    }

    const id = `phone-${ question.id }`;
    label.setAttribute('for', id);
    input.setAttribute('id', id);

    label.innerText = '+';

    const currentLocale = this.getCurrentLocale(question);
    getCountryList(currentLocale)
      .subscribe((countryList: DataFormat[]) => {
        this.initializeDropdown(
          element,
          question,
          countryList
        );

        this.initializeInput(
          element,
          question
        );

        if (question.value) {
          question.viewValue = question.value;
        }
        this.setValue(question, element);
      });

    const observer = resizeObserver(
      () => this.setDropdownWidth(element)
    );
    if (element !== null) {
      observer.observe(document.body);
    } else {
      observer.disconnect();
    }
  }

  public setDropdownWidth = (element: HTMLElement): void => {
    if (!element) {
      return;
    }
    const component = element.querySelector(
      '.phone-widget-wrap'
    ) as HTMLElement;
    if (!component) {
      return;
    }
    const dropdown = document.querySelector(
      '.dse-phone-dropdown-dropdown'
    )?.closest('.select2-container') as HTMLElement;

    const { width } = component.getBoundingClientRect();

    if (dropdown && width) {
      dropdown.style.width = `${ width }px`;
    }
  };

  public willUnmount(question: IQuestionPhone, element: HTMLElement): void {
    setTimeout(() => {
      const select = this.getElement('select', element);
      if (!select) {
        console.error('Element "select" not found');
        return;
      }
      const $select = $(select as HTMLSelectElement);

      if ($(element).data('select2')) {
        $select.off().select2('destroy');
      }

      const input = this.getElement('input', element);
      if (!input) {
        console.error('Element "input" not found');
        return;
      }
      const $input = $(input as HTMLInputElement);
      $input.off();
      super.willUnmount(question, element);
    });
  }

  protected onPropertyChangedHandler(
    question: IQuestionPhone,
    element: HTMLElement,
    options: IPropertyChangeOption<any>
  ): void {
    super.onPropertyChangedHandler(question, element, options);
    if (options.name === 'defaultValue') {
      question.viewValue = question.defaultValue ?? this.emptyValue;
      this.setValue(question, element);
    }
  }

  // @ts-ignore
  protected onChangeReadOnly(
    _question: IQuestionPhone,
    element: HTMLElement,
    options: IPropertyChangeOption<boolean>
  ): void {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = $(select as HTMLSelectElement);
    $select.prop('disabled', options.newValue);
    const input = this.getElement('input', element);
    if (!input) {
      console.error('Element "input" not found');
      return;
    }
    const $input = $(input as HTMLInputElement);
    $input.prop('disabled', options.newValue);

    const wrapper = this.getElement('wrapper', element);
    if (!wrapper) {
      console.error('Element "wrapper" not found');
      return;
    }
    setClass(wrapper, this.classNameDictionary.disabled, options.newValue);
  }

  private initializeDropdown(
    element: HTMLElement,
    question: IQuestionPhone,
    data: DataFormat[]
  ): void {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    const $select = $(select as HTMLSelectElement);
    const locale = this.getCurrentLocale(question);
    $select.select2({
      ...getCountryCodeDropdownSettings($select, locale),
      disabled: question.isReadOnly,
      data
    });

    const wrapper = this.getElement('wrapper', element);
    if (!wrapper) {
      console.error('Element "wrapper" not found');
      return;
    }
    $select.on('select2:open', () => {
      this.setDropdownWidth(element);
      setClass(wrapper, this.classNameDictionary.focus, true);
    });
    $select.on('select2:close', () => setClass(wrapper, this.classNameDictionary.focus, false));
    $select.on('select2:select', (event: Event<HTMLElement, DataParams>) => {
      this.updateValue(element, question, event);
    });
  }

  private initializeInput(
    element: HTMLElement,
    question: IQuestionPhone
  ): void {
    const input = this.getElement('input', element);
    if (!input) {
      console.error('Element "input" not found');
      return;
    }
    const $input = $(input as HTMLInputElement);
    $input.prop('disabled', question.isReadOnly);

    const wrapper = this.getElement('wrapper', element);
    if (!wrapper) {
      console.error('Element "wrapper" not found');
      return;
    }
    $input.on('focus', () => setClass(wrapper, this.classNameDictionary.focus, true));
    $input.on('blur', () => setClass(wrapper, this.classNameDictionary.focus, false));

    $input.on('input', (event: JQuery.TriggeredEvent<HTMLInputElement>) => {
      this.updateValue(element, question, event);
    });
  }

  private getAsYouType(value: string): AsYouType {
    const asYouType = new AsYouType();
    asYouType.reset();
    asYouType.input(`+${ value }`);
    return asYouType;
  }

  private getCountry = (asYouType: AsYouType): undefined | CountryCode => {
    if (asYouType.country) {
      return asYouType.country;
    }
    if ((asYouType as any).state.callingCode) {
      return (asYouType as any).metadata.metadata.country_calling_codes[(asYouType as any).state.callingCode][0];
    }
    return undefined;
  };

  private updateValue(
    element: HTMLElement,
    question: IQuestionPhone,
    event: JQuery.TriggeredEvent<HTMLInputElement> | Event<HTMLElement, DataParams>
  ): void {
    if (event.currentTarget === null) {
      return;
    }

    if (event.type === 'input') {
      const removePrefix = (stringValue: string, prefix: string): string => stringValue.startsWith(prefix)
        ? stringValue.slice(prefix.length)
        : stringValue;
      this.selectionStart = event.currentTarget.selectionStart || null;
      this.selectionEnd = event.currentTarget.selectionEnd || null;
      this.nonDigitCharacters = event.currentTarget.value
        .substring(0, event.currentTarget.selectionStart || 0)
        .replace(/[^\D]/g, '').length
        || 0;
      const value = event.currentTarget.value
        .replace(this.onlyNumberRegexp, '');
      const asYouType = this.getAsYouType(value);
      const state: IAsYouTypeState = (asYouType as any).state;
      const countryDialCode = state.callingCode ?? state.digits;
      const phoneNumber = removePrefix(state.digits, countryDialCode);

      question.viewValue = {
        countryDialCode,
        phoneNumber
      };
      this.setDropdownValue(element, this.getCountry(asYouType));
    } else if (event.type === 'select2:select') {
      const countryCode = (event as Event<HTMLElement, DataParams>).params.data.id as CountryCode;
      const countryDialCode = getCallingCode(countryCode);

      question.viewValue = {
        ...question.viewValue,
        countryDialCode
      };
    }

    this.setInputValue(element, question.viewValue);
    this.updateQuestionValue(question, question.viewValue);
  }

  private setInputValue(
    element: HTMLElement,
    value?: IPhoneWidgetValue
  ): void {
    const input = this.getElement('input', element) as HTMLInputElement;
    if (!input) {
      console.error('Element "input" not found');
      return;
    }
    const formattedPhoneNumber = (this.getAsYouType(this.getStringFromPhoneData(value)) as any).formattedOutput.replace('+', '');
    $(input).val(formattedPhoneNumber);

    if (this.selectionStart && this.selectionEnd && input.selectionStart !== this.selectionStart) {
      const newNonDigitCharacters = input.value
        .substring(0, input.selectionStart || 0)
        .replace(/[^\D]/g, '').length
        || 0;
      const delta = newNonDigitCharacters - this.nonDigitCharacters;
      input.selectionStart = this.selectionStart + delta;
      input.selectionEnd = this.selectionEnd + delta;
    }
  }

  private setDropdownValue(
    element: HTMLElement,
    value?: CountryCode
  ) {
    const select = this.getElement('select', element);
    if (!select) {
      console.error('Element "select" not found');
      return;
    }
    $(select).val(value ?? '').trigger('change');
  }

  private setValue(question: IQuestionPhone, element: HTMLElement): void {
    this.updateQuestionValue(question, question.viewValue);
    const asYouType = this.getAsYouType(this.getStringFromPhoneData(question.viewValue));
    this.setDropdownValue(element, this.getCountry(asYouType));
    this.setInputValue(element, question.viewValue);
  }

  private isDefaultValueWidget = (question: IQuestionPhone): boolean => question.name === 'question';

  private updateQuestionValue(question: IQuestionPhone, value?: IPhoneWidgetValue): void {
    const stringValue: string = this.getStringFromPhoneData(value);
    if (
      (stringValue === this.getStringFromPhoneData(question.defaultValue) || stringValue === this.getStringFromPhoneData(this.emptyValue))
      && !this.isDefaultValueWidget(question)
    ) {
      question.value = undefined;
    } else {
      question.value = value;
    }
  }

  private addPhoneNumberValidation(question: IQuestionPhone): void {
    this.addValidator(question, 'PhoneValidator', 'phonevalidator');
  }

  private getStringFromPhoneData(value?: null | IPhoneWidgetValue): string {
    return typeof value === 'undefined' || value === null
      ? ''
      : `${ value.countryDialCode ?? '' }${ value.phoneNumber ?? '' }`;
  }
}
