import { ComponentListType, ComponentPermissionData, IPermission, PermissionType } from '@proliance-ai/typings';

const checkPermission = (name: ComponentListType | ComponentListType[], permission?: ComponentPermissionData, permissionType: PermissionType = 'read'): boolean => {
  if (!permission) {
    return false;
  }
  const nameList = Array.isArray(name)
    ? name
    : [ name ];
  return nameList
    .map((component: ComponentListType) => {
      const componentPermission: undefined | IPermission = permission[component];
      if (!componentPermission) {
        return false;
      }
      return componentPermission[permissionType];
    })
    .some(Boolean);
};

export default checkPermission;
