import { of, Subscription } from 'rxjs';
import { dictionaryService } from '../../compatibility';
import { catchError, take } from 'rxjs/operators';
import { Collection } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { IAjaxData } from '../utilities/ajaxData';
import { IQuestionDropdown } from '../dropdown.widget';
import apiListMapper from '../utilities/apiListMapper';

const transport = (question: IQuestionDropdown) => (
  ajaxSettings: JQueryAjaxSettings,
  success: any,
  failure: any
): Subscription | void => {
  if (ajaxSettings.url) {
    const { page, pageSize, search } = ajaxSettings.data as IAjaxData;
    const parameters = {
      page,
      pageSize,
      ...(search && { search })
    };
    return dictionaryService.apiRequest(ajaxSettings.url, parameters)
      .pipe(
        take(1),
        catchError(() => of(null))
      )
      .subscribe((response: Collection<IDictionaryResponse<string>> | null) => {
        if (response === null) {
          failure();
        } else {
          question.settings.data = apiListMapper(response.elements as any);
          success(response);
        }
      });
  } else {
    failure();
  }
};

export default transport;
