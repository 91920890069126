import { Observable } from 'rxjs';
import { CoursePricingRange, ProductPricing } from '@proliance-ai/typings';
import { Api } from '@services/api';

const apiUrl = '/api/survey/self-checkout';

export class SelfCheckoutApiService extends Api {
  public getCoursePriceList(companyCourseId: number): Observable<CoursePricingRange[]> {
    return this.get<CoursePricingRange[]>({
      url: `academy/${ companyCourseId }`
    });
  }

  public orderSalesforceLicenses(courseId: number, licenses: number, expectedPrice: number): Observable<null> {
    return this.post<null>({
      url: `academy/${ courseId }`,
      parameters: { licenses, expectedPrice }
    });
  }

  public getTeamPlusPrice(): Observable<ProductPricing> {
    return this.get<ProductPricing>({
      url: 'privacy/team-plus'
    });
  }

  public orderTeamPlus(expectedPrice: number): Observable<null> {
    return this.post<null>({
      url: 'privacy/team-plus',
      parameters: { expectedPrice }
    });
  }
}

export const selfCheckoutApiService = new SelfCheckoutApiService(apiUrl);
