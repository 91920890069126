import { Observable } from 'rxjs';
import { Collection, CollectionParameters } from '@proliance-ai/typings';
import { ITableState } from '@proliance-ai/design-system';
import { ISurveyData } from '@services/data/survey';

export enum DataBreachType {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export interface DataBreach {
  id: string;
  type: string;
  status: DataBreachType;
  awarenessAt: Date;
  createdAt: Date;
  closedAt: null | Date;
  createdFrom: undefined;
  createdTo: undefined;
  closedFrom: undefined;
  closedTo: undefined;
  awarenessFrom: undefined;
  awarenessTo: undefined;
}

export interface IDataBreachService {
  getDataBreachInitialState: (type: DataBreachType) => Partial<ITableState<DataBreach>>;
  getActiveDataBreachCount: () => Observable<number>;
  getDataBreachCollection: (parameters: CollectionParameters) => Observable<Collection<DataBreach>>;
  getDataBreach: (parameters?: Record<string, boolean | string>) => Observable<ISurveyData>;
  updateDataBreachStatus: (id: string, status: DataBreachType) => Observable<Observable<ISurveyData>>;
  deleteDataBreach: (id: string) => Observable<void>;
}
