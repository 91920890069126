import { Observable } from 'rxjs';
import { Collection } from '@proliance-ai/typings';
import { SystemsApiUrl, SystemsEntity, SystemsStatistics } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey';

class SystemsApiService extends Api {
  public getSystemsStatistics(): Observable<SystemsStatistics> {
    return this.get<SystemsStatistics>({ url: 'company-data/system-count' });
  }

  public getSystemsByType(url: SystemsApiUrl, parameters = {}): Observable<Collection<SystemsEntity>> {
    return this.get<Collection<SystemsEntity>>({
      url: `system/${ url }`,
      parameters
    });
  }
}

export const systemsApiService = new SystemsApiService(apiUrl);
