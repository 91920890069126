import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { DataStorageCloudHosted, DataStorageThirdPartyHosted, SystemsType } from '@services';
import { Header, ValueCell } from '@proliance-ai/design-system';

const getDataStorageThirdPartyHostedColumnList = (
  t: TFunction,
  systemsType: SystemsType
): Array<Column<DataStorageThirdPartyHosted>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'serviceProvider',
    accessor: (originalRow: DataStorageCloudHosted) => originalRow.serviceProvider.title,
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  }
];

export default getDataStorageThirdPartyHostedColumnList;
