import { DataStorageFormKey } from '@proliance-ai/typings';

export const dataStorageSteps: DataStorageFormKey[] = [
  'management',
  'type',
  'operators',
  'dataTypes',
  'description',
  'accesses',
  'rpaAsController'
];
