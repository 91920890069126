import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { parseQueryParameters } from '@proliance-ai/design-system';
import { leadingCharacter } from '@proliance-ai/utilities';
import {
  companyIdParameterName,
  emailParameterName,
  errorParameterName,
  productParameterName,
  redirectToLobby,
  redirectUrnParameterName
} from '@router';
import { productService } from '@services';
import { IRouteState, PageType } from '../interfaces';

const check = (toState: IRouteState): boolean | Promise<boolean> => {
  if (!toState.isLoggedIn) {
    const redirectUrn = leadingCharacter(toState.path, '/', false);
    const companyId = toState.params[companyIdParameterName];
    const emailParameter = toState.params[emailParameterName];
    const email = emailParameter
      ? decodeURIComponent(emailParameter)
      : emailParameter;
    const product = productService.getCurrentProduct()!;
    const parameters = {
      ...parseQueryParameters(),
      ...(email && { [emailParameterName]: encodeURIComponent(email) }),
      [companyIdParameterName]: companyId,
      [productParameterName]: product,
      [redirectUrnParameterName]: redirectUrn,
      [errorParameterName]: 401
    };
    redirectToLobby({ parameters });
    return Promise.reject();
  }
  return true;
};

export const authorizationGuard: ActivationFnFactory = (): ActivationFn =>
  (toState: State): boolean | Promise<boolean> => {
    const { error, pageType } = toState as IRouteState;
    if (error || pageType !== PageType.private) {
      return true;
    }
    return check(toState as IRouteState);
  };
