// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr{box-sizing:border-box;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;border-radius:50%}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr.impersonated-CardAvatar_CV2VP:before{content:'';position:absolute;top:0;right:0;bottom:0;left:-4px;width:123.53%;height:123.53%;padding:2px;box-sizing:border-box;border:2px solid #38a7d7;border-radius:50%;margin:auto}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr.inactive-CardAvatar_n0WPk{background-color:#e0e0e0}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr.inactive-CardAvatar_n0WPk .initials-CardAvatar_hcjtj{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)\";filter:alpha(opacity=50);opacity:.5}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr.inactive-CardAvatar_n0WPk .placeholder-CardAvatar_i4_xI{color:#828282;font-size:175%;font-weight:500}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr.inactive-CardAvatar_n0WPk .icon-CardAvatar_QcQdN{width:24px;height:24px}.wrap-CardAvatar_VBCa5 .avatar-CardAvatar_cNycr .initials-CardAvatar_hcjtj{color:#fff;font-weight:500;letter-spacing:.1em;text-indent:.1em;line-height:0;font-size:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-CardAvatar_VBCa5",
	"avatar": "avatar-CardAvatar_cNycr",
	"impersonated": "impersonated-CardAvatar_CV2VP",
	"inactive": "inactive-CardAvatar_n0WPk",
	"initials": "initials-CardAvatar_hcjtj",
	"placeholder": "placeholder-CardAvatar_i4_xI",
	"icon": "icon-CardAvatar_QcQdN"
};
export default ___CSS_LOADER_EXPORT___;
