// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productSelect-ProductSwitcherModalContent_KvMb8{margin:0;padding:1.5rem 0 0;padding:1.5rem 1.5rem 20px}.productSelect-ProductSwitcherModalContent_KvMb8 .list-ProductSwitcherModalContent_jqHvv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:.5rem;padding-bottom:1.5rem}.productSelect-ProductSwitcherModalContent_KvMb8 .list-ProductSwitcherModalContent_jqHvv:last-of-type{padding-bottom:0}.productSelect-ProductSwitcherModalContent_KvMb8 .title-ProductSwitcherModalContent_jZQzu{color:#1b143f;font-size:18px;font-weight:500;line-height:22px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productSelect": "productSelect-ProductSwitcherModalContent_KvMb8",
	"list": "list-ProductSwitcherModalContent_jqHvv",
	"title": "title-ProductSwitcherModalContent_jZQzu"
};
export default ___CSS_LOADER_EXPORT___;
