import { Priority } from '@proliance-ai/typings';
import { AssetKey, AssetTypeKey, DataStorageKey, GetPriority, SoftwareKey, ThirdPartyKey } from '../assetManagement.typings';
import { dataStoragePriorityMapping, softwarePriorityMapping, thirdPartyPriorityMapping } from '../data';

const getPriorityValue = (value?: Priority): Priority => typeof value !== 'undefined'
  ? value
  : Priority.OPTIONAL;

export const getPriority: GetPriority = (type: AssetTypeKey, key: AssetKey): Priority => {
  switch (type) {
    case 'ThirdParty':
      return getPriorityValue(thirdPartyPriorityMapping[key as ThirdPartyKey]);
    case 'DataStorage':
      return getPriorityValue(dataStoragePriorityMapping[key as DataStorageKey]);
    case 'Software':
      return getPriorityValue(softwarePriorityMapping[key as SoftwareKey]);
    default:
      return Priority.OPTIONAL;
  }
};

