import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { storageService } from '@proliance-ai/react-ui';
import { getIsRouteInProduct, getProductByRouteName as getProductByRouteNameUtility } from '@proliance-ai/utilities';
import { getKey } from '@router';
import { RouteListType } from '@router/configuration';
import {
  ProductData, ProductEnumType,
  ProductType
} from '@proliance-ai/typings';
import { ProductService } from './product.typings';
import { productApiService } from '@services/api';

const productStorageName = 'product';
const lastProductStorageName = 'lastProduct';

export const getProductStorageName = (): string => getKey(productStorageName);
export const getLastProductStorageName = (): string => getKey(lastProductStorageName);
export const productList: ProductType[] = [ 'academy', 'privacy', 'whistle', 'isms' ];

const productDataSubject$ = new BehaviorSubject<null | ProductData>(null);
const productData$ = productDataSubject$.asObservable();

const currentProductSubject$ = new BehaviorSubject<null | ProductType>(null);
const currentProduct$ = currentProductSubject$.asObservable();
const getLastProduct = (): null | ProductType => storageService.get(getLastProductStorageName()) as null | ProductType;
const updateProduct = (): void => {
  const sessionStorageProductValue = sessionStorage.getItem(getProductStorageName()) as null | ProductType;
  const localStorageProductValue = getLastProduct();
  currentProductSubject$.next(sessionStorageProductValue || localStorageProductValue);
};
const getCurrentProduct = (): null | ProductType => currentProductSubject$.value;
const setCurrentProduct = (product: ProductType): void => {
  const currentProduct = getCurrentProduct();
  if ([ 'whistle', 'isms' ].some((productType: string): boolean => productType !== currentProduct && productType === product)) {
    storageService.set(getLastProductStorageName(), currentProduct);
  }
  sessionStorage.setItem(getProductStorageName(), product);
  currentProductSubject$.next(product);
};
const getProductByRouteName = (routeName: RouteListType): ProductType => getProductByRouteNameUtility(routeName, productDataSubject$.value!, currentProductSubject$.value);
const isRouteInProduct = (
  routeName: RouteListType,
  product: ProductType
): boolean => getIsRouteInProduct(routeName, product);
const setCurrentProductByRouteName = (routeName: RouteListType): void => setCurrentProduct(getProductByRouteName(routeName));

const getProductsData = (suppressDefaultErrorHandler?: boolean | number[]): Observable<void> => productApiService
  .getProductsData(suppressDefaultErrorHandler)
  .pipe(
    map((productData: ProductData): void => {
      productDataSubject$.next(productData);
    })
  );
const productsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(Object.entries(productData))
  : {};
const getProducts = (): ProductData => productsMapper(productDataSubject$.value);

const productsCountMapper = (productData: null | ProductData): number => Object.keys(productsMapper(productData)).length;
const getProductsCount = (): number => productsCountMapper(productDataSubject$.value);
const productsCount$ = productData$.pipe(map(productsCountMapper));

const isSingleProductMapper = (productData: null | ProductData): boolean => !!productData && Object.keys(productData).length === 1;
const getIsSingleProduct = () => isSingleProductMapper(productDataSubject$.value);
const isSingleProduct$ = productData$.pipe(map(isSingleProductMapper));

const singleProductNameMapper = (productData: null | ProductData): false | ProductEnumType => !!productData && Object.keys(productData).length === 1 && Object.keys(productData)[0] as ProductEnumType;
const getSingleProductName = (): false | ProductEnumType => singleProductNameMapper(productDataSubject$.value);
const singleProductName$ = productData$
  .pipe(
    map(getSingleProductName)
  );

const isMultiProductMapper = (productData: null | ProductData): boolean => !!productData && Object.keys(productData).length > 1;
const getIsMultiProduct = () => isMultiProductMapper(productDataSubject$.value);
const isMultiProduct$ = productData$
  .pipe(
    map(isMultiProductMapper)
  );

const internalProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => !value.external)
  )
  : {};
const getInternalProducts = (): ProductData => internalProductsMapper(productDataSubject$.value);
const internalProducts$ = productData$
  .pipe(
    map(internalProductsMapper)
  );

const internalProductsCountMapper = (productData: null | ProductData): number => Object.keys(internalProductsMapper(productData)).length;
const getInternalProductsCount = (): number => internalProductsCountMapper(productDataSubject$.value);

const singleInternalProductNameMapper = (productData: null | ProductData): false | ProductEnumType => isSingleProductMapper(internalProductsMapper(productData)) && Object.keys(internalProductsMapper(productData))[0] as ProductEnumType;
const getSingleInternalProductName = (): false | ProductEnumType => singleInternalProductNameMapper(productDataSubject$.value);
const singleInternalProductName$ = productData$
  .pipe(
    map(singleInternalProductNameMapper)
  );

const ownedProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => value.owned)
  )
  : {};
const getOwnedProducts = (): ProductData => ownedProductsMapper(productDataSubject$.value);
const ownedProducts$ = productData$
  .pipe(
    map(ownedProductsMapper)
  );

const ownedProductsCountMapper = (productData: null | ProductData): number => Object.keys(ownedProductsMapper(productData)).length;
const getOwnedProductsCount = (): number => ownedProductsCountMapper(productDataSubject$.value);
const ownedProductsCount$ = productData$
  .pipe(
    map(ownedProductsCountMapper)
  );

const isHasOwnedProductsMapper = (productData: null | ProductData): boolean => !!ownedProductsCountMapper(productData);
const getIsHasOwnedProducts = () => isHasOwnedProductsMapper(productDataSubject$.value);
const isHasOwnedProducts$ = productData$
  .pipe(
    map(isHasOwnedProductsMapper)
  );

const offeredProductsMapper = (productData: null | ProductData): ProductData => productData
  ? Object.fromEntries(
    Object.entries(productData)
      .filter(([ _, value ]) => !value.owned)
  )
  : {};
const getOfferedProducts = (): ProductData => offeredProductsMapper(productDataSubject$.value);
const offeredProducts$ = productData$
  .pipe(
    map(offeredProductsMapper)
  );

const offeredProductsCountMapper = (productData: null | ProductData): number => Object.keys(offeredProductsMapper(productData)).length;
const getOfferedProductsCount = (): number => offeredProductsCountMapper(productDataSubject$.value);
const offeredProductsCount$ = productData$
  .pipe(
    map(offeredProductsCountMapper)
  );

const isHasOfferedProductsMapper = (productData: null | ProductData): boolean => !!offeredProductsCountMapper(productData);
const getIsHasOfferedProducts = () => isHasOfferedProductsMapper(productDataSubject$.value);
const isHasOfferedProducts$ = productData$
  .pipe(
    map(isHasOfferedProductsMapper)
  );

export const productService: ProductService = {
  currentProduct$,
  currentProductSubject$,
  productData$,
  productDataSubject$,

  getProductsData,
  productsMapper,
  getProducts,

  productsCountMapper,
  productsCount$,
  getProductsCount,

  isSingleProductMapper,
  getIsSingleProduct,
  isSingleProduct$,

  singleProductNameMapper,
  getSingleProductName,
  singleProductName$,

  isMultiProductMapper,
  getIsMultiProduct,
  isMultiProduct$,

  internalProductsMapper,
  getInternalProducts,
  internalProducts$,

  internalProductsCountMapper,
  getInternalProductsCount,

  singleInternalProductNameMapper,
  getSingleInternalProductName,
  singleInternalProductName$,

  ownedProductsMapper,
  getOwnedProducts,
  ownedProducts$,

  ownedProductsCountMapper,
  getOwnedProductsCount,
  ownedProductsCount$,

  isHasOwnedProductsMapper,
  getIsHasOwnedProducts,
  isHasOwnedProducts$,

  offeredProductsMapper,
  getOfferedProducts,
  offeredProducts$,

  offeredProductsCountMapper,
  getOfferedProductsCount,
  offeredProductsCount$,

  isHasOfferedProductsMapper,
  getIsHasOfferedProducts,
  isHasOfferedProducts$,

  updateProduct,
  setCurrentProduct,
  setCurrentProductByRouteName,
  getCurrentProduct,
  getLastProduct,
  getProductByRouteName,
  isRouteInProduct
};
