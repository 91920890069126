import { State } from 'router5';
import { ProductType } from '@proliance-ai/typings';
import { storageService } from '@proliance-ai/react-ui';
import {
  router,
  redirectParameterName,
  checkPathPermission,
  getPageType,
  getKey
} from '@router';
import { IRouteState, PageType } from '../interfaces';

export const lastPageStorageName = 'lastPage';

export const getLastPageStorageName = (product?: ProductType): string => getKey(lastPageStorageName, { useProduct: true, product });

export const getLastPageStorageValue = (product?: ProductType): null | string => {
  const storageName = getLastPageStorageName(product);
  return storageService.get(storageName);
};
export const setLastPageStorageValue = (value: string): void => {
  const storageName = getLastPageStorageName();
  storageService.set(storageName, value);
};

export const lastPageMiddleware = () => (toState: State, fromState: State): Promise<boolean> => {
  const {
    path,
    name,
    error,
    isLoggedIn,
    isRouted,
    pageType,
    [redirectParameterName]: redirected
  } = toState as IRouteState;
  if (error || !isLoggedIn || pageType === PageType.public) {
    return Promise.resolve(true);
  }
  const storageName = getLastPageStorageName();
  const lastPagePath = storageService.get<null | string>(storageName);

  if (lastPagePath && !fromState && name === 'default' && !isRouted && !redirected) {
    const lastPageData = router().matchUrl(lastPagePath);
    if (!lastPageData || lastPageData.path === toState.path) {
      return Promise.resolve(true);
    }
    if (getPageType(lastPageData.name) === PageType.both) {
      lastPageData.params[redirectParameterName] = true;
      return Promise.reject({ redirect: lastPageData });
    } else {
      if (!checkPathPermission(lastPagePath)) {
        return Promise.resolve(true);
      }
      lastPageData.params[redirectParameterName] = true;
      return Promise.reject({ redirect: lastPageData });
    }
  } else {
    setLastPageStorageValue(path);
  }
  return Promise.resolve(true);
};
