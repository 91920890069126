// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalButton-CompanySwitcherButton_NLKHc,.button-CompanySwitcherButton_96QBv{max-width:600px;padding:8px 24px;box-sizing:border-box;gap:8px}.modalButton-CompanySwitcherButton_NLKHc svg,.button-CompanySwitcherButton_96QBv svg{width:16px;height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalButton": "modalButton-CompanySwitcherButton_NLKHc",
	"button": "button-CompanySwitcherButton_96QBv"
};
export default ___CSS_LOADER_EXPORT___;
