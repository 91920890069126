import { FC } from 'react';
import { State } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import {
  ComponentType,
  ComponentTypeDictionary,
  IComponentPromiseFactory,
  IExportFC,
  IRouteState
} from '../interfaces';
import { permissionService } from '@services';
import { loadingIndicatorService, showMiddlewareReloadModal } from '@proliance-ai/design-system';
import { RouteListType } from '../configuration';
import { routerDependencies } from '@router';

const getComponent = (componentData: ComponentTypeDictionary): null | ComponentType => {
  return (Object.keys(componentData) as RouteListType[]).reduce(
    (result: null | ComponentType, route: RouteListType) => {
      if (result !== null) {
        return result;
      }
      return permissionService.isPageAvailable(route) ? (componentData[route] as ComponentType) : result;
    },
    null
  );
};

export const componentMiddleware = () => (toState: State, _fromState: State, done: DoneFn): void => {
  const { error: errorRouteState } = toState as IRouteState;
  if (errorRouteState) {
    return done();
  }
  const { component: componentData, dynamic, lazy } = routerDependencies[toState.name];
  const component = dynamic ? getComponent(componentData as ComponentTypeDictionary) : (componentData as ComponentType);
  if (component === null) {
    return done();
  }
  if (!lazy) {
    (toState as IRouteState).component = component as FC<any>;
    return done();
  } else {
    (component as IComponentPromiseFactory<any>)()
      .then((module: IExportFC<any>) => {
        (toState as IRouteState).component = module.default;
        return done();
      })
      .catch((error: any) => {
        console.groupCollapsed('ChunkLoadError handled');
        console.error(error);
        console.groupEnd();
        loadingIndicatorService.hide();

        const isChunkLoadError = /Loading chunk [^]+ failed/.test(error.message);
        if (isChunkLoadError) {
          showMiddlewareReloadModal(toState);
        }
      });
  }
};
