import { Api } from '@services/api';
import { Observable } from 'rxjs';
import { PermissionData } from '@proliance-ai/typings';

const apiUrl = '/api/auth/permission';

class PermissionApiService extends Api {
  public getPermissionData(suppressDefaultErrorHandler?: boolean | number[]): Observable<PermissionData> {
    return this.get<PermissionData>({ suppressDefaultErrorHandler });
  }
}

export const permissionApiService = new PermissionApiService(apiUrl);
