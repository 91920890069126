import React, { FC, ReactElement } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  DataAttributesType,
  classNameArrayToString,
  useDataAttributes
} from '@proliance-ai/design-system';

import styles from './StatusIcon.styl';

export enum StatusColor {
  default = 'default',
  primary = 'primary',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export interface IStatusIconData {
  icon: IconDefinition;
  titleTranslationKey?: string;
  color?: StatusColor;
}

export type StatusIconData = Record<string, IStatusIconData>;

interface IOwnProps {
  settings: StatusIconData;
  value?: any;
  dataAttributesDictionary?: DataAttributesType;
  namespace?: Namespace;
  labelNamespace?: Namespace;
  labelTranslationKey?: string;
  className?: string;
}
type Props = IOwnProps;

export const StatusIcon: FC<Props> = ({
  namespace = 'common',
  labelTranslationKey,
  value,
  dataAttributesDictionary = {},
  settings,
  className
}): ReactElement => {
  const { t } = useTranslation(namespace);
  const {
    test = { 'status-icon': value },
    guide = { 'status-icon': value }
  } = dataAttributesDictionary;
  const { dataAttributes } = useDataAttributes({ test, guide });

  const data = settings[value];
  const iconClassNameString = classNameArrayToString([
    styles.icon,
    data.color && styles[data.color]
  ]);

  const title = typeof data.titleTranslationKey !== 'undefined'
    ? t(data.titleTranslationKey)
    : undefined;

  const icon = (
    <FontAwesomeIcon
      className={iconClassNameString}
      icon={data.icon}
      title={title}
    />
  );

  const label = typeof labelTranslationKey !== 'undefined'
    ? <span className={styles.label}>{t(labelTranslationKey)}</span>
    : null;

  const classNameString = classNameArrayToString([
    className,
    styles.statusIcon
  ]);

  return (
    <div
      className={classNameString}
      {...dataAttributes}
    >
      {icon}
      {label}
    </div>
  );
};
