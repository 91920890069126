import { Collection, TranslationInfo } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { Observable } from 'rxjs';

export enum DocumentType {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
  RPA = 'RPA',
  DATA_BREACH = 'DATA_BREACH',
  RISK = 'RISK',
  UPLOADS = 'UPLOADS',
  SURVEY_CONTENT = 'SURVEY_CONTENT',
  NEWS = 'NEWS',
  COMPANY_LOGO = 'COMPANY_LOGO',
}

export enum DocumentFileType {
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  OTHER = 'OTHER',
}

export interface Filter {
  id: string;
  value: string;
}

export interface Document {
  id: string;
  companyId: number;
  type: DocumentType;
  tags: Array<IDictionaryResponse<TranslationInfo>>;
  topic: string;
  fileName: string;
  fileType: DocumentFileType;
  url: string;
  createdAt: string;
}

export interface IDocumentService {
  getDocumentCollection: (options?: object) => Observable<Collection<Document>>;
  uploadDocument: (body: FormData) => Observable<Response>;
  deleteDocument: (id: string) => Observable<boolean>;
}
