import { AjaxError } from 'rxjs/ajax';
import { loadingIndicatorService, parseQueryParameters } from '@proliance-ai/design-system';
import { leadingCharacter } from '@proliance-ai/utilities';
import {
  PageType,
  nextRoute,
  nextPath,
  redirectToLobby,
  redirectUrnParameterName,
  companyIdParameterName,
  emailParameterName,
  errorParameterName,
  productParameterName
} from '@router';
import { companyService, productService, userService } from '@services';
import { getPageType } from '@router';
import { interceptorErrorHandler } from './interceptorErrorHandler';

const redirectHandler = (error: AjaxError) => {
  const { email } = userService.userSubject$.value || {};
  const { id: companyId } = companyService.company$.value || {};
  const redirectUrn = leadingCharacter(nextPath.value, '/', false);
  const product = productService.getCurrentProduct();
  userService.clearUserData();
  interceptorErrorHandler(error);
  if (getPageType(nextRoute.value) === PageType.private) {
    const parameters = { 
      ...parseQueryParameters(),
      ...(email && { [emailParameterName]: encodeURIComponent(email) }),
      [companyIdParameterName]: companyId,
      [productParameterName]: product,
      [redirectUrnParameterName]: redirectUrn,
      [errorParameterName]: 401
    };
    redirectToLobby({ parameters });
  }
};

export const errorResponseInterceptor = (error: AjaxError): AjaxError => {
  const requestFallback = {
    suppressDefaultErrorHandler: false,
    errorHandler: undefined
  };
  const { suppressDefaultErrorHandler, errorHandler } = error.request as any || requestFallback;
  if (typeof errorHandler !== 'undefined') {
    errorHandler(error);
  }
  if (suppressDefaultErrorHandler === true) {
    return error;
  }
  switch (error.status) {
    case 400:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(400)) {
        return error;
      }
      interceptorErrorHandler(error);
      break;
    case 401:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(401)) {
        return error;
      }
      redirectHandler(error);
      break;
    case 403:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(403)) {
        return error;
      }
      interceptorErrorHandler(error);
      break;
    case 404:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(404)) {
        return error;
      }
      interceptorErrorHandler(error);
      break;
    case 409:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(409)) {
        return error;
      }
      break;
    case 500:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(500)) {
        return error;
      }
      interceptorErrorHandler(error);
      break;
    case 502:
      if (Array.isArray(suppressDefaultErrorHandler) && suppressDefaultErrorHandler.includes(502)) {
        return error;
      }
      interceptorErrorHandler(error);
      break;
    default:
      interceptorErrorHandler(error);
  }
  loadingIndicatorService.hide();
  return error;
};
