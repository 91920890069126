// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".productList-ProductList_qL4S2{display:grid;grid-gap:1rem;padding:1rem;background-color:#f7f7f7;border:thin solid #e0e0e0;border-radius:3px;-ms-grid-template-columns:repeat(auto-fill,minmax(270px,1fr));grid-template-columns:repeat(auto-fill,minmax(270px,1fr))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productList": "productList-ProductList_qL4S2"
};
export default ___CSS_LOADER_EXPORT___;
