// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".taskList-BasicTasks_VEaON{width:100%;list-style:none;margin:0;padding:0;box-shadow:0 3px 8px rgba(0,0,0,0.09)}.taskItem-BasicTasks_Y2p8i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;-ms-justify-content:space-between;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;width:100%;height:49px;padding:8px 24px;box-sizing:border-box;border-bottom:1px solid #e0e0e0}.taskItem-BasicTasks_Y2p8i:last-child{border-bottom:none}.taskName-BasicTasks_sFiXg{font-weight:500;font-size:14px;line-height:18px;color:#4f4f4f}.loading-BasicTasks_EQDsG{width:100%;height:98px;position:relative}.empty-BasicTasks_K61mK{width:100%;height:49px;text-align:center;font-size:14px;padding:16px;box-sizing:border-box;font-weight:500;color:#979797;line-height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskList": "taskList-BasicTasks_VEaON",
	"taskItem": "taskItem-BasicTasks_Y2p8i",
	"taskName": "taskName-BasicTasks_sFiXg",
	"loading": "loading-BasicTasks_EQDsG",
	"empty": "empty-BasicTasks_K61mK"
};
export default ___CSS_LOADER_EXPORT___;
