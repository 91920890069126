import { BehaviorSubject, Observable } from 'rxjs';
import { ProductData, ProductEnumType, ProductType } from '@proliance-ai/typings';
import { Mapper } from '@interfaces';
import { RouteListType } from '@router/configuration';

export function isProductValueType(value: any): value is ProductType {
  return [ 'academy', 'privacy', 'proliance' ].includes(<ProductType>value);
}

export type ProductDataMapper<T> = Mapper<null | ProductData, T>;

export interface ProductService {
  currentProduct$: Observable<null | ProductType>;
  currentProductSubject$: BehaviorSubject<null | ProductType>;

  productData$: Observable<null | ProductData>;
  productDataSubject$: BehaviorSubject<null | ProductData>;
  getProductsData: (suppressDefaultErrorHandler?: boolean | number[]) => Observable<void>;
  productsMapper: ProductDataMapper<ProductData>;
  getProducts: () => ProductData;

  productsCountMapper: ProductDataMapper<number>;
  getProductsCount: () => number;
  productsCount$: Observable<number>;

  isSingleProductMapper: ProductDataMapper<boolean>;
  getIsSingleProduct: () => boolean;
  isSingleProduct$: Observable<boolean>;

  singleProductNameMapper: ProductDataMapper<false | ProductEnumType>;
  getSingleProductName: () => false | ProductEnumType;
  singleProductName$: Observable<false | ProductEnumType>;

  isMultiProductMapper: ProductDataMapper<boolean>;
  getIsMultiProduct: () => boolean;
  isMultiProduct$: Observable<boolean>;

  internalProductsMapper: ProductDataMapper<ProductData>;
  getInternalProducts: () => ProductData;
  internalProducts$: Observable<ProductData>;

  internalProductsCountMapper: ProductDataMapper<number>;
  getInternalProductsCount: () => number;

  singleInternalProductNameMapper: ProductDataMapper<false | ProductEnumType>;
  getSingleInternalProductName: () => false | ProductEnumType;
  singleInternalProductName$: Observable<false | ProductEnumType>;

  ownedProductsMapper: ProductDataMapper<ProductData>;
  getOwnedProducts: () => ProductData;
  ownedProducts$: Observable<ProductData>;

  ownedProductsCountMapper: ProductDataMapper<number>;
  getOwnedProductsCount: () => number;
  ownedProductsCount$: Observable<number>;

  isHasOwnedProductsMapper: ProductDataMapper<boolean>;
  getIsHasOwnedProducts: () => boolean;
  isHasOwnedProducts$: Observable<boolean>;

  offeredProductsMapper: ProductDataMapper<ProductData>;
  getOfferedProducts: () => ProductData;
  offeredProducts$: Observable<ProductData>;

  offeredProductsCountMapper: ProductDataMapper<number>;
  getOfferedProductsCount: () => number;
  offeredProductsCount$: Observable<number>;

  isHasOfferedProductsMapper: ProductDataMapper<boolean>;
  getIsHasOfferedProducts: () => boolean;
  isHasOfferedProducts$: Observable<boolean>;

  updateProduct: () => void;
  setCurrentProduct: (product: ProductType) => void;
  setCurrentProductByRouteName: (routeName: RouteListType) => void;
  getCurrentProduct: () => null | ProductType;
  getLastProduct: () => null | ProductType;
  getProductByRouteName: (routeName: RouteListType) => ProductType;
  isRouteInProduct: (routeName: RouteListType, product: ProductType) => boolean;
}
