import { forkJoin, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  retryWhen,
  share,
  take
} from 'rxjs/operators';
import { ProductEnumType } from '@proliance-ai/typings';
import { parseQueryParameters, tokenService } from '@proliance-ai/design-system';
import { notificationService } from '@proliance-ai/react-ui';
import { getProductKey, leadingCharacter } from '@proliance-ai/utilities';
import {
  companyIdParameterName,
  emailParameterName,
  errorParameterName,
  productParameterName,
  redirect,
  redirectToLobby,
  redirectUrnParameterName,
  router
} from '@router';
import {
  AcademyUserInvitedMessage,
  companyService,
  permissionService,
  productService,
  userService
} from '@services';
import { CompanyAddedMessage,
  CompanyRemovedMessage,
  CompanySwitchedMessage,
  CompanyUpdatedMessage,
  DataBreachMessage,
  LogoutMessage,
  PermissionsUpdatedMessage,
  ProductsUpdatedMessage,
  RiskCaseMessage,
  CompanyUpdatedPayload,
  ErrorStream,
  StreamEvent,
  ProductsUpdatedPayload,
  UserCourseDeletedMessage,
  UserCourseMessage } from '@proliance-ai/typings';
import { streamApiService } from '@services/api';
import {
  ActivityMessage,
  IStreamService,
  StreamMessage,
  TaskCountMessage
} from './stream.typings';

let subscription: Subscription;

export const debounceTimeValue = 200;

const subject$ = new Subject<StreamMessage>();
const draftChangedSubject$ = new Subject<boolean>();
const draftChanged$ = draftChangedSubject$.asObservable();
const updateCompanyListSubject$ = new Subject<void>();
const updateCompanyList$ = updateCompanyListSubject$.asObservable();
const updateProductListSubject$ = new Subject<void>();
const updateProductList$ = updateProductListSubject$.asObservable();
const updatePermissionsSubject$ = new Subject<void>();
const updatePermissions$ = updatePermissionsSubject$.asObservable();

const getRedirectParameters = (): Record<string, undefined | null | number | string> => {
  const { email } = userService.userSubject$.value || {};
  const { id: companyId } = companyService.company$.value || {};
  const product = productService.getCurrentProduct();
  const path = router().getState()?.path || '';
  const redirectUrn = leadingCharacter(path.split('?')[0], '/', false);
  return {
    ...parseQueryParameters(),
    ...(email && { [emailParameterName]: email }),
    ...(redirectUrn && redirectUrn !== 'login' && { [redirectUrnParameterName]: redirectUrn }),
    ...(companyId && { [companyIdParameterName]: companyId }),
    [productParameterName]: product,
    [errorParameterName]: 401
  };
};

const streamErrorHandler = (error: ErrorStream): void => {
  const unauthorizedHandler = (errorItem: AjaxError) => {
    if (errorItem.status === 401) {
      if (userService.isLoggedInSubject$.value) {
        redirectToLobby();
      }
      return throwError(errorItem);
    }
  };
  streamApiService
    .healthCheck()
    .pipe(
      retryWhen((errors: Observable<AjaxError>) => errors
        .pipe(
          concatMap((errorItem: AjaxError, index: number): Observable<ErrorStream> => iif(
            () => errorItem.status === 401 || index > 60,
            unauthorizedHandler(errorItem),
            of(error).pipe(delay(error.reconnectDelay))
          ))
        )
      ),
      catchError(() => of(null))
    )
    .subscribe((value: null | number): void => {
      if (value !== null) {
        connect();
      }
    });
};

const connect = (): void => {
  const stream = streamApiService
    .getStream()
    .pipe(
      catchError((error: ErrorStream): Observable<null> => {
        streamErrorHandler(error);
        return of(null);
      }),
      share()
    );
  subscription = stream
    .subscribe((message: null | StreamMessage): void => {
      if (message) {
        subject$.next(message);
      }
    });
};

const unsubscribe = (): void => {
  if (subscription) {
    subscription.unsubscribe();
  }
};

const reconnect = (): void => {
  unsubscribe();
  connect();
};

const isAcademyUserInvitedMessage = (message: StreamMessage): message is AcademyUserInvitedMessage => message.type === StreamEvent.ACADEMY_USER_INVITED;
const subscribeAcademyUserInvitedMessage = (): Observable<AcademyUserInvitedMessage> => subject$
  .asObservable()
  .pipe(filter(isAcademyUserInvitedMessage));

const isActivityMessage = (message: StreamMessage): message is ActivityMessage => message.type === StreamEvent.ACTIVITY;
const subscribeActivityMessage = (): Observable<ActivityMessage> => subject$
  .asObservable()
  .pipe(filter(isActivityMessage));

const isDataBreachMessage = (message: StreamMessage): message is DataBreachMessage => message.type === StreamEvent.DATA_BREACH;
const subscribeDataBreachMessage = (): Observable<DataBreachMessage> => subject$
  .asObservable()
  .pipe(filter(isDataBreachMessage));

const isRiskCaseMessage = (message: StreamMessage): message is RiskCaseMessage => message.type === StreamEvent.RISK_CASE;
const subscribeRiskCaseMessage = (): Observable<RiskCaseMessage> => subject$
  .asObservable()
  .pipe(filter(isRiskCaseMessage));

const isTaskCountMessage = (message: StreamMessage): message is TaskCountMessage => message.type === StreamEvent.TASK_COUNT;
const subscribeTaskCountMessage = (): Observable<TaskCountMessage> => subject$
  .asObservable()
  .pipe(
    filter(isTaskCountMessage),
    debounceTime(debounceTimeValue)
  );

const isUserCourseMessage = (message: StreamMessage): message is UserCourseMessage => [
  StreamEvent.USER_COURSE_UPDATED,
  StreamEvent.USER_COURSE_DELETED
]
  .includes(message.type);
export const isUserCourseDeletedMessage = (message: StreamMessage): message is UserCourseDeletedMessage => message.type === StreamEvent.USER_COURSE_DELETED;
const subscribeUserCourseMessage = (): Observable<UserCourseMessage> => subject$
  .asObservable()
  .pipe(filter(isUserCourseMessage));

const companiesListChangeHandler = (): void => {
  updateCompanyListSubject$.next();
};
const isCompanyAddedMessage = (message: StreamMessage): message is CompanyAddedMessage => message.type === StreamEvent.COMPANY_ADDED;
const subscribeCompanyAddedMessage = (): Observable<CompanyAddedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanyAddedMessage));
subscribeCompanyAddedMessage()
  .subscribe((): void => {
    companyService.updateCurrentCompany()
      .subscribe(companiesListChangeHandler);
  });
const isCompanyRemovedMessage = (message: StreamMessage): message is CompanyRemovedMessage => message.type === StreamEvent.COMPANY_REMOVED;
const subscribeCompanyRemovedMessage = (): Observable<CompanyRemovedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanyRemovedMessage));
subscribeCompanyRemovedMessage()
  .subscribe((message: CompanyRemovedMessage): void => {
    const { payload: { currentCompany } } = message;
    if (currentCompany) {
      return redirectToLobby({
        route: 'companies',
        parameters: {
          notification: 'companyRemoved'
        }
      });
    } else {
      companyService.updateCurrentCompany()
        .subscribe(companiesListChangeHandler);
    }
  });
const isCompanySwitchedMessage = (message: StreamMessage): message is CompanySwitchedMessage => message.type === StreamEvent.COMPANY_SWITCHED;
const subscribeCompanySwitchedMessage = (): Observable<CompanySwitchedMessage> => subject$
  .asObservable()
  .pipe(filter(isCompanySwitchedMessage));
subscribeCompanySwitchedMessage()
  .subscribe((message: CompanySwitchedMessage): void => {
    const { payload: { currentCompanyId } } = message;
    const companyId = companyService.companyId$.value;
    if (companyId && +companyId === +currentCompanyId) {
      return;
    }
    redirect('default', { companyId: currentCompanyId, notification: 'companySwitched' });
  });
const isCompanyUpdatedMessage = (message: StreamMessage): message is CompanyUpdatedMessage => message.type === StreamEvent.COMPANY_UPDATED;
const defaultCompanyUpdatedPayload: CompanyUpdatedPayload = {
  currentCompany: false,
  permissionsUpdated: false,
  draftChanged: false
};
let companyUpdatedPayload: CompanyUpdatedPayload = defaultCompanyUpdatedPayload;
const subscribeCompanyUpdatedMessage = (): Observable<CompanyUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isCompanyUpdatedMessage),
    map((message: CompanyUpdatedMessage): CompanyUpdatedMessage => {
      companyUpdatedPayload = {
        currentCompany: companyUpdatedPayload.currentCompany || message.payload.currentCompany,
        permissionsUpdated: companyUpdatedPayload.permissionsUpdated || message.payload.permissionsUpdated,
        draftChanged: companyUpdatedPayload.draftChanged || message.payload.draftChanged
      };
      message.payload = companyUpdatedPayload;
      return message;
    }),
    debounceTime(debounceTimeValue)
  );
subscribeCompanyUpdatedMessage()
  .subscribe((message: CompanyUpdatedMessage): void => {
    companyUpdatedPayload = defaultCompanyUpdatedPayload;
    const { payload: { currentCompany, draftChanged, permissionsUpdated } } = message;
    if (!currentCompany) {
      updateCompanyListSubject$.next();
      return;
    }
    forkJoin(
      permissionsUpdated
        ? [ companyService.updateCurrentCompany(), permissionService.assignPermissionData() ]
        : [ companyService.updateCurrentCompany() ]
    )
      .pipe(take(1))
      .subscribe(([ company ]): void => {
        updateCompanyListSubject$.next();
        if (permissionsUpdated) {
          updatePermissionsSubject$.next();
        }
        if (company && draftChanged) {
          draftChangedSubject$.next(company.draftMode);
        }
        const dataAttributesDictionary = {
          test: { notificationWarning: 'companyUpdated' },
          guide: { notificationWarning: 'companyUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.companyUpdated',
          dataAttributesDictionary
        });
      });
  });

const isPermissionsUpdatedMessage = (message: StreamMessage): message is PermissionsUpdatedMessage => message.type === StreamEvent.PERMISSIONS_UPDATED;
const subscribePermissionsUpdatedMessage = (): Observable<PermissionsUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isPermissionsUpdatedMessage),
    debounceTime(debounceTimeValue)
  );
subscribePermissionsUpdatedMessage()
  .subscribe((message: PermissionsUpdatedMessage): void => {
    const { payload: { currentCompany } } = message;
    if (!currentCompany) {
      return;
    }
    permissionService.assignPermissionData()
      .pipe(take(1))
      .subscribe((): void => {
        updatePermissionsSubject$.next();
        const dataAttributesDictionary = {
          test: { notificationWarning: 'permissionsUpdated' },
          guide: { notificationWarning: 'permissionsUpdated' }
        };
        notificationService.warn({
          textTranslationKey: 'common:sse.permissionsUpdated',
          dataAttributesDictionary
        });
      });
  });

const isCurrentProductRemoved = (): boolean => {
  const currentProduct = productService.getCurrentProduct();
  if (!currentProduct) {
    return false;
  }
  const currentProductType = getProductKey(currentProduct);
  if (!currentProductType) {
    return false;
  }
  const ownedProductList = Object.keys(productService.getOwnedProducts()) as ProductEnumType[];
  return !ownedProductList.includes(currentProductType);
};
const isProductsUpdatedMessage = (message: StreamMessage): message is ProductsUpdatedMessage => message.type === StreamEvent.PRODUCTS_UPDATED;
const defaultProductsUpdatedPayload: ProductsUpdatedPayload = {
  currentCompany: false,
  permissionsUpdated: false
};
let productsUpdatedPayload: ProductsUpdatedPayload = defaultProductsUpdatedPayload;
const subscribeProductsUpdatedMessage = (): Observable<ProductsUpdatedMessage> => subject$
  .asObservable()
  .pipe(
    filter(isProductsUpdatedMessage),
    map((message: ProductsUpdatedMessage): ProductsUpdatedMessage => {
      productsUpdatedPayload = {
        currentCompany: productsUpdatedPayload.currentCompany || message.payload.currentCompany,
        permissionsUpdated: companyUpdatedPayload.permissionsUpdated || message.payload.permissionsUpdated
      };
      message.payload = productsUpdatedPayload;
      return message;
    }),
    debounceTime(debounceTimeValue)
  );
subscribeProductsUpdatedMessage()
  .subscribe((message: ProductsUpdatedMessage): void => {
    productsUpdatedPayload = defaultProductsUpdatedPayload;
    const { payload: { currentCompany, permissionsUpdated } } = message;
    if (!currentCompany) {
      return;
    }
    forkJoin([
      productService.getProductsData(),
      permissionService.assignPermissionData()
    ])
      .pipe(take(1))
      .subscribe((): void => {
        if (isCurrentProductRemoved()) {
          redirectToLobby({
            route: 'products',
            parameters: {
              notification: 'productsUpdated'
            }
          });
        } else {
          updateProductListSubject$.next();
          if (permissionsUpdated) {
            updatePermissionsSubject$.next();
          }
          const dataAttributesDictionary = {
            test: { notificationWarning: 'productsUpdated' },
            guide: { notificationWarning: 'productsUpdated' }
          };
          notificationService.warn({
            textTranslationKey: 'common:sse.productsUpdated',
            dataAttributesDictionary
          });
        }
      });
  });

const isLogoutMessage = (message: StreamMessage): message is LogoutMessage => message.type === StreamEvent.LOGOUT;
const subscribeLogoutMessage = (): Observable<LogoutMessage> => subject$
  .asObservable()
  .pipe(filter(isLogoutMessage));
subscribeLogoutMessage()
  .subscribe((message: LogoutMessage): void => {
    const token = message.payload?.token;
    if (token) {
      const currentToken = tokenService.getToken();
      if (!!currentToken && currentToken !== token) {
        return;
      }
    }
    window.addEventListener(
      'pageshow',
      (event: PageTransitionEvent): void => {
        if (event.persisted) {
          location.reload();
        }
      }
    );
    const parameters = getRedirectParameters();
    return redirectToLobby({ parameters });
  });

export const streamService: IStreamService = {
  draftChanged$,
  updateCompanyList$,
  updatePermissions$,
  updateProductList$,
  connect,
  unsubscribe,
  reconnect,
  subscribeAcademyUserInvitedMessage,
  subscribeActivityMessage,
  subscribeDataBreachMessage,
  subscribeRiskCaseMessage,
  subscribeTaskCountMessage,
  subscribeUserCourseMessage,
  subscribeCompanyAddedMessage,
  subscribeCompanyRemovedMessage,
  subscribeCompanySwitchedMessage,
  subscribeCompanyUpdatedMessage,
  subscribePermissionsUpdatedMessage,
  subscribeProductsUpdatedMessage
};
