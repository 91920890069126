import React, { FC, memo, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductEnum, ProductEnumType, ProductType } from '@proliance-ai/typings';
import {
  Button,
  ButtonProperties,
  IconButton,
  SVG
} from '@proliance-ai/design-system';
import { modalService, useDataAttributes } from '@proliance-ai/react-ui';
import { classNameArrayToString, getStaticProductLogoResourceUrl } from '@proliance-ai/utilities';
import { redirect } from '@router';
import { FullRouteListType, RouteListType } from '@router/configuration';
import { Company, productService } from '@services';
import { useProductSwitcherModalOptions } from './hooks';

import {
  faRepeat,
  faHomeAlt,
  faPoll,
  faExclamationCircle,
  faGraduationCap,
  faBooks,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import { faGrid3 } from '@fortawesome/pro-solid-svg-icons';
import styles from './ProductSwitcherButton.styl';

export const menuIconDictionary: Partial<Record<FullRouteListType, IconDefinition>> = {
  dashboard: faHomeAlt,
  risk: faPoll,
  dataBreach: faExclamationCircle,
  courseManagement: faBooks,
  myCourses: faGraduationCap,
  profile: faUser
};

interface OwnProps {
  route?: RouteListType;
  hideMenu?: boolean;
  isCollapsed?: boolean;
  currentCompany: null | Company;
  isMultiProduct: null | boolean;
  isMultitenancy: null | boolean;
  isModalActionButton?: boolean;
}

type Props = OwnProps & Omit<ButtonProperties, 'text' | 'disabled' | 'preset' | 'onClick' | 'className'>;

const ProductSwitcherButton: FC<Props> = ({
  route,
  hideMenu = false,
  isCollapsed = false,
  currentCompany,
  isMultiProduct,
  isMultitenancy,
  isModalActionButton = false,
  ...properties
}): null | ReactElement => {
  const { t } = useTranslation('products');

  const { modalOptions } = useProductSwitcherModalOptions({
    id: 'productSwitcherModal',
    currentCompany,
    isMultiProduct,
    isMultitenancy
  });

  const clickHandler = (): void => {
    if (!isMultiProduct) {
      if (route) {
        redirect(route);
      }
      return;
    }
    modalService.show(modalOptions);
  };

  const [ product, setProduct ] = useState<ProductType>(productService.getCurrentProduct()!);
  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    productService.currentProduct$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data: null | ProductType) => {
        if (data) {
          setProduct(data);
        }
      });
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const proliance = getStaticProductLogoResourceUrl({ name: 'proliance360.svg' });
  const productKey = (Object.keys(ProductEnum) as ProductEnumType[])[
    (Object.values(ProductEnum) as ProductType[]).indexOf(product)
  ];
  const productItem = productKey
    && productService.productDataSubject$.value
    && productService.productDataSubject$.value[productKey];
  const image = !!productItem
    ? t(`products:products.${ productKey }.logo`)
    : proliance;
  const buttonContent = (
    <>
      <SVG className={ styles.logo } src={ image }/>
      { isMultiProduct && <FontAwesomeIcon className={ styles.icon } icon={ faRepeat }/> }
    </>
  );

  const title: string = isMultiProduct
    ? t('common:products.title.select')
    : route
      ? t('common:products.navigate', { route })
      : t(`common:products.${ product }`);

  const button = (
    <Button
      { ...properties }
      className={ styles.modalButton }
      preset="default"
      text={ buttonContent }
      title={ title }
      useEllipsis={ false }
      disabled={ !isMultiProduct }
      onClick={ clickHandler }
      dataAttributesDictionary={ {
        test: { toggleModal: 'product-switcher' },
        guide: { toggleModal: 'product-switcher' }
      } }
    />
  );

  const buttonClassName = classNameArrayToString([
    styles.button,
    ...[ !hideMenu && isCollapsed && styles.collapsed ],
    ...[ !isMultiProduct && !route && styles.disabled ]
  ]);

  const routeIcon = route && menuIconDictionary[route];
  const icon: IconDefinition = !isMultiProduct && routeIcon
    ? routeIcon
    : faGrid3;

  const logoButtonIcon = isMultiProduct || route
    ? (
      <FontAwesomeIcon
        className={ styles.logoButtonIcon }
        icon={ icon }
      />
    )
    : null;

  const dataAttributes = useDataAttributes({
    test: { productSwitcher: isMultiProduct ? 'modal' : 'navigate' },
    guide: { productSwitcher: isMultiProduct ? 'modal' : 'navigate' }
  });

  const iconButton = (
    <div
      className={ buttonClassName }
      title={ title }
      { ...dataAttributes }
      onClick={ clickHandler }
    >
      <IconButton
        { ...properties }
        className={ styles.iconButton }
        preset="default"
        icon={ icon }
        disabled={ !isMultiProduct && !route }
      />
      { logoButtonIcon }
      <SVG
        className={ `${ styles.logoButton } ${ styles[product] }` }
        src={ image }
      />
    </div>
  );

  return isModalActionButton
    ? button
    : iconButton;
};

export default memo(ProductSwitcherButton);
