import { Priority, ThirdPartyFormKey } from '@proliance-ai/typings';

export const thirdPartyPriorityMapping: Partial<Record<ThirdPartyFormKey, Priority>> = {
  type: Priority.IMPORTANT,
  legalStatuses: Priority.IMPORTANT,
  dataTypes: Priority.IMPORTANT,
  transmissionCountries: Priority.IMPORTANT,
  documents: Priority.IMPORTANT,
  description: Priority.IMPORTANT,
  contractualDeadlines: Priority.OPTIONAL,
  contact: Priority.RECOMMENDED,
  privacyContact: Priority.RECOMMENDED,
  services: Priority.IMPORTANT,
  accesses: Priority.OPTIONAL,
  rpaAsController: Priority.OPTIONAL,
  rpaAsProcessor: Priority.OPTIONAL
};
