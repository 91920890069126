import { from, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Collection } from '@proliance-ai/typings';
import { tokenService } from '@proliance-ai/design-system';
import { Document } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/document';

class DocumentApiService extends Api {
  public getDocumentCollection(parameters: Record<string, any>): Observable<Collection<Document>> {
    return this.get({ parameters });
  }

  public uploadDocument(body: FormData): Observable<Response> {
    return from(
      fetch(
        '/api/survey/document',
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${ tokenService.getToken() }` },
          body
        }
      )
    )
      .pipe(take(1));
  }

  public deleteDocument(id: string): Observable<boolean> {
    return this.delete<boolean>({ url: id })
      .pipe(take(1));
  }
}

export const documentApiService = new DocumentApiService(apiUrl);
