import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRoute } from 'react-router5';
import { ActivationFn, State } from 'router5';
import { Params } from 'router5/dist/types/base';
import { take } from 'rxjs/operators';
import {
  Button,
  Callback,
  loadingIndicatorService,
  mergeObjects
} from '@proliance-ai/design-system';
import {
  CloseModalButton,
  notificationService,
  modalService,
  modalHandler
} from '@proliance-ai/react-ui';
import { redirect } from '@router';
import { surveyService, userService } from '@services';
import { SurveyStatus } from '@shared/surveyJs';
import getSurveyData from '@shared/surveyJs/utilities';
import { useClearLeavePageHandler } from './useClearLeavePageHandler';
import { CancelSurveyModal } from './components';
import { defaultUseHandlerFn } from './constants';
import { ISendRequestOptions, IUseCancelSurveyCreationOptions } from './typings';

export const useCancelSurveyCreation = (options: IUseCancelSurveyCreationOptions) => {
  const {
    isCreating,
    survey,
    redirectName,
    redirectParams,
    isReadonly = false,
    useHandlerFn = defaultUseHandlerFn
  } = options;

  const { t } = useTranslation();
  const { router } = useRoute();
  const routeName = router.getState().name;

  const sendRequest = (requestOptions: ISendRequestOptions): void => {
    const {
      id,
      currentPageNo = -1,
      status = SurveyStatus.running,
      data = {},
      parameters = {},
      callback
    } = requestOptions;
    loadingIndicatorService.show();
    const { params = '{}' } = parameters;
    surveyService
      .saveSurveyResult(
        id,
        currentPageNo,
        status,
        data,
        { ...parameters, params: mergeObjects({}, JSON.parse(params), { 'partly-completed': true }) }
      )
      .pipe(take(1))
      .subscribe(() => {
        loadingIndicatorService.hide();
        if (typeof callback !== 'undefined') {
          callback();
        }
      });
  };

  const cancelButtonHandler = (redirectFunction: Callback) => (): void => {
    sendRequest({
      id: survey?.state.surveyResultId,
      parameters: survey?.state.params,
      callback: redirectFunction
    });
  };

  const saveButtonHandler = (redirectFunction: Callback) => (): void => {
    if (!survey) {
      return;
    }
    const {
      state: {
        surveyResultId: id,
        params: parameters,
        questionsViewModel: model
      }
    } = survey;
    if (model.isCurrentPageHasErrors) {
      const dataAttributesDictionary = {
        test: { notificationError: 'saveSurvey' },
        guide: { notificationError: 'saveSurvey' }
      };
      notificationService.error({
        textTranslationKey: 'common:survey.message.saveError',
        dataAttributesDictionary
      });
      return;
    }
    const data = getSurveyData(model);
    return sendRequest({
      id,
      parameters,
      currentPageNo: model.currentPageNo,
      data,
      status: SurveyStatus.completed,
      callback: () => {
        redirectFunction();
        const dataAttributesDictionary = {
          test: { notificationError: 'saveSurvey' },
          guide: { notificationError: 'saveSurvey' }
        };
        notificationService.success({
          textTranslationKey: 'common:survey.message.saveSuccess',
          dataAttributesDictionary
        });
      }
    });
  };

  const canDeactivateHandler = (): ActivationFn => (toState: State): boolean => {
    if (!useHandlerFn()) {
      return true;
    }
    const showModal = cancelModalFactory(toState.name, toState.params, toState.meta?.params?.callback);
    showModal();
    return false;
  };

  const setLeavePageHandler = (): void => {
    if (!isReadonly) {
      router.canDeactivate(routeName, canDeactivateHandler);
    }
  };

  const { clearLeavePageHandler } = useClearLeavePageHandler(isReadonly);

  const redirectFunctionFactory = (name: string, params?: Params, callback?: Callback) => (): void => {
    clearLeavePageHandler();
    typeof callback === 'undefined'
      ? redirect(name, params)
      : callback();
  };

  const cancelModalFactory = (name: string, params?: Params, callback?: Callback) => (): void => {
    const redirectFunction = redirectFunctionFactory(name, params, callback);
    const isLoggedIn = userService.isLoggedInSubject$.value;
    if (!isLoggedIn) {
      redirectFunction();
      return;
    }
    const isFirstPage = survey?.state.questionsViewModel.isFirstPage;
    const translationKey = isCreating
      ? isFirstPage
        ? 'survey.cancelCreationFirstPageText'
        : 'survey.cancelCreationDefaultText'
      : !isFirstPage
        ? 'survey.cancelCreationDefaultText'
        : 'survey.cancelEditingText';
    const actionsElement = isFirstPage
      ? (
        <>
          <Button
            preset="default"
            dataAttributesDictionary={ {
              test: { 'button-modal': 'stay' },
              guide: { 'survey': 'stayButton' }
            } }
            text={ t('common:survey.button.stay') }
            onClick={ modalHandler() }
          />
          <Button
            preset="primary"
            dataAttributesDictionary={ {
              test: { 'button-modal': 'leave' },
              guide: { 'survey': 'leaveButton' }
            } }
            text={ t('common:survey.button.leave') }
            onClick={ modalHandler(redirectFunction) }
          />
        </>
      )
      : (
        <>
          <CloseModalButton
            dataAttributesDictionary={ {
              test: { 'button-modal': 'close' },
              guide: { 'survey': 'closeButton' }
            } }
          />

          <Button
            dataAttributesDictionary={ {
              test: { 'button-modal': 'save' },
              guide: { 'survey': 'saveButton' }
            } }
            preset="primary"
            text={ t('common:survey.button.save') }
            onClick={ modalHandler(saveButtonHandler(redirectFunction)) }
          />
        </>
      );
    const secondaryActionsElement = isFirstPage
      ? undefined
      : (
        <>
          <Button
            dataAttributesDictionary={ {
              test: { 'button-modal': 'clear' },
              guide: { 'survey': 'clearButton' }
            } }
            preset="danger"
            suppressWarning={ true }
            text={ t('common:survey.button.clear') }
            onClick={ modalHandler(cancelButtonHandler(redirectFunction)) }
          />
        </>
      );
    modalService.show({
      id: 'surveyCreationCancel',
      size: 'large',
      heading: {
        title: t('survey.cancel')
      },
      content: {
        contentElement: <CancelSurveyModal translationKey={ translationKey }/>
      },
      footer: {
        actionsElement,
        secondaryActionsElement
      }
    });
  };

  const cancelCallback = isReadonly
    ? redirectFunctionFactory(redirectName, redirectParams)
    : cancelModalFactory(redirectName, redirectParams);

  setLeavePageHandler();

  return { cancelCallback, setLeavePageHandler, clearLeavePageHandler };
};
