import { Observable } from 'rxjs';
import { Collection, TranslationInfo } from '@proliance-ai/typings';
import { QueryStringValue } from '@proliance-ai/design-system';

export enum SystemEvent {
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  DPO_NOMINATION_COMPLETED = 'DPO_NOMINATION_COMPLETED',
  PROCESS_SELECTION_COMPLETED = 'PROCESS_SELECTION_COMPLETED',
  SYSTEM_ASSIGMENT_COMPLETED = 'SYSTEM_ASSIGMENT_COMPLETED',
  AUDIT_COMPLETED = 'AUDIT_COMPLETED',
  ACTION_COMPLETED = 'ACTION_COMPLETED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TEAM_MEMBER_ADDED = 'TEAM_MEMBER_ADDED',
  TEAM_MEMBER_CHANGED = 'TEAM_MEMBER_CHANGED',
  FA_CONTRIBUTOR_ADDED = 'FA_CONTRIBUTOR_ADDED',
  FA_CONTRIBUTOR_REMOVED = 'FA_CONTRIBUTOR_REMOVED',
  DPM_DEPUTY_ADDED = 'DPM_DEPUTY_ADDED',
  DPM_DEPUTY_REMOVED = 'DPM_DEPUTY_REMOVED',
  BASIS_DATA_CREATED = 'BASIS_DATA_CREATED',
  BASIS_DATA_UPDATED = 'BASIS_DATA_UPDATED',
  BASIS_DATA_DELETED = 'BASIS_DATA_DELETED',
  COURSE_REPETITION_FORCED = 'COURSE_REPETITION_FORCED',
  CUSTOM_PROCESS_CREATED = 'CUSTOM_PROCESS_CREATED',
  CUSTOM_PROCESS_UPDATED = 'CUSTOM_PROCESS_UPDATED',
  CUSTOM_PROCESS_DELETED = 'CUSTOM_PROCESS_DELETED',
  DATA_BREACH_CREATED = 'DATA_BREACH_CREATED',
  DATA_BREACH_UPDATED = 'DATA_BREACH_UPDATED',
  DATA_BREACH_DELETED = 'DATA_BREACH_DELETED',
  DATA_BREACH_CLOSED = 'DATA_BREACH_CLOSED',
  DATA_BREACH_REOPENED = 'DATA_BREACH_REOPENED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  DOCUMENT_GENERATED = 'DOCUMENT_GENERATED',
  UPDATED_COMPANY_BASIC_DATA = 'UPDATED_COMPANY_BASIC_DATA',
  UPDATED_COMPANY_BUSINESS_DETAILS = 'UPDATED_COMPANY_BUSINESS_DETAILS',
  CREATED_COMPANY_MANAGEMENT = 'CREATED_COMPANY_MANAGEMENT',
  UPDATED_COMPANY_MANAGEMENT = 'UPDATED_COMPANY_MANAGEMENT',
  DELETED_COMPANY_MANAGEMENT = 'DELETED_COMPANY_MANAGEMENT',
  RISK_CASE_CREATED = 'RISK_CASE_CREATED',
  RISK_CASE_DELETED = 'RISK_CASE_DELETED',
  RISK_CASE_CLOSED = 'RISK_CASE_CLOSED',
  RISK_CASE_REOPENED = 'RISK_CASE_REOPENED',
  RISK_CASE_STEP_FINISHED = 'RISK_CASE_STEP_FINISHED',
  RISK_CASE_EXPORTED = 'RISK_CASE_EXPORTED',
  COMPANY_COURSE_RECURRENCE_ACTIVATED = 'COMPANY_COURSE_RECURRENCE_ACTIVATED',
  COMPANY_COURSE_RECURRENCE_DEACTIVATED = 'COMPANY_COURSE_RECURRENCE_DEACTIVATED',
  COMPANY_COURSE_RECURRENCE_CHANGED = 'COMPANY_COURSE_RECURRENCE_CHANGED',
  COMPANY_USER_RETIRED = 'COMPANY_USER_RETIRED',
  RECURRING_TASKS_CANCELED = 'RECURRING_TASKS_CANCELED',
  RPA_EXPORTED = 'RPA_EXPORTED',
  DOMAIN_CONFIG_ADDED = 'DOMAIN_CONFIG_ADDED',
  DOMAIN_CONFIG_UPDATED = 'DOMAIN_CONFIG_UPDATED',
  DOMAIN_CONFIG_DELETED = 'DOMAIN_CONFIG_DELETED',
  DPO_DEPUTY_ADDED = 'DPO_DEPUTY_ADDED',
  DPO_DEPUTY_REMOVED = 'DPO_DEPUTY_REMOVED'
}

export interface IActivityAuthor {
  admin: boolean;
  name: string;
  onBehalf: string;
}

export interface IActivity {
  id: string;
  companyName: string;
  author: null | IActivityAuthor;
  affectedUserName: string;
  nameTranslations: TranslationInfo;
  event: string;
  type: string;
  parameters: Record<string, number | string>;
  createdAt: string;
}

export interface IActivityService {
  getActivity: (parameters?: Record<string, QueryStringValue>) => Observable<Collection<IActivity>>;
}
