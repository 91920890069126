// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container-AppLayout_XOpKt{min-width:1200px;width:100%;height:100%;height:100vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1 1 auto;-ms-flex:1 1 auto;flex:1 1 auto;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin:0 auto;padding:0;box-sizing:border-box}.body-AppLayout_Of0n1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1 0 auto;-ms-flex:1 0 auto;flex:1 0 auto}.content-AppLayout_Bw_Io{position:relative;min-width:1200px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1 0 100%;-ms-flex:1 0 100%;flex:1 0 100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;width:100%;padding:16px 0;box-sizing:border-box;overflow:hidden}.sidebar-AppLayout_eVdMs{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:0 0 0;-ms-flex:0 0 0;flex:0 0 0}.page-AppLayout_e2Mys{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:1;-ms-flex:1;flex:1;min-width:0;padding:0 1rem;box-sizing:border-box;margin-right:0;-webkit-transition:margin-right .2s linear;transition:margin-right .2s linear}@media all and (min-width:1800px){.page-AppLayout_e2Mys.sidebar-AppLayout_eVdMs{margin-right:600px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container-AppLayout_XOpKt",
	"body": "body-AppLayout_Of0n1",
	"content": "content-AppLayout_Bw_Io",
	"sidebar": "sidebar-AppLayout_eVdMs",
	"page": "page-AppLayout_e2Mys"
};
export default ___CSS_LOADER_EXPORT___;
