import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FileUpload.styl';
import { useDataAttributes } from '@proliance-ai/design-system';

export const FileUploadListItem: FC<Props> = ({ file, isFileExtensionInvalid = false, removeFile }) => {
  const { t } = useTranslation();
  const validationError = (value: string) => {
    return isFileExtensionInvalid ? value : '';
  };
  const { dataAttributes } = useDataAttributes({
    test: { 'file-upload': 'fileTypeError' },
    guide: { 'file-upload': 'fileTypeError' }
  });
  return (
    <div className={`${ styles.listItem } ${ validationError(styles.error) }`}>
      <div className={styles.content}>
        <div className={styles.name}>{file.name}</div>
        <div
          className={styles.removeBtn}
          onClick={() => {
            if (typeof removeFile === 'function') {
              removeFile(file.name);
            }
          }}
        >
          {t('fileUpload.list.remove')}
        </div>
      </div>
      <div
        className={styles.validationError}
        {...dataAttributes}
      >
        {validationError(t('fileUpload.list.extensionInvalid'))}
      </div>
    </div>
  );
};
export interface IFileUploadListItem {
  file: File;
  isFileExtensionInvalid: boolean;
  removeFile: (fileName: string) => void;
}
type Props = IFileUploadListItem;
