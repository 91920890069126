import { Priority, SoftwareFormKey } from '@proliance-ai/typings';

export const softwarePriorityMapping: Partial<Record<SoftwareFormKey, Priority>> = {
  type: Priority.IMPORTANT,
  operators: Priority.IMPORTANT,
  vendor: Priority.RECOMMENDED,
  dataTypes: Priority.IMPORTANT,
  description: Priority.IMPORTANT,
  categories: Priority.IMPORTANT,
  accesses: Priority.OPTIONAL,
  rpaAsController: Priority.OPTIONAL
};
