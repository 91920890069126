import { Api } from '@services/api';
import { Observable, of } from 'rxjs';
import { Collection, CurrentUser } from '@proliance-ai/typings';
import { ITableParameters } from '@interfaces';
import {
  getRequestParametersFromTableParameters,
  Company
} from '@services';

const apiUrl: string = '/api/survey/user';

class UserApiService extends Api {
  public getCurrentUser(token: null | string, suppressDefaultErrorHandler?: boolean | number[]): Observable<null | CurrentUser> {
    if (token) {
      const headers = {
        Authorization: `Bearer ${ token }`
      };
      return this.get({ headers, suppressDefaultErrorHandler });
    }
    return of(null);
  }

  public updateAnalyticsAllowed(value: boolean): Observable<CurrentUser> {
    const parameters = {
      analyticsAllowed: value
    };
    return this.patch({
      url: 'analytics',
      parameters
    });
  }

  public updateLanguage(value: string): Observable<CurrentUser> {
    const parameters = {
      preferredLanguage: value
    };
    return this.patch({
      url: 'language',
      parameters
    });
  }

  public updateTimeZone(value: string): Observable<CurrentUser> {
    const parameters = {
      timeZone: value
    };
    return this.patch({
      url: 'timezone',
      parameters
    });
  }

  public getAvailableCompanyCollection(
    tableParameters: Partial<ITableParameters<Company>> = {},
    suppressDefaultErrorHandler?: boolean | number[]
  ): Observable<Collection<Company>> {
    const parameters = Object.keys(tableParameters).length
      ? getRequestParametersFromTableParameters(tableParameters)
      : tableParameters;
    return this.get<Collection<Company>>({
      url: 'available-companies',
      parameters,
      suppressDefaultErrorHandler
    });
  }

  public setCurrentCompany(companyId: number): Observable<Company> {
    return this.patch<Company>({
      url: `current-company/${ companyId }`,
      suppressDefaultErrorHandler: [ 404 ]
    });
  }
}

export const userApiService = new UserApiService(apiUrl);
