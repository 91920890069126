import { isSupportedCountry } from 'libphonenumber-js';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DataFormat } from 'select2';
import { TranslationInfo } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { dictionaryService } from '@services';
import countryListMapper from './countryListMapper';

export const getCountryList = (locale: string): Observable<DataFormat[]> => dictionaryService.getData('country')
  .pipe(
    take(1),
    map((countryList: Array<IDictionaryResponse<TranslationInfo>>) => countryList
      .filter((country: IDictionaryResponse<TranslationInfo>) => isSupportedCountry(country.id))
    ),
    map((countryList) => countryListMapper(countryList, locale))
  );
