import { AssetManagementDocuments, SortDirection } from '@proliance-ai/typings';
import { sortObjectsArray } from '@proliance-ai/utilities';

export const documentsMapper = (assetManagementDocuments: AssetManagementDocuments): AssetManagementDocuments => {
  const { documents = [] } = assetManagementDocuments;
  const sortingConditions = [
    {
      path: 'fileName',
      sortDirection: SortDirection.asc
    }
  ];
  return {
    documents: sortObjectsArray(
      documents,
      sortingConditions
    )
  };
};
