import { tokenService } from '@proliance-ai/design-system';
import { ChoicesRestful } from '@shared/surveyJs/reexport';

interface IOptions {
  request: XMLHttpRequest;
}

ChoicesRestful.onBeforeSendRequest = (sender: ChoicesRestful, options: IOptions) => {
  sender.allowEmptyResponse = true;
  options.request.setRequestHeader(
    'Authorization',
    `Bearer ${ tokenService.getToken() }`
  );
};
