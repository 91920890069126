import React, { FC } from 'react';
import { classNameArrayToString, Ellipsis, useDataAttributes } from '@proliance-ai/design-system';
import { Company } from '@services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt } from '@fortawesome/pro-light-svg-icons';

import styles from './CompanySwitcherItem.styl';

interface ICompanySwitcherItemProperties {
  item: Company;
  currentCompany: Company;
  onClick: () => void;
}

type Props = ICompanySwitcherItemProperties;

const CompanySwitcherItem: FC<Props> = (props: Props) => {
  const {
    item,
    currentCompany,
    ...properties
  } = props;

  const {
    id,
    name,
    draftMode
  } = item;

  const itemClassName = classNameArrayToString([
    styles.companySwitcherItem,
    currentCompany!.id === id && styles.isActive
  ]);

  const draftModeIcon = draftMode
    ? <FontAwesomeIcon icon={ faPenAlt }/>
    : null;

  const { dataAttributes } = useDataAttributes({
    test: { company: id },
    guide: { company: id }
  });

  return (
    <div
      className={ itemClassName }
      { ...dataAttributes }
      { ...properties }
    >
      {/*<div className={styles.imageWrap}>*/ }
      {/*  <img className={styles.image} */ }
      {/*    src={image} */ }
      {/*    alt={name}*/ }
      {/*  />*/ }
      {/*</div>*/ }
      <div className={ styles.title }>
        <Ellipsis singleLine={ true }>
          { name }
        </Ellipsis>
        { draftModeIcon }
      </div>
    </div>
  );
};

export default CompanySwitcherItem;
