// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-CancelSurveyModal_Dv5tf .text-CancelSurveyModal_K9fQr{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal-CancelSurveyModal_Dv5tf",
	"text": "text-CancelSurveyModal_K9fQr"
};
export default ___CSS_LOADER_EXPORT___;
