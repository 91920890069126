import { useEffect, useState } from 'react';
import { State } from 'router5/dist/types/base';
import { Router, SubscribeState } from 'router5/dist/types/router';
import { from, ObservableInput, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentPermissionData } from '@proliance-ai/typings';
import { Case, kebabCaseToSnakeCase, snakeCaseToCamelCase } from '@proliance-ai/utilities';
import {
  getAcceptedRouteTabList,
  getDefaultTab,
  getRouteListTabNameTypeByRouteName,
  getRouteListTypeByRouteName,
  isDynamicRoute,
  isRouteHasTabs,
  isTabRoute,
  redirect,
  routerStateReplaced$,
  RouteState
} from '@router';
import { TabsRouteList } from '@router/data';
import { permissionService, streamService } from '@services';
import { getRoutePermission } from '../utilities';

export type UsePermissionSubscriptionOptions = {
  router: Router;
};
export type UsePermissionSubscriptionReturn = {
  route: RouteState;
};

export const usePermissionSubscription = (options: UsePermissionSubscriptionOptions): UsePermissionSubscriptionReturn => {
  const { router } = options;
  const [ route, setRoute ] = useState<RouteState>(router.getState() as RouteState);
  const [ lastDefaultTab, setLastDefaultTab ] = useState<string>();
  const [ permission, setPermission ] = useState<ComponentPermissionData>(route?.data.permission);

  const updateRoute = (state: RouteState): void => {
    setRoute(state);
  };

  const routerStateReplacedHandler = (state: State): void => {
    const newState = { ...route, name: state.name, params: { ...route.params, ...state.params } };
    updateRoute(newState);
  };

  const routerStateChangedHandler = (({ route }: SubscribeState): void => {
    updateRoute(route as RouteState);
    setPermission((route as RouteState)?.data?.permission);
  });

  const updatePermissionsHandler = (): void => {
    if (!route?.name) {
      return;
    }
    const routeName = getRouteListTypeByRouteName(route.name);
    const permission = getRoutePermission(routeName);
    setPermission(permission);

    const redirectOptions = { replace: true, reload: true };

    const isTab = isRouteHasTabs(routeName);
    if (isTab) {
      const isDefaultTab = !isTabRoute(route.name) || !route.params.routeTab;
      const routeTab: null | string = isDefaultTab
        ? getDefaultTab<string>(routeName as TabsRouteList, permission)
        : kebabCaseToSnakeCase(route.params.routeTab, Case.upper);
      const isDynamic = isDynamicRoute(routeName);
      const acceptedRouteTabList: string[] = getAcceptedRouteTabList<string>(routeName as TabsRouteList, permission);
      const isTabPermissionLost = routeTab && !acceptedRouteTabList.includes(routeTab);
      const isDefaultTabPermissionChanged = isDefaultTab && lastDefaultTab !== routeTab;
      const isRouteErrorSolved = !!route.error && acceptedRouteTabList.includes(routeTab);
      const isDynamicPageAvailable = isDynamic && permissionService.isPageAvailable(routeName);
      if (isTabPermissionLost || isDefaultTabPermissionChanged || isRouteErrorSolved || isDynamicPageAvailable) {
        const name = isDefaultTabPermissionChanged && !isDynamic
          ? getRouteListTabNameTypeByRouteName(route.name)
          : route.name;
        const isDefaultTabPermissionLost = isDefaultTabPermissionChanged && lastDefaultTab && !acceptedRouteTabList.includes(lastDefaultTab);
        const params = isDefaultTabPermissionChanged && lastDefaultTab
          ? {
            ...route.params,
            routeTab: snakeCaseToCamelCase(
              isDefaultTabPermissionLost
                ? routeTab
                : lastDefaultTab
            )
          }
          : route.params;
        return redirect(name, params, redirectOptions);
      }
    }

    const pagePermission = permissionService.isPageAvailable(routeName);
    if (!pagePermission) {
      return redirect(route.name, route.params, redirectOptions);
    } else if (route.error) {
      updateRoute({ ...route, error: null });
      if (!route.component) {
        return redirect(route.name, route.params, redirectOptions);
      }
    }
  };

  const routeTabHandler = (): void => {
    if (!route?.name) {
      setLastDefaultTab(undefined);
      return;
    }
    const routeName = getRouteListTypeByRouteName(route.name);
    const isTab = isRouteHasTabs(routeName);
    if (!isTab) {
      setLastDefaultTab(undefined);
      return;
    }
    const isDefaultTab = !isTabRoute(route.name) || !route.params.routeTab;
    setLastDefaultTab(
      isDefaultTab
        ? getDefaultTab<string>(routeName as TabsRouteList, permission)
        : undefined
    );
  };

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    streamService.updatePermissions$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(updatePermissionsHandler);
    const router$ = from(router as unknown as ObservableInput<SubscribeState>);
    routerStateReplaced$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(routerStateReplacedHandler);
    router$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(routerStateChangedHandler);
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, [ route, lastDefaultTab ]);
  useEffect(() => {
    routeTabHandler();
  }, [ route ]);

  return route
    ? {
      route: {
        ...route,
        data: {
          ...route.data,
          permission
        }
      }
    }
    : { route };
};
