// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterRow-ManualFilter_OhzvH td{padding:10px 5px 5px}.filterRow-ManualFilter_OhzvH select{-ms-filter:\"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)\";filter:alpha(opacity=0);opacity:0}.filterRow-ManualFilter_OhzvH label{font-size:13px}.systemEvent-ManualFilter_muQI5{display:block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterRow": "filterRow-ManualFilter_OhzvH",
	"systemEvent": "systemEvent-ManualFilter_muQI5"
};
export default ___CSS_LOADER_EXPORT___;
