// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion-InfoAccordion_KsjSr{width:100%;margin-bottom:16px;box-sizing:border-box;background:#fff;border:1px solid #e0e0e0;box-shadow:0 3px 8px rgba(0,0,0,0.09)}.panel-InfoAccordion_Rq2nJ{border-bottom:1px solid #e0e0e0}.content-InfoAccordion_Px5Av{padding:17px 24px 24px;box-sizing:border-box;background:#f7f7f7}.link-InfoAccordion_wySt_{display:block;margin-bottom:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "accordion-InfoAccordion_KsjSr",
	"panel": "panel-InfoAccordion_Rq2nJ",
	"content": "content-InfoAccordion_Px5Av",
	"link": "link-InfoAccordion_wySt_"
};
export default ___CSS_LOADER_EXPORT___;
