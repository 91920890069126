import { DataStorageFormKey, Priority } from '@proliance-ai/typings';

export const dataStoragePriorityMapping: Partial<Record<DataStorageFormKey, Priority>> = {
  type: Priority.IMPORTANT,
  operators: Priority.IMPORTANT,
  dataTypes: Priority.IMPORTANT,
  description: Priority.IMPORTANT,
  accesses: Priority.OPTIONAL,
  rpaAsController: Priority.OPTIONAL
};
