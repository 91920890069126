import { FunctionalArea } from '@proliance-ai/typings';
import { IComponentDictionary } from '@interfaces';
import { AssetManagementTabsKey, DataBreachType, DocumentType, RiskState, TaskCount } from '@services';
import { AnalyticsTabsKeys } from '@pages/Analytics/components/Analytics';

export type TabsRouteList = 'analytics' | 'assetManagement' | 'dashboard' | 'dataBreach' | 'document' | 'processes' | 'risk';
export type RouteTabMappingItem = {
  routeList: string[];
  mapper?: IComponentDictionary;
};

export const routeTabMapping: Record<TabsRouteList, RouteTabMappingItem> = {
  analytics: {
    routeList: Object.keys(AnalyticsTabsKeys) as AnalyticsTabsKeys[],
    mapper: {
      STATUS: [ 'areaWidget', 'complianceScoreWidget' ],
      ACTIVITIES: 'activities'
    }
  },
  assetManagement: {
    routeList: Object.keys(AssetManagementTabsKey) as AssetManagementTabsKey[],
    mapper: {
      SOFTWARE: 'assetManagement',
      THIRD_PARTIES: 'assetManagement',
      DATA_STORAGES: 'assetManagement'
    }
  },
  dashboard: {
    routeList: Object.keys(TaskCount) as TaskCount[],
    mapper: {
      BASIC: 'basicTasksWidget',
      ASSESSMENT: 'assessmentTasksWidget'
    }
  },
  dataBreach: {
    routeList: Object.keys(DataBreachType) as DataBreachType[],
    mapper: {
      ACTIVE: 'dataBreach',
      ARCHIVE: 'dataBreach'
    }
  },
  document: {
    routeList: Object.keys(DocumentType) as DocumentType[]
  },
  processes: {
    routeList: Object.keys(FunctionalArea) as FunctionalArea[]
  },
  risk: {
    routeList: Object.keys(RiskState) as RiskState[],
    mapper: {
      ACTIVE: 'risk',
      CLOSED: 'risk'
    }
  }
};
