import { CountryCode } from 'libphonenumber-js';
import { TranslationInfo, Locale } from '@proliance-ai/typings';
import { IDictionaryResponse } from '@interfaces';
import { getCallingCode } from './getCallingCode';

export const countryListMapper = (
  countryList: Array<IDictionaryResponse<TranslationInfo>>,
  locale: string
) => countryList
  .map(
    (country) => ({
      id: country.id,
      text: country.title[locale as Locale]
        ? `${ country.title[locale as Locale] } (${ country.id }) +${ getCallingCode(country.id as CountryCode) }`
        : ''
    })
  );

export default countryListMapper;
