import { useEffect, useState } from 'react';
import { pageTitleService } from '@proliance-ai/design-system';

export type UsePageTitleReturn = {
  pageTitle?: string;
};

export const usePageTitle = (): UsePageTitleReturn => {
  const [ pageTitle, setPageTitle ] = useState<string>();

  useEffect(
    () => {
      const subscription = pageTitleService.title$
        .subscribe(
          (value: string) => {
            if (value && value !== pageTitle) {
              setPageTitle(value);
            }
          }
        );
      return (): void => {
        subscription.unsubscribe();
      };
    },
    []
  );

  return { pageTitle };
};
