import React, { createRef, FC, ReactElement, ReactNode, RefObject, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMenuGroupData, MenuDataType, MenuItemData } from '@proliance-ai/typings';
import { classNameArrayToString, useDataAttributes } from '@proliance-ai/design-system';
import { Menu, menuService, Sidebar, sidebarService } from '@proliance-ai/react-ui';
import { redirectParameterName, replaceHistoryState, routerStateReplaced$ } from '@router';
import { Footer } from '../components';
import { Header } from './components';

import styles from './AppLayout.styl';

interface OwnProps {
  className?: string;
  bg?: ReactElement;
  hideUser?: boolean;
  hideFooter?: boolean;
  hideMenu?: boolean;
  footerTransparent?: boolean;
  children: ReactNode | ReactElement;
}

type Props = OwnProps;

const AppLayout: FC<Props> = ({
  children,
  className = '',
  hideMenu = false,
  hideUser = false
}) => {
  const isEmptyMenu = !menuService.menu$.value
    .filter((item: MenuDataType) => isMenuGroupData(item)
      ? item.group.filter((item: MenuItemData) => !!item?.permission).length
      : !!item?.permission
    ).length;

  const { dataAttributes } = useDataAttributes({
    test: { sidebar: 'menu' },
    guide: { sidebar: 'menu' }
  });

  const containerClassName = classNameArrayToString([
    styles.container,
    className
  ]);
  const menu = hideMenu || isEmptyMenu
    ? null
    : (
      <Menu
        menuService={ menuService }
        routerStateReplaced$={ routerStateReplaced$ }
        replaceHistoryState={ replaceHistoryState }
        redirectParameterName={ redirectParameterName }
      />
    );
  const menuSidebar = (
    <aside
      className={ styles.sidebar }
      { ...dataAttributes }
    >
      { menu }
    </aside>
  );

  const headerRef: RefObject<HTMLDivElement> = createRef();
  const footerRef: RefObject<HTMLDivElement> = createRef();

  const sidebar = (
    <Sidebar
      headerRef={ headerRef }
      footerRef={ footerRef }
    />
  );

  const unsubscribe$ = new Subject<void>();
  const [ isSidebarVisible, setIsSidebarVisible ] = useState<boolean>(sidebarService.isOpen.value);
  useEffect(() => {
    sidebarService.isOpen$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((isOpen: boolean): void => {
        setIsSidebarVisible(isOpen);
      });
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);
  const pageClassName = classNameArrayToString([
    styles.page,
    isSidebarVisible && styles.sidebar
  ]);

  return (
    <>
      <section className={ containerClassName }>
        <Header
          ref={ headerRef }
          hideMenu={ hideMenu || isEmptyMenu }
          hideUser={ hideUser }
        />
        <div className={ styles.body }>
          <div className={ styles.content }>
            { menuSidebar }
            <div className={ pageClassName }>{ children }</div>
            { sidebar }
          </div>
        </div>
        <Footer ref={ footerRef } />
      </section>
    </>
  );
};

export default AppLayout;
