import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { IActivity, activityService, streamService } from '@services';
import { Table } from './Table';
import { getColumns } from './Table/columns';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { ITableState } from './Table/Table';
import { take, takeUntil } from 'rxjs/operators';
import { getPageCount } from '@shared/utilities';
import { Subject } from 'rxjs';
import { defaultPageSize } from '@proliance-ai/design-system';

interface IOwnProps {
  namespace: string | string[];
}

type Props = IOwnProps;

const activityFilter = {
  page: 1,
  pageSize: defaultPageSize,
  sortField: 'createdAt',
  sortDirection: 'desc'
};

const ActivitiesList: FC<Props> = ({ namespace }) => {
  const { t } = useTranslation(namespace);

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ data, setData ] = useState<IActivity[]>([]);
  const [ total, setTotal ] = useState<number>();
  const [ pageCount, setPageCount ] = useState<number>(0);
  const columns: Array<Column<IActivity>> = getColumns(t);
  const lastState = useRef<Partial<ITableState<IActivity>>>({});

  const unsubscribe$ = new Subject<void>();
  const unsubscribeRequest$ = new Subject<void>();
  const fetchData = useCallback((state: Partial<ITableState<IActivity>>): void => {
    unsubscribeRequest$.next();
    lastState.current = state;
    const { pageIndex = 0, pageSize, sortBy, globalFilter } = state;
    const page = pageIndex + 1;
    const sortField = sortBy && sortBy[0] ? sortBy[0].id : activityFilter.sortField;
    const sortDirection = sortBy && sortBy[0] ? sortBy[0].desc ? 'desc' : 'asc' : activityFilter.sortDirection;
    const parameters = {
      page,
      pageSize,
      sortField,
      sortDirection
    };
    setLoading(true);
    activityService
      .getActivity({ ...parameters, ...globalFilter })
      .pipe(
        take(1),
        takeUntil(unsubscribeRequest$)
      )
      .subscribe((collection) => {
        setData(collection.elements);
        setTotal(collection.totalResult);
        setPageCount(getPageCount(collection));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    streamService
      .subscribeActivityMessage()
      .pipe(takeUntil(unsubscribe$))
      .subscribe(() => {
        fetchData(lastState.current);
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
      unsubscribeRequest$.next();
      unsubscribeRequest$.complete();
    };
  }, []);

  const initialState = {
    ...activityFilter,
    sortBy: [ {
      id: 'createdAt',
      desc: true
    } ]
  };

  const emptyText = t('table.empty');

  return (
    <Table
      columns={ columns }
      dataAttributesDictionary={ {
        test: { activities: 'table' },
        guide: { activities: 'table' }
      } }
      data={ data }
      loading={ loading }
      fetchData={ fetchData }
      pageCount={ pageCount }
      initialState={ initialState }
      emptyText={ emptyText }
      total={ total }
    />
  );
};

export default ActivitiesList;
