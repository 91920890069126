import { AssetManagementEvidences, Evidence, SortDirection } from '@proliance-ai/typings';
import { sortObjectsArray } from '@proliance-ai/utilities';
import { assetManagementService, i18n } from '@services';
import { documentsMapper } from './documentsMapper';

export const evidencesMapper = async (assetManagementEvidences: AssetManagementEvidences): AssetManagementEvidences => {
  const { t } = i18n;
  let { evidences = [] } = assetManagementEvidences;
  evidences = evidences
    .map((evidence: Evidence) => ({
      ...evidence,
      ...documentsMapper(evidence)
    }));

  const sortingConditions = [
    {
      path: 'country',
      sortDirection: SortDirection.asc
    },
    {
      path: 'type',
      sortDirection: SortDirection.asc
    }
  ];

  const countryList = [
    ...new Set(
      evidences
        .map((evidence: Evidence) => evidence.country)
        .filter(Boolean)
    )
  ];
  const promiseList: Promise<Record<string, string>[]> = countryList
    .map(async (country: string): Record<string, string> => assetManagementService
      .getCountryTitle(country)
      .then((value: string): Record<string, string> => ({ [country]: value })));

  const countryTitleData = await Promise.all(promiseList);
  const countryNameList = Object.values(countryTitleData)
    .reduce(
      (result: Record<string, string>, item: Record<string, string>): Record<string, string> => ({
        ...result,
        ...item
      }),
      {} as Record<string, string>
    );

  const getValue = (evidence: Evidence, path: string): undefined | string => {
    const { country, type } = evidence;
    const countryName = countryNameList[country];
    if (path === 'country') {
      return countryName;
    }
    if (path === 'type') {
      return t(`assetManagement:evidence.${ type }.title`, { country: countryName });
    }
    return;
  };
  
  const sorted = sortObjectsArray<Evidence>(
    evidences,
    sortingConditions,
    getValue
  );
  
  return {
    evidences: sorted
  };
};
