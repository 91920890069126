// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userMenu-UserMenu_FR2pR{position:relative;height:64px;padding:0 1rem;box-sizing:border-box;border-radius:3px;cursor:pointer;-webkit-transition:background-color .2s linear;transition:background-color .2s linear}.userMenu-UserMenu_FR2pR:hover{background-color:rgba(0,0,0,0.05)}.container-UserMenu_vUgiq{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:center;-ms-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-align-items:center;align-items:center;font-size:12px}.icon-UserMenu_wTQCs{width:34px;height:34px}.chevron-UserMenu_KB2RM{width:12px;color:#1b143f;margin-left:.5rem}.chevron-UserMenu_KB2RM.opened-UserMenu_Z4NgD{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}.dark-UserMenu_L0E4b,.light-UserMenu_rMdx6{white-space:nowrap}.dark-UserMenu_L0E4b{color:#1b143f;font-weight:500;font-size:14px;line-height:16px}.light-UserMenu_rMdx6{color:#828282;font-weight:400;font-size:12px;line-height:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenu": "userMenu-UserMenu_FR2pR",
	"container": "container-UserMenu_vUgiq",
	"icon": "icon-UserMenu_wTQCs",
	"chevron": "chevron-UserMenu_KB2RM",
	"opened": "opened-UserMenu_Z4NgD",
	"dark": "dark-UserMenu_L0E4b",
	"light": "light-UserMenu_rMdx6"
};
export default ___CSS_LOADER_EXPORT___;
