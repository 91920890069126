import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentPermissionData, Feature } from '@proliance-ai/typings';
import { Case, snakeCaseToKebabCase } from '@proliance-ai/utilities';
import { useTabsRouteHandling } from '@hooks';
import { getDefaultTab, replaceHistoryState, tabFormatter } from '@router';
import { permissionService } from '@services';
import { PermissionGuard } from '@shared/components';
import { Tabs } from '@shared/ui';
import { Status } from './Status';
import { default as ActivitiesList } from './ActivitiesList';

export enum AnalyticsTabsKeys {
  STATUS = 'STATUS',
  ACTIVITIES = 'ACTIVITIES',
}

interface IAnalyticsView {
  namespace: string | string[];
  permission: ComponentPermissionData;
  routeTab: null | AnalyticsTabsKeys;
}

export const Analytics: FC<IAnalyticsView> = ({ namespace, permission, routeTab }) => {
  const { t } = useTranslation(namespace);

  const defaultTab: AnalyticsTabsKeys = getDefaultTab<AnalyticsTabsKeys>('analytics', permission);
  const [ tab, setTab ] = useState<AnalyticsTabsKeys>(routeTab || defaultTab);
  useTabsRouteHandling<AnalyticsTabsKeys>({ routeName: 'analytics', defaultTab, tabState: [ tab, setTab ] });
  const onTabChange = (value: AnalyticsTabsKeys) => {
    if (value === AnalyticsTabsKeys.STATUS) {
      replaceHistoryState('analytics');
    } else {
      replaceHistoryState('analyticsTab', { routeTab: snakeCaseToKebabCase(value, Case.lower) });
    }
    setTab(value);
  };

  const tabsPermission = {
    activities: permission.activities,
    status: permissionService.permissionDataSubject$.value?.[Feature.COMPLIANCE]
  };

  return (
    <PermissionGuard permission={tabsPermission}>
      <Tabs
        dataAttributesDictionary={{
          test: { tabs: 'analytics' },
          guide: { tabs: 'analytics' }
        }}
        activeTab={tab}
        onChange={onTabChange}
        tabFormatter={tabFormatter}
      >
        <section
          data-key={AnalyticsTabsKeys.STATUS.toLowerCase()}
          data-reload={true}
          data-label={t(`tabs.${ AnalyticsTabsKeys.STATUS.toLowerCase() }`)}
        >
          <Status permission={permission} />
        </section>
        <section
          data-key={AnalyticsTabsKeys.ACTIVITIES.toLowerCase()}
          data-reload={true}
          data-label={t(`tabs.${ AnalyticsTabsKeys.ACTIVITIES.toLowerCase() }`)}
        >
          <ActivitiesList namespace={namespace} />
        </section>
      </Tabs>
    </PermissionGuard>
  );
};
