import { cloneElement, FC, ReactElement } from 'react';
import { ComponentListType, ComponentPermissionData, IPermission } from '@proliance-ai/typings';
import { Tabs } from '@shared/ui';
import { getComponentPermission, setPermission } from './utilities';

interface IOwnProperties {
  permission: IPermission | ComponentPermissionData;
  componentName?: ComponentListType;
  children?: ReactElement;
  readonlyKey?: string;
  readonlyValue?: any;
}

type Props = IOwnProperties;

export const PermissionGuard: FC<Props> = ({
  permission,
  componentName,
  children,
  readonlyKey,
  readonlyValue
}): ReactElement | null => {
  if (typeof children === 'undefined') {
    return null;
  }

  if (typeof componentName === 'undefined') {
    if (children.type === Tabs) {
      const props = children.props;
      const newTabList = props.children.reduce((result: ReactElement[], tab: ReactElement) => {
        const key = tab.props['data-permission-key'] || tab.props['data-key'];
        const tabPermission = getComponentPermission(key, permission);
        const newTab = setPermission(tab, tabPermission);
        if (newTab !== null) {
          result.push(newTab);
        }
        return result;
      }, []);
      return cloneElement(children, { ...props, ...{ children: newTabList } });
    }
    return null;
  } else {
    const componentPermission = getComponentPermission(componentName, permission);
    return setPermission(children, componentPermission, readonlyKey, readonlyValue);
  }
};
