import {
  SurveyLocalizationKey,
  WidgetEditorLocalization,
  WidgetLocalization
} from '@shared/surveyJs/widgets/interfaces';
import { TranslationInfo } from '@proliance-ai/typings';

const setTranslationInfo = (
  _key: string,
  _translationInfo: TranslationInfo,
  _suffix: SurveyLocalizationKey
): void => {
  return;
};

export const setEditorLocalization = (localizationData: WidgetEditorLocalization) => {
  (Object.keys(localizationData) as SurveyLocalizationKey[])
    .map(
      (suffix: SurveyLocalizationKey) => {
        const localizationGroup: WidgetLocalization = localizationData[suffix]!;
        (Object.keys(localizationGroup) as SurveyLocalizationKey[])
          .map(
            (key: string) => {
              setTranslationInfo(key, localizationGroup[key], suffix);
            }
          );
      }
    );
};

export const getTitle = (name: string = ''): string => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};
